import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import deepPurple from '@material-ui/core/colors/deepPurple';

// Redux Actions
import { connect } from 'react-redux';
import { changePage, MAIN_PAGE, DESIGNER_PAGE, resetAll } from '../redux_store/appState';
const importedActions = { changePage, resetAll };
const mapStateToProps = state => ({
    app: state.app
});

const styles = {
    root: {
        flexGrow: 1,
        top: 0,
        left: 0,
        right: 0,
        position: "fixed",
        zIndex: 1100,
        textAlign: "left",
    },
    grow: {
        flexGrow: 1,
        font: "22pt 'Futura', sans-serif"
    },
    menuButton: {
        // marginLeft: 20,
        marginRight: -12,
    },
    backButton: {
        // marginLeft: 20,
        marginRight: 0,
    },
    purpleAvatar: {
        margin: 5,
        color: '#fff',
        backgroundColor: deepPurple[500],
    },
};

class TopBar extends Component {
    state = {
        anchorEl: null,
        currentUser: null,
    };
    componentWillMount() {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => this.setState({ currentUser: user.attributes }))
            .catch(err => console.log(err));
    }

    showMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    }

    closeMenu = () => {
        this.setState({ anchorEl: null });
    };

    signOut = async () => {
        Auth.signOut()
            .then(data => console.log(data))
            .catch(err => console.log(err));
    }

    getUserData = async () => {
      console.log('get user data');
      let user = await Auth.currentAuthenticatedUser();
    //   const { attributes } = user;
      console.log(user);
      console.log('get user data');
      let session = await Auth.currentSession();
    //   const { attributes } = user;
      console.log(session);
    }

    updatePost = (id, parameter) => event => {
    }

    render() {
        const { page } = this.props.app;
        const { classes, changePage, resetAll } = this.props;
        const { anchorEl, currentUser } = this.state;

        let display_name = "Centenary User";
        if (currentUser) {
            if (currentUser.given_name) display_name = currentUser.given_name + " " + currentUser.family_name;
            else display_name = currentUser.email
        };

        return (
            <div className={classes.root}>
                <AppBar position="fixed">
                    <Toolbar variant="dense">
                        {page === DESIGNER_PAGE &&
                            <IconButton className={classes.backButton} color="inherit" aria-label="Menu" onClick={() => changePage(MAIN_PAGE)}>
                                <ArrowBack />
                            </IconButton>
                        }
                        <Typography variant="h6" color="inherit" className={classes.grow} onClick={() => changePage(MAIN_PAGE)}>
                            CENTENARY DESIGN CLOUD
                            </Typography>

                        <Typography variant="body1" color="inherit" align="right">
                            <span style={{ fontWeight: "200", fontSize: "20px", textTransform: "uppercase" }}>{display_name}</span><br />FIELDERS CENTENARY PTY LTD
                        </Typography>
                        <Avatar className={classes.purpleAvatar}>FC</Avatar>
                        <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.showMenu}>
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.closeMenu}
                    >
                        <MenuItem onClick={this.getUserData}>Get User Data</MenuItem>
                        <MenuItem onClick={this.signOut}>Sign Out</MenuItem>
                    </Menu>
                </AppBar>
            </div>
        );
    }
}

export default connect(mapStateToProps, importedActions)(withStyles(styles)(TopBar));