import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';


const styles = {
  card: {
    height: "100%",
  },
  cardWidthLimit: {
    maxWidth: 345,
  },
    media: {
      // ⚠️ object-fit is not supported by IE 11.
      objectFit: 'cover',
    },
    content: {
      textAlign: 'left',
    },
    typography: {
      margin: '20px',
    },
    actionblock: {
      bottom: '0px',
    },
  };

  // function JobCard(props) {
    class JobCard extends React.Component {
      state = {
        anchorEl: null,
      };

      
      showPopover = event => {
        this.setState({
          anchorEl: event.currentTarget,
        });
      };

      closePopover = () => {
        this.setState({
          anchorEl: null,
        });
      };

    
    render() {
      const { props } = this;
      const { classes, mainAction } = props;
      const { anchorEl } = this.state;
      const open = Boolean(anchorEl);

    let detail_text = "";
    if (props.detail_text) detail_text = props.detail_text.split('<br />').map((item, i) => {
      return <React.Fragment key={i}>{item}<br /></React.Fragment >;
    });
    let sub_detail_text = "";
    if (props.sub_detail_text) sub_detail_text = props.sub_detail_text.split('<br />').map((item, i) => {
      return <React.Fragment key={i}>{item}<br /></React.Fragment >;
    });

    let actions = null;
    if (props.first_button_text || props.second_button_text || props.third_button_text) {
      actions = <CardActions
        className={classes.actionblock}>
        {props.first_button_text &&
          <Button size="small" color="primary" onClick={mainAction ? mainAction : null}>
            {props.first_button_text}
          </Button>
        }
        {props.second_button_text &&
          <Button size="small" color="primary">
            {props.second_button_text}
          </Button>
        }
        {props.third_button_text &&
          <Button size="small" color="secondary"
            onClick={this.showPopover}>
            {props.third_button_text}
          </Button>
        }
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={this.closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography className={classes.typography}>Notes: sadfasdfasdfdsa</Typography>
        </Popover>
      </CardActions>;
    }

    return (
      <Card className={classes.card + (props.noMaxWidth?"":" " + classes.cardWidthLimit)} style={props.disabled?{opacity: "0.5"}:{}}>
        <CardActionArea onClick={() => (mainAction?mainAction():null)}>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            className={classes.media}
            height="140"
            image={props.image?props.image:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAikAAAEYBAMAAABrc3u4AAAALHRFWHRDcmVhdGlvbiBUaW1lAFdlZCAyNyBNYXIgMjAxOSAxODo1MTo0NyArMDkzMOWZz+IAAAAHdElNRQfjAxsIHCk2av1LAAAACXBIWXMAAAsSAAALEgHS3X78AAAABGdBTUEAALGPC/xhBQAAAANQTFRFlJylJii/hwAAAGJJREFUeNrtwQENAAAAwqD3T20ON6AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4NDAfAAHd3L3XAAAAAElFTkSuQmCC"}
            title=""
          />
          <CardContent className={classes.content}>
            <Typography gutterBottom variant="h6" component="h2">
              {props.title?props.title:""}
            </Typography>
            <Typography component="p">
            {detail_text}
            </Typography>
            <Typography component="p" variant="caption" style={{color:"grey", textAlign: "left"}}>
            {props.sub_detail_text?sub_detail_text:""}
            </Typography>
          </CardContent>
        </CardActionArea>
        {actions}
        </Card>
      );
    }
};
const mapStateToProps = state => ({
    // posts: state.posts
});

export default connect(mapStateToProps, null)(withStyles(styles)(JobCard));