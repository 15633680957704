import React from 'react';
import { Greetings, SignIn, SignOut, ConfirmSignIn, VerifyContact, ForgotPassword } from 'aws-amplify-react'; // or 'aws-amplify-react-native';
import orange from '@material-ui/core/colors/orange';

export const signUpComponents = [
    <Greetings />,
    <SignIn />,
    <SignOut />,
    <ConfirmSignIn />,
    <VerifyContact />,
    <ForgotPassword />
  ]

export const signUpConfig = {
    defaultCountryCode: "61",
    hideAllDefaults: false,
    hiddenDefaults: ["email"],
    signUpFields: [
      {
        label: 'Email',
        key: 'username',
        required: true,
        placeholder: 'Email',
        type: 'email',
        displayOrder: 1,
      },
      {
        label: 'Password',
        key: 'password',
        required: true,
        placeholder: 'Password',
        type: 'password',
        displayOrder: 2,
      },
      { label: "Given Name", key: "given_name", required: true, displayOrder: 3, type: "string" },
      { label: "Family Name", key: "family_name", required: true, displayOrder: 4, type: "string" },
    ]
  }

export const Palette = {
  FieldersBlue: "#003465"
};

export const MuiTheme = {
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#335c83',
      main: Palette.FieldersBlue,
      dark: '#001235',
      contrastText: '#fff',
    },
    secondary: orange,
  },
  status: {
    danger: '#ff3d00',
  },
}
