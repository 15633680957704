import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

// Material UI
import Divider from '@material-ui/core/Divider';

// App Components
import NewQuotesLayout from './NewQuotesLayout';
import RecallQuotesLayout from './RecallQuotesLayout';
import NewsPaper from './NewsPaper';


const styles = {
    card: {
      maxWidth: 420,
    },
    media: {
      // ⚠️ object-fit is not supported by IE 11.
      objectFit: 'cover',
    },
    newspaper: {
      padding: 50,
    }
  };

  function Main(props) {

    return (
      <div>
      <NewQuotesLayout />
      <Divider style={{margin: "25px 15px"}}/>
      <RecallQuotesLayout />
      <Divider style={{margin: "25px 15px"}}/>
      <NewsPaper />
      </div>
    );
  }

const mapStateToProps = state => ({
    // posts: state.posts
});

export default connect(mapStateToProps, null)(withStyles(styles)(Main));