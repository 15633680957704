import _debounce from 'lodash/debounce';

// Page Actions
export const UPDATE_PAGE = 'UPDATE_PAGE';
// Page Attributes
export const MAIN_PAGE = 'MAIN_PAGE';
export const DESIGNER_PAGE = 'DESIGNER_PAGE';

// Designer Actions
export const UPDATE_DESIGNER = 'UPDATE_DESIGNER';
export const TOGGLE_DESIGNER = 'TOGGLE_DESIGNER';
// Designer Attributes
export const MAIN_DRAWER_OPEN = 'mainDrawerOpen';
export const TOOL_DRAWER_OPEN = 'toolDrawerOpen';
export const THREED_MODE = 'threeDMode';
export const SHOW_CUSTOMER_JOB_PANEL = 'showCustomerJobPanel';
export const SHOW_NOTES_PANEL = 'showNotesPanel';

// 2D Designer Actions
export const UPDATE_2D_DESIGNER = 'UPDATE_2D_DESIGNER';
export const UPDATE_2D_DESIGNER_SVGS = 'UPDATE_2D_DESIGNER_SVGS';
export const TOGGLE_2D_DESIGNER = 'TOGGLE_2D_DESIGNER';
// 2D Designer Attributes
export const SHOW_IMPORTED_MAP = 'showImportedMap';

// 3D Designer Actions
export const UPDATE_3D_DESIGNER = 'UPDATE_3D_DESIGNER';
export const TOGGLE_3D_DESIGNER = 'TOGGLE_3D_DESIGNER';

// 3D Babylon Designer Attributes
export const SHOW_GROUND_PHOTO = 'showGroundPhoto';
export const SHOW_HOUSE_MODEL = 'showHouseModel';
export const SHOW_VERANDAH_MODEL = 'showVerandahModel';
export const SHOW_VERANDAH_LAYOUT = 'showVerandahLayout';
export const BABYLON_SELECTED_ITEM = 'babylonSelectedItem';
export const VERANDAH_MESH_MODE = 'verandahMeshMode';
export const VERANDAH_EDGE_LINE_MODE = 'verandahEdgeLineMode';
export const BACKGROUND_MODE = 'backgroundMode';
export const BACKGROUND_SHADOW_MODE = 'backgroundShadowMode';

export const DRAWER_TOOLSET_EXPANDED = 'drawerToolSetExpanded';

export const initialState = {
    page: MAIN_PAGE,
    designer: {
        [TOOL_DRAWER_OPEN]: true,
        [MAIN_DRAWER_OPEN]: false,
        [THREED_MODE]: false,
        [SHOW_CUSTOMER_JOB_PANEL]: false,
        [SHOW_NOTES_PANEL]: false,
        designer2D: {
            [SHOW_IMPORTED_MAP]: false,
            svgs: {
                area_all: null,
            }
        },
        designer3D: {
            [SHOW_GROUND_PHOTO]: true,
            [SHOW_HOUSE_MODEL]: true,
            [SHOW_VERANDAH_MODEL]: false,
            [VERANDAH_MESH_MODE]: false,
            [VERANDAH_EDGE_LINE_MODE]: false,
            [SHOW_VERANDAH_LAYOUT]: false,
            [BABYLON_SELECTED_ITEM]: null,
            [DRAWER_TOOLSET_EXPANDED]: null,
            [BACKGROUND_SHADOW_MODE]: false,
            [BACKGROUND_MODE]: "Plain",
        },
    },
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_PAGE:
            return {
                ...state,
                page: action.value
            };
        case UPDATE_DESIGNER:
            return {
                ...state,
                designer: {
                    ...state.designer,
                    [action.parameter]: action.value
                }
            };
        case TOGGLE_DESIGNER:
            return {
                ...state,
                designer: {
                    ...state.designer,
                    [action.parameter]: !state.designer[action.parameter]
                }
            };
        case UPDATE_2D_DESIGNER:
            return {
                ...state,
                designer: {
                    ...state.designer,
                    designer2D: {
                        ...state.designer.designer2D,
                        [action.parameter]: action.value
                    }
                }
            };
        case UPDATE_2D_DESIGNER_SVGS:
            return {
                ...state,
                designer: {
                    ...state.designer,
                    designer2D: {
                        ...state.designer.designer2D,
                        svgs: {
                            ...state.designer.designer2D.svgs,
                            [action.parameter]: action.value
                        }
                    }
                }
            };
        case TOGGLE_2D_DESIGNER:
            return {
                ...state,
                designer: {
                    ...state.designer,
                    designer2D: {
                        ...state.designer.designer2D,
                        [action.parameter]: !state.designer.designer2D[action.parameter]
                    }
                }
            };
        case UPDATE_3D_DESIGNER:
            return {
                ...state,
                designer: {
                    ...state.designer,
                    designer3D: {
                        ...state.designer.designer3D,
                        [action.parameter]: action.value
                    }
                }
            };
        case TOGGLE_3D_DESIGNER:
            return {
                ...state,
                designer: {
                    ...state.designer,
                    designer3D: {
                        ...state.designer.designer3D,
                        [action.parameter]: !state.designer.designer3D[action.parameter]
                    }
                }
            };
        default:
            return state;
    }
}


export const loadAppTemplate = (template) => dispatch => {
    console.log("LOAD_TEMPLATE");
    dispatch({
        type: "LOAD_TEMPLATE",
        value: template
    });
}

export const resetAll = () => dispatch => {
    console.log("RESET_ALL");
    dispatch({
        type: "RESET_ALL",
        value: "RESET_ALL"
    });
}

export const changePage = (page_name) => dispatch => {
    console.log(page_name);
    dispatch({
        type: UPDATE_PAGE,
        value: page_name
    });
}

export const updateDesigner = (parameter, value) => dispatch => {
    console.log(UPDATE_DESIGNER, parameter, value);
    dispatch({
        type: UPDATE_DESIGNER,
        parameter: parameter,
        value: value
    });
}
export const toggleDesigner = (parameter) => dispatch => {
    console.log(TOGGLE_DESIGNER, parameter);
    dispatch({
        type: TOGGLE_DESIGNER,
        parameter: parameter,
    });
}

export const svg_url = "http://qikquoteserverapi-test.ap-southeast-2.elasticbeanstalk.com/api/svg";
// export const svg_url = "http://localhost:62027/api/svg";

// export const update2DSVGs = (ini_data) => _debounce(updateSVG_debounced(ini_data), 2000, { 'maxWait': 2000 });

export const update2DSVGs = (ini_data) => dispatch => {
    return fetch(svg_url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            "Content-Type": "text/plain",
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer", // no-referrer, *client
        body: ini_data, // body data type must match "Content-Type" header
    })
        .then(response => response.blob())
        .then(blob => {
            // console.log(response);
            console.log(blob);
            var svg_url = URL.createObjectURL(blob);
            dispatch({
                type: UPDATE_2D_DESIGNER_SVGS,
                parameter: "area_all",
                value: svg_url
            });
        }); // parses JSON response into native Javascript objects 
}

export const update2DDesigner = (parameter, value) => dispatch => {
    console.log(UPDATE_2D_DESIGNER, parameter, value);
    dispatch({
        type: UPDATE_2D_DESIGNER,
        parameter: parameter,
        value: value
    });
}
export const toggle2DDesigner = (parameter) => dispatch => {
    console.log(TOGGLE_2D_DESIGNER, parameter);
    dispatch({
        type: TOGGLE_2D_DESIGNER,
        parameter: parameter,
    });
}

export const update3DDesigner = (parameter, value) => dispatch => {
    console.log(UPDATE_3D_DESIGNER, parameter, value);
    dispatch({
        type: UPDATE_3D_DESIGNER,
        parameter: parameter,
        value: value
    });
}
export const toggle3DDesigner = (parameter) => dispatch => {
    console.log(TOGGLE_3D_DESIGNER, parameter);
    dispatch({
        type: TOGGLE_3D_DESIGNER,
        parameter: parameter,
    });
}
// export const toggleDesigner = (toggle_action) => dispatch => {
//     dispatch({
//             type: toggle_action
//         });
// }