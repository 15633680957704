export const gazebo_default_app_state = {app: {
    page: 'DESIGNER_PAGE',
    designer: {
      toolDrawerOpen: true,
      mainDrawerOpen: false,
      threeDMode: true,
      showCustomerJobPanel: false,
      designer2D: {
        showImportedMap: false
      },
      designer3D: {
        showGroundPhoto: false,
        showHouseModel: false,
        showVerandahModel: false,
        verandahMeshMode: false,
        verandahEdgeLineMode: false,
        babylonSelectedItem: null,
        drawerToolSetExpanded: 'gazebo_tool',
        backgroundShadowMode: true,
        backgroundMode: 'Plain'
      }
    }
  },
}


export const gazebo_basic_specs_initialState = { // matched to VQU
  SHAPE: {
    DesignType: 'Gazebo Roof', 
    DesignSubType: 'Type 4', 
    Length: 7500,
    Depth: 6000,
    Height: 2100,
    RoofPitch: 20,
    SheetDirection: 'Front',
  },
  BEAMS_AND_POSTS: {
    BeamType: '1586510', 
    BeamList: 'Gazebo Beam', 
    BeamName: 'BeamName', 
    BeamCol: 'Classic Cream', 
    BeamColMatchBeam: 'Classic Cream', 
    BeamType2: '1586510', 
    BeamList2: 'Gazebo Beam', 
    BeamName2: 'BeamName2', 
    BeamCol2: 'Classic Cream', 
    BeamCol2MatchBeam: 'Classic Cream', 
    BeamType3: '1586510', 
    BeamList3: 'Box', 
    BeamName3: 'BeamName3', 
    BeamCol3: 'Classic Cream', 
    BeamCol3MatchBeam: 'Classic Cream', 
    BeamType4: '1586510', 
    BeamList4: 'Fascia Box', 
    BeamName4: 'BeamName4', 
    BeamCol4: 'Classic Cream', 
    BeamCol4MatchBeam: 'Classic Cream', 
    PostType: '1586510', 
    PostList: 'Gazebo Post', 
    PostCol: 'Classic Cream', 
    PostColMatchBeam: 'Classic Cream', 
  },
  ROOF_SHEETING: {
    SECTION_NAME: "ROOF_SHEETING",
    RoofType: 'Corrugated - C/Bond (Trafficable)', 
    RoofSubType: '', 
    RoofColour: 'Classic Cream/Grey', 
    RoofRolling: 'Top Side',
    SkylightRolling: 'Under Side',
    SheetLaying: 0,
    SheetLayingOffset: 0,
  },
}