import React from 'react';
import ReactDOM from 'react-dom'
import Amplify, { Analytics, Storage } from 'aws-amplify';
import { withAuthenticator, S3Album } from 'aws-amplify-react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Lightbox from 'react-image-lightbox';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Linkify from 'react-linkify';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { Palette } from '../AppConfig';

// Redux Actions
import { connect } from 'react-redux';
import { updateCustomer, updateSiteParameters } from '../redux_store/jobState';
// import * as JOB from '../redux_store/jobState';
import { toggleDesigner } from '../redux_store/appState';
import { SHOW_NOTES_PANEL } from '../redux_store/appState';
import AddressAutoComplete from './AddressAutoComplete';
const importedActions = { updateCustomer, updateSiteParameters, toggleDesigner };
const mapStateToProps = state => ({
    job: state.job
});


const styles = {
    root: {
        padding: '20px',
        width: '700px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
    },
    media: {
        // ⚠️ object-fit is not supported by IE 11.
        objectFit: 'cover',
    },
    header: {
        fontSize: "24px",
        margin: "00px -20px 5px -20px",
        padding: "20px 0px 10px 20px",
        width: "110%",
    },
    formControlFull: {
        paddingRight: "5px",
        width: "100%",
    },
    formControl: {
        paddingRight: "5px",
    },
    closeButton: {
        position: "absolute",
        right: "0px",
        color: "white",
        width: "64px",
        height: "64px"
    },
    comment_data: {
        textAlign: 'right',
        color: 'red',
        fontSize: '50%',
        flexBasis: '2',
        width: '15%',
    },
    system_text: {
        color: '#AAA',
        flexBasis: '7',
        width: '90%',
        fontSize: '70%',
    },
    comment_text: {
        color: '#111',
        flexBasis: '7',
        width: '90%',
        fontSize: '80%',
    },
    comments: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        height: '100%',
        overflow: 'auto',
    },
    comment: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        borderBottom: '1px dotted #EEF',
        padding: '5px',
    },
    systemComment: {
        background: '#EFEFEF',
    },
    textField: {
        width: '100%',
        display: 'flex',
        flex: '1',
    },
    textFieldInput: {
        fontSize: '80%',
    },
    orangeAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepOrange[500],
        width: '32px',
        height: '32px',
        margin: '0px 5px 0px 5px',
        // fontSize: '70%',
    },
    fieldersAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: Palette.FieldersBlue,
        width: '24px',
        height: '24px',
        margin: '0px 5px 0px 5px',
        fontSize: '80%',
    },
    link: {
        verticalAlign: 'top',
    },
    thumb: {
        flex: 1,
        margin: 5,
        height: 75,
        cursor: "pointer",
        boxShadow: '5px 5px 5px #CCC'
    },
    thumb_sm: {
        flex: 1,
        margin: 5,
        height: 50,
        cursor: "pointer",
        boxShadow: '5px 5px 5px #CCC'
    },
    button: {
    },
    new_message_buttons: {
        position: 'absolute',
        float: 'right',
        bottom: '10px',
        right: '20px',
    },
};

class NotesPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            imageSet: [],
            photoIndex: 0,
            imageTitle: "",
            imageCaption: "",
            lightBoxIsOpen: false,

            new_comment: {
                timestamp: 1,
                system_generated: false,
                user: "",
                user_code: "",
                text: "",
                attachments: [
                    {
                        image: true,
                        url: "images/SampleImage1.jpg",
                        thumb: "images/SampleImage1_thumb.jpg"
                    }
                ],
            }

        };
        this.openLightBox = this.openLightBox.bind(this);
        this.commentsPanel = React.createRef();
    }

    componentDidMount() {
        const node = ReactDOM.findDOMNode(this.commentsPanel.current);
        node.scrollTop = node.scrollHeight;
    }

    openLightBox = (index, images) => event => {
        this.setState({ lightBoxIsOpen: true, photoIndex: index, imageSet: images });
        event.stopPropagation();
        return false;
    }

    uploadFile = (evt) => {
        const file = evt.target.files[0];
        const name = file.name;

        Storage.put(name, file).then(() => {
            this.setState({ file: name });
        })
    }

    render() {

        const { imageSet, photoIndex, lightBoxIsOpen, expanded } = this.state;
        const { classes, updateCustomer, updateSiteParameters, toggleDesigner } = this.props;
        const { Notes } = this.props.job;

        var notes = [];
        let note_images_all = [];
        let note_images_all_n = 0;
        for (const note of Notes) {
            let date = new Date(note.timestamp);

            let note_images = [];
            let attachment_docs_list = [];

            for (const image of note.attachments) {
                if (image.image) {
                    note_images.push(image);
                    note_images_all.push(image);
                }
                else {
                    let filename = image.url.substring(image.url.lastIndexOf('/') + 1);
                    attachment_docs_list.push(<Typography>
                        <Link href={image.url} target="_blank" rel="noreferrer" variant="body2" className={classes.link}>
                            <AttachmentIcon style={{ fontSize: 24 }} />{filename}
                        </Link>
                    </Typography>);
                }
            }

            let attachment_thumbs = [];
            if (note_images.length > 0) note_images.forEach((example, image_index) => {
                attachment_thumbs.push(
                    <img key={image_index + example.url} alt="" src={example.thumb} className={classes.thumb} onClick={this.openLightBox(note_images_all_n, note_images_all)} />
                );
                note_images_all_n++;
            });

            notes.push(<div key={note.timestamp} className={classes.comment + (note.system_generated ? (" " + classes.systemComment) : "")}>
                <Avatar className={note.system_generated ? classes.fieldersAvatar : classes.orangeAvatar}>{note.user_code}</Avatar>
                <span className={note.system_generated ? classes.system_text : classes.comment_text}>
                    <Linkify>{note.text}</Linkify>
                    {attachment_thumbs.length > 0 &&
                        <span>
                            <br />
                            {attachment_thumbs}
                        </span>
                    }
                    {attachment_docs_list.length > 0 &&
                        <span>
                            <br />
                            {attachment_docs_list}
                        </span>
                    }
                </span>
                <span className={classes.comment_data}>{date.toDateString()}<br />{date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</span>

            </div>);
        }

        let new_images = [];
        let new_attachment_docs_list = [];
        if (this.state.new_comment && this.state.new_comment.attachments) {
            for (const new_attachment of this.state.new_comment.attachments) {
                if (new_attachment.image) {
                    new_images.push(new_attachment);
                }
                else {
                    let filename = new_attachment.url.substring(new_attachment.url.lastIndexOf('/') + 1);
                    new_attachment_docs_list.push(<Typography>
                        <Link href={new_attachment.url} target="_blank" rel="noreferrer" variant="body2" className={classes.link}>
                            <AttachmentIcon style={{ fontSize: 24 }} />{filename}
                        </Link>
                    </Typography>);
                }
            }
        }

        let new_attachment_images = [];
        if (new_images.length > 0) new_images.forEach((example, image_index) => {
            new_attachment_images.push(
                <img key={image_index + example.url} alt="" src={example.thumb} className={classes.thumb_sm} onClick={this.openLightBox(image_index, new_images)} />
            );
        });


        return (
            <div className={classes.root}>
                <IconButton className={classes.closeButton} onClick={() => toggleDesigner(SHOW_NOTES_PANEL)}>
                    <CloseIcon />
                </IconButton>
                <h4 className={classes.header}>NOTE LOG</h4>
                <br />

                <Paper className={classes.comments} ref={this.commentsPanel}>
                    {notes}
                </Paper>

                <TextField
                    id="outlined-multiline-static"
                    label="Add Note"
                    multiline
                    rows="2"
                    defaultValue=""
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    helperText="max 155 characters (0/155)"
                    InputProps={{
                        classes: {
                            input: classes.textFieldInput,
                        },
                    }}
                // onChange={}
                />


                {/* <input type="file" onChange={this.uploadFile} /> */}

                <input
                    accept="image/jpeg, image/png, application/pdf"
                    className={classes.input}
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={this.uploadFile}
                />

                <div className={classes.new_message_buttons}>
                    <label htmlFor="raised-button-file">
                        <IconButton component="span" className={classes.button}>
                            <AttachmentIcon style={{ fontSize: 20 }} />
                        </IconButton>
                    </label>
                    <IconButton className={classes.button} aria-label="Post">
                        <ArrowRightIcon />
                    </IconButton>
                </div>

                {new_attachment_images &&
                    <div>
                        {new_attachment_images}
                    </div>
                }

                {lightBoxIsOpen && (
                    <Lightbox
                        mainSrc={imageSet[photoIndex].url}
                        nextSrc={imageSet[(photoIndex + 1) % imageSet.length].thumb}
                        prevSrc={imageSet[(photoIndex + imageSet.length - 1) % imageSet.length].thumb}
                        onCloseRequest={() => this.setState({ lightBoxIsOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + imageSet.length - 1) % imageSet.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % imageSet.length,
                            })
                        }
                        imageTitle={imageSet[photoIndex].title}
                        imageCaption={imageSet[photoIndex].caption}
                        reactModalStyle={{ overlay: { zIndex: 9999 } }}
                    />
                )}
            </div>
        );
    };
}

export default connect(mapStateToProps, importedActions)(withStyles(styles)(NotesPanel))