import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';


const styles = {
    card: {
      maxWidth: 420,
    },
    media: {
      // ⚠️ object-fit is not supported by IE 11.
      objectFit: 'cover',
    },
    newspaper: {
      padding: 50,
    }
  };

  function NewsPaper(props) {
    const { classes } = props;
    return (
      <>
      <Grid container spacing={24} direction="row">
          <Grid item xs={9}>
              <h2 style={{marginTop: "0px", marginBottom: "0px"}}>CENTENARY NEWS AND UPDATES</h2>
          </Grid>
          <Grid item xs={3}>

          </Grid>
      </Grid>
          <Paper className={classes.newspaper} elevation={1}>
            <h3>Fielders SlimFlor®: A Product Fit for a Tower</h3>
            <Typography component="p">
            Fielders collaborated with Synergy Constructions and PT Design to deliver its renowned integrated steel flooring solution to 115 King William Street – a 23-storey office tower in the heart of the Adelaide CBD.

The project began in August 2015, and completed in late 2016, with the building opened to tenants in November 2016. As the fourth tallest building in Adelaide, the towering structure is a striking and prominent addition to the busy city street.

The building boasts open-planned and light-filled office spaces throughout, and is surrounded by everything the CBD has to offer, including up-scale restaurants, Adelaide’s key shopping precinct, Rundle Mall, and all four major banks.
            </Typography>
            <Divider style={{margin: "25px 15px"}}/>
            
            <h3>Introducing COLORBOND® steel Matt</h3>
            <Typography component="p">
            Fielders is proud to now offer the latest addition to the versatile COLORBOND® steel range – COLORBOND® steel Matt.

COLORBOND® steel Matt offers a softer look in neutral tones, allowing you to make a sophisticated and personal design statement. Available in five popular COLORBOND® steel colours, COLORBOND® steel Matt incorporates Thermatech® solar reflectance technology, designed to reflect more of the sun’s heat on hot sunny days, as well as industry-leading Activate® technology to provide enhanced corrosion resistance. COLORBOND® steel Matt is perfectly suited to Fielders range of Finesse cladding profiles.</Typography>

{/* <iframe title="steel" src="https://www.youtube.com/embed/RuSkkquOZH4" width="100%" height="315" frameBorder="0" allowFullScreen="allowfullscreen"></iframe> */}

<Divider style={{margin: "25px 15px"}}/>
            
            <h3>Fielders re-signs as a Port Adelaide Club Partner</h3>
            <Typography component="p">
            Fielders is happy to announce our continued support the Port Adelaide Football Club by signing on as a Club Partner for the 2018 and 2019 seasons.
            </Typography>
            {/* <iframe title="footy" src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FPortAdelaideFC%2Fvideos%2F10155488645538882%2F&show_text=0&width=560" width="560" height="315" style={{border:"none",overflow:"hidden"}} scrolling="no" frameBorder="0" allowFullScreen={true}></iframe> */}
          </Paper>
          </>
    );
  }

const mapStateToProps = state => ({
    // posts: state.posts
});

export default connect(mapStateToProps, null)(withStyles(styles)(NewsPaper));