import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

const styles = theme => ({
    whiteText : {
        color: "white",
        '&:focus':{
          fontSize: '10px',
          color: '#c1c1c2',
        }
    },
    orangeText : {
        color: "orange",
        '&:focus':{
          fontSize: '10px',
          color: '#c1c1c2',
        }
    },
    underline: {
        borderBottomColor: 'orange',
        '&:after': {
          // The MUI source seems to use this but it doesn't work
          borderBottomColor: 'orange',
        },
        '&:before': {
          // The MUI source seems to use this but it doesn't work
          borderBottom: 'none',
        },
    },
    cssLabel: {
        focused: {
            color: "orange"
        },
        cssFocused: {
          color:'orange',
        },
    },
    select: {
        color: "white",
        textAlign: "center",
        focused: {
            color: "orange"
        },
    },
    focused: {},
});

class DarkSelect extends React.Component {

    render() {
        const { classes } = this.props;
        
        return (

                <FormControl style={{ width: "100%", textAlign: "left" }}>
                    <InputLabel htmlFor="roofsheetSubType" shrink={true}
                        className={classes.whiteText}
                        // FormLabelClasses={classes}
                        FormLabelClasses={{
                            root: classes.cssLabel,
                            focused: classes.focused,
                        }}
                    >{this.props.labelText}</InputLabel>
                    <Select
                        value={this.props.value}
                        className={classes.select}
                        onChange={this.props.onChange()}
                        name="Colour"

                        input={<Input
                            classes={{
                                focused: classes.orangeText,
                                underline: classes.underline,
                            }}
                            name="roofsheetSubType"
                            id="roofsheetSubType" />
                        }
                        // classes={classes}
                    >
                        {this.props.options}
                    </Select>
                </FormControl>
        );
    }
}

export default (withStyles(styles)(DarkSelect));