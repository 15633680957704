import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import BabylonRoofSheetTools from './BabylonRoofSheetTools';
import BabylonToolAccordian from './BabylonToolAccordian';

// Redux Actions
import { connect } from 'react-redux';
import { changePage, toggleDesigner, updateDesigner, toggle3DDesigner, update3DDesigner } from '../redux_store/appState';
import * as APP from '../redux_store/appState';
import BabylonDesignerToolDrawerMain from './BabylonDesignerToolDrawerMain';
const importedActions = { changePage, toggleDesigner, toggle3DDesigner };
const mapStateToProps = state => ({
  app: state.app,
  job: state.job
});

// BabyloneJS data store
const _scene_data = window.scene_data;
const BABYLON = window.BABYLON;

const toolDrawerWidth = 280;

const styles = theme => ({
  root: {
    display: 'flex',
    overflowX: 'hidden',
  },
  drawer: {
    width: 'toolDrawerWidth',
    flexShrink: '0',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },
  drawerPaper: {
    overflowX: 'hidden',
  },
  
  toolDrawerOpen: {
    // backgroundColor: "rgba(242,245,251, 0.5)",
    color: "white",
    backgroundColor: "rgba(20,20,20, 0.5)",
    width: toolDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolDrawerClose: {
    color: "white",
    backgroundColor: "rgba(20,20,20, 0.5)",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: "48px",
    ...theme.mixins.toolbar,
  },
  toolMenuText : {
    color: "white",
    textAlign: "right",
  },
  toolMenuOn : {
    color: 'orange',
    textShadow: '0px 0px 7px rgba(107,107,107,0.89)',
  },
  toolMenuOff : {
    color: 'white',
    textShadow: '0px 0px 7px rgba(107,107,107,0.89)',
  },
  toolListItem : {
    paddingRight: "0px",
    minWidth: toolDrawerWidth,
  }
});

class BabylonDesignerToolDrawer extends React.Component {

  toggleHouseModel = () => {
    const SHOW_HOUSE_MODEL = this.props.app.designer.designer3D[APP.SHOW_HOUSE_MODEL];
    var visibility = !SHOW_HOUSE_MODEL;
    this.toggleEaseInOutMeshes(_scene_data.house_meshes, visibility);
    this.props.toggle3DDesigner(APP.SHOW_HOUSE_MODEL);
  }
  
  toggleVerandahModel = () => {
    const SHOW_VERANDAH_MODEL = this.props.app.designer.designer3D[APP.SHOW_VERANDAH_MODEL];
    var visibility = !SHOW_VERANDAH_MODEL;
    this.toggleEaseInOutMeshes(_scene_data.verandah_meshes, visibility);
    this.props.toggle3DDesigner(APP.SHOW_VERANDAH_MODEL);
  }

  toggleEaseInOutMeshes = (meshes, visibility) => {
    // setTimeout(() => {
    //   if (meshes) {
    //     for (var mesh of meshes) {
    //       mesh.isVisible = visibility;
    //     }
    //   }
    // }, 100);

    // animate
    setTimeout(() => {
      var mesh;
      if (meshes) {
        for (mesh of meshes) {
          
          var ease = new BABYLON.CubicEase();
          ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
          if (_scene_data.mesh_picked === mesh) {
            _scene_data.mesh_picked = null;
            _scene_data.join_line.linkWithMesh(null);
            _scene_data.join_line.isVisible = false;
          }

          if (visibility === true) {
            mesh.material.alpha = 0.0;
            mesh.isVisible = true;
          }

          mesh.isPickable = visibility;
          // mesh.isVisible = house_visible;
          if (!visibility) {
            BABYLON.Animation.CreateAndStartAnimation('at5', mesh, 'material.alpha', 90, 25, 1.0, 0, 0, ease);
            // BABYLON.Animation.CreateAndStartAnimation('at5', mesh, 'position.y', 90, 25, mesh.position.y-100, mesh.position.y, 0, ease);
          }
          else {
            BABYLON.Animation.CreateAndStartAnimation('at5', mesh, 'material.alpha', 90, 25, 0, 1.0, 0, ease);
            BABYLON.Animation.CreateAndStartAnimation('at5', mesh, 'position.y', 90, 25, mesh.position.y+200, mesh.position.y, 0, ease);
          }
        }
      }
    }, 0);
    setTimeout(() => {
      var mesh;
      if (meshes) {
        for (mesh of meshes) {
          mesh.isVisible = visibility;
        }
      }
    }, 400);
  };

  render() {
    const { classes, toggleDesigner, toggle3DDesigner } = this.props;
    const TOOL_DRAWER_OPEN = this.props.app.designer[APP.TOOL_DRAWER_OPEN];
    const BABYLON_SELECTED_ITEM = this.props.app.designer.designer3D[APP.BABYLON_SELECTED_ITEM];

    return (
      <Drawer
        variant="permanent"
        anchor="right"
        className={classNames(classes.drawer, {
          [classes.toolDrawerOpen]: TOOL_DRAWER_OPEN,
          [classes.toolDrawerClose]: !TOOL_DRAWER_OPEN,
        })}
        classes={{
          paper: classNames(classes.drawerPaper,{
            [classes.toolDrawerOpen]: TOOL_DRAWER_OPEN,
            [classes.toolDrawerClose]: !TOOL_DRAWER_OPEN,
          }),
        }}
        open={TOOL_DRAWER_OPEN}
      >
        <div className={classes.toolbar} style={{minHeight: "48px"}}/>
        {/* {(() => {
          switch (BABYLON_SELECTED_ITEM) {
            case "group_4 group_all":
              return <BabylonRoofSheetTools />;
            default:
              return (
                <>
                  <span style={{ width: "100%", textAlign: "right" }}><IconButton style={{ width: "48px", marginRight: "5px" }} onClick={() => toggleDesigner(APP.TOOL_DRAWER_OPEN)}>{TOOL_DRAWER_OPEN ? <ChevronRightIcon className={classes.toolMenuOff} /> : <ChevronLeftIcon className={classes.toolMenuOff} />}</IconButton></span>
                  <BabylonDesignerToolDrawerMain />
                </>
              );
          }
        })()} */}
                  <span style={{ width: "100%", textAlign: "right" }}><IconButton style={{ width: "48px", marginRight: "5px" }} onClick={() => toggleDesigner(APP.TOOL_DRAWER_OPEN)}>{TOOL_DRAWER_OPEN ? <ChevronRightIcon className={classes.toolMenuOff} /> : <ChevronLeftIcon className={classes.toolMenuOff} />}</IconButton></span>
                  
                  {/* <BabylonRoofSheetTools /> */}
                  <BabylonToolAccordian />
      </Drawer>
    );
  }
}

export default connect(mapStateToProps, importedActions)(withStyles(styles)(BabylonDesignerToolDrawer));