import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import JobCard from './JobCard';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Redux Actions
import { connect } from 'react-redux';
import { changePage, DESIGNER_PAGE } from '../redux_store/appState';
import FreeStandingDesignDialog from './FreestandingDesignDialog';
const importedActions = { changePage };
const mapStateToProps = state => ({
  app: state.app
});


const styles = {
  card: {
    maxWidth: 420,
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },
};

// function NewQuotesLayout(props) {
class NewQuotesLayout extends React.Component {
  state = {
    showFreeStandingDesignDialog: false,
  };
  
  handleshowFreeStandingDesignDialogOpen = () => {
    this.setState({ showFreeStandingDesignDialog: true });
  };

  handleshowFreeStandingDesignDialogClose = () => {
    this.setState({ showFreeStandingDesignDialog: false });
  };

  render() {
    const { classes, changePage } = this.props;
    return (
      <div>
        <FreeStandingDesignDialog open={this.state.showFreeStandingDesignDialog} handleClose={this.handleshowFreeStandingDesignDialogClose} />
        <h2>CREATE A NEW QUOTE</h2>
        <Grid container spacing={24} direction="row"
          justify="center"
          alignItems="flex-start">
          <Grid item xs={3}>
            <JobCard key={0} image="images/new.jpg"
              first_button_text="Create"
              title="New Attached Design"
              detail_text="Flat • Tapered Flats • Flat-Gable-Flat • Gable • etc..."
              mainAction={() => changePage(DESIGNER_PAGE)} />
          </Grid>
          <Grid item xs={3}>
            <JobCard key={1} image="images/carport-slider-9-1280x561.jpg"
              first_button_text="Create"
              title="Freestanding Designs"
              detail_text="Flat Carports • BBQ Shelters • 8/6 Sided Gazebos • Cantilever Structures • etc..."
              mainAction={() => this.handleshowFreeStandingDesignDialogOpen()} />
            </Grid>
          <Grid item xs={3}>
            <JobCard key={2} image="images/job1.jpg"
              first_button_text="Create"
              title="Freestyle Designer"
              detail_text="An ADVANCED tool for creating more custom design flats..." />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(mapStateToProps, importedActions)(withStyles(styles)(NewQuotesLayout));