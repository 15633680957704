import React from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import JobCard from './JobCard';

// Redux Actions
import { connect } from 'react-redux';
import { gazebo_default_app_state } from '../redux_store/state_library/gazebo'
import { changePage, DESIGNER_PAGE, updateDesigner, THREED_MODE, loadAppTemplate } from '../redux_store/appState';
const importedActions = { changePage, updateDesigner, loadAppTemplate };
const mapStateToProps = state => ({
  app: state.app
});

class FreeStandingDesignDialog extends React.Component {
  state = {
    open: false,
    scroll: 'paper',
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  designGazebo = () => {
    this.props.loadAppTemplate(gazebo_default_app_state);
    // this.props.changePage(DESIGNER_PAGE);
    // this.props.updateDesigner(THREED_MODE, true);
  };




  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => this.props.handleClose()}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">Choose a Design Type</DialogTitle>
          <DialogContent>
            
          <JobCard key={0} image="images/designtypes_gazebo.jpg"
              // first_button_text="Create"
              noMaxWidth={true}
              title="Gazebo Design (6 or 8 Sided)"
              detail_text="While they often feature in the gardens of grand estate homes, gazebos are modern and popular addition to homes across Australia.."
              mainAction={() => this.designGazebo()} />

            {/* <Paper style={{borderRadius: "14px"}} onClick={() => this.designGazebo()}>
            <img src={"images/designtypes_gazebo.jpg"} alt="Gazebo" width="100%" style={{borderRadius: "14px 14px 0px 0px"}}/>
            <div style={{padding: "5px"}}>
                <Typography gutterBottom variant="h6" component="h2">
                    Gazebo Design (6 or 8 Sided)
                </Typography>
                <Typography component="p">
                    While they often feature in the gardens of grand estate homes, gazebos are modern and popular addition to homes across Australia. 
                </Typography>
            </div>
            </Paper> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.handleClose()}color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(mapStateToProps, importedActions)(FreeStandingDesignDialog);
// export default FreeStandingDesignDialog;