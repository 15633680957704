import React, { Component } from 'react';
import './App.css';

// AWS and Authentication
import Amplify from 'aws-amplify';
import AWSSettings from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react'; // or 'aws-amplify-react-native';
import { signUpConfig, signUpComponents } from './AppConfig';

// Material UI
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiTheme } from './AppConfig';

// App Components
import TopBar from './components_main/TopBar';
import Main from './components_main/Main';
import Designer from './components_designer/Designer';

// Redux Actions
import { connect } from 'react-redux';
import { MAIN_PAGE, DESIGNER_PAGE } from './redux_store/appState';
const importedActions = null;
const mapStateToProps = state => ({
  app: state.app
});

// AWS and Authentication
Amplify.configure(AWSSettings);

// Material UI
const theme = createMuiTheme(MuiTheme);
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

class App extends Component {
  render() {
    const { page } = this.props.app; 

    if (page === DESIGNER_PAGE) document.documentElement.style.overflow = "hidden";
    return (
        <MuiThemeProvider theme={theme}>
          <div className={"App"}>
          
          <TopBar />
          {(page === null || page === MAIN_PAGE) &&
            <Main />
          }
          {page === DESIGNER_PAGE &&
            <Designer />
          }
          
          </div>
        </MuiThemeProvider>
    );
  }
}

// export default App;
export default connect(mapStateToProps, importedActions)(App);
// export default connect(mapStateToProps, importedActions)(withAuthenticator(App, { signUpConfig }, { signUpComponents }));
