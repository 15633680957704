// CUSTOMER Schema
// export const CUSTOMER = { // matched to VQU
//     VQU_SECTION_NAME: "Customer",
//     JobName: 'JOB_NAME', 
//     FirstName: 'FIRST_NAME', 
//     LastName: '', 
//     Email: '',
//     ADDRESS: 'ADDRESS',
//     LATLNG: 'LATLNG',
// }

export const customer_initialState = {
  JobName: "",
  FirstName: "",
  LastName: "",
  PhoneNumber: "",
  Email: "",
  Address: "",
  LatLng: "",
}

// // QUOTE PARAMETERS Schema
// export const QUOTE = { // matched to VQU
//     BUILDING_SURVEYOR: 'BuildingSurveyor', 
//     DELIVERY_NOTES: 'DeliveryNotes', 
//     DELIVERY_DATE: 'DeliveryDate', 
//     SALES_REP_ID: 'SalesRepID',
//     DISTRIBUTOR_ID: 'DistributorID',
//     ALTERNATE_ID: 'AlternateID',
//     material_parameters: {
//         NOBULKPACKING : 'NoBulkPacking',
//         MORETOADD : '...',
//     },
//     pricing_parameters: {
//         SHOWCOSTKIND : 'ShowCostKind',
//         MORETOADD : '...',
//     }
// }

export const QUOTE_PARAMETER_SECTIONS = {
  MATERIAL_PARAMETERS: "MATERIAL_PARAMETERS",
  PRICING_PARAMETERS: "PRICING_PARAMETERS",
}

export const quote_parameters_initialState = {
  ID: "",
  BuildingSurveyor: "",
  DeliveryNotes: "",
  DeliveryDate: "",
  SalesRepID: "",
  DistributorID: "",
  AlternateID: "",
  [QUOTE_PARAMETER_SECTIONS.MATERIAL_PARAMETERS]: {
    NoBulkPacking: 1,
  },
  [QUOTE_PARAMETER_SECTIONS.PRICING_PARAMETERS]: {
    ShowCostKind: false,
  }
}

// // SITE PARAMETERS Schema
// export const SITE = { // matched to VQU
//     VQU_SECTION_NAME: "JOB_DATA",
//     ADDRESS: 'Address', 
//     SITESTATE: 'SiteState', 
//     LATLNG: 'LatLng',
//     WINDZONE: 'WindZone',
//     WIND_REVOLUTIO: 'Wind_Revolutio',
//     HOUSE_PARAMETERS: {
//         ROOFPITCH : 'RoofPitch',
//         ROOFTYPE : 'RoofType',
//         ROOFCOLOUR : 'RoofColour',
//         WALLTYPE : 'WallType',
//         MORETOADD : '...',
//     }
// }


export const SITE_PARAMETER_SECTIONS = {
  HOUSE_PARAMETERS: "HOUSE_PARAMETERS",
}

export const site_parameters_initialState = {
  Address: "",
  SiteState: "",
  LatLng: "",
  WindZone: "N2",
  WindRevolutio: null,
  [SITE_PARAMETER_SECTIONS.HOUSE_PARAMETERS]: {
    RoofPitch: "22.5",
    RoofType: "Sheet",
    RoofColour: "Galvanised",
    WallType: "Timber",
  },
}

// // DESIGN PARAMETERS Schema
// export const DESIGN = { // matched to VQU
//     VQU_SECTION_NAME: "JOB_DATA",
//     FREESTANDING: 'Freestanding',
//     GAZEBOMODE: 'GazeboMode',
//     BBQMODE: 'BBQMode',
// }

export const design_parameters_initialState = {
  Freestanding: 1,
  GazeboMode: 1,
  BBQMode: 0,
}

// BASIC SPECS Schema
// export const BASIC_SPECS = { // matched to VQU
//     VQU_SECTION_NAME: "BASIC_SPECS",
//     SHAPE: {
//         DESIGNTYPE: 'DesignType', 
//         DESIGNSUBTYPE: 'DesignSubType', 
//         LENGTH: 'Length',
//         DEPTH: 'Depth',
//         HEIGHT: 'Height',
//         ROOFPITCH: 'RoofPitch',
//         SHEETDIRECTION: 'SheetDirection',
//     },
//     BEAMS_AND_POSTS: {
//         SECTION_NAME: 'BEAMS_AND_POSTS',
//         BEAM1_SECTION: 'BeamType', 
//         BEAM1_LIST: 'BeamList', 
//         BEAM1_NAME: 'BeamName', 
//         BEAM1_COLOUR: 'BeamCol', 
//         BEAM1_COLOURMATCH: 'BeamColMatchBeam', 
//         BEAM2_SECTION: 'BeamType2', 
//         BEAM2_LIST: 'BeamList2', 
//         BEAM2_NAME: 'BeamName2', 
//         BEAM2_COLOUR: 'BeamCol2', 
//         BEAM2_COLOURMATCH: 'BeamCol2MatchBeam', 
//         BEAM3_SECTION: 'BeamType3', 
//         BEAM3_LIST: 'BeamList3', 
//         BEAM3_NAME: 'BeamName3', 
//         BEAM3_COLOUR: 'BeamCol3', 
//         BEAM3_COLOURMATCH: 'BeamCol3MatchBeam', 
//         BEAM4_SECTION: 'BeamType4', 
//         BEAM4_LIST: 'BeamList4', 
//         BEAM4_NAME: 'BeamName4', 
//         BEAM4_COLOUR: 'BeamCol4', 
//         BEAM4_COLOURMATCH: 'BeamCol4MatchBeam', 
//         POST_SECTION: 'PostType', 
//         POST_LIST: 'PostList', 
//         POST_COLOUR: 'PostCol', 
//         POST_COLOURMATCH: 'PostColMatchBeam', 
//         BEAMSPACING: 'BeamSpacing',
//         POSTSPACING: 'PostSpacing',
//         SPACINGSLOCKED: 'SpacingsLocked',
//         MEMBERSLOCKED: 'MembersLocked',
//         OmitLeftBeam: 'OmitLeftBeam',
//         OmitRightBeam: 'OmitRightBeam',
//         ExternalConnectors: 'ExternalConnectors',
//         PostExternalConnectors: 'PostExternalConnectors',
//         PostAlignment: 'PostAlignment',
//         AlignPostToBeamsKey: 'AlignPostToBeamsKey',
//         MatchSideBeams: 'MatchSideBeams',
//         NumMiddlePosts: 'NumMiddlePosts',
//         PurlinSpacing: 'PurlinSpacing',
//         NumExtraPortals: 'NumExtraPortals',
//         NumExtraPurlins: 'NumExtraPurlins',
//         HasRidgeBeam: 'HasRidgeBeam',
//         SlimlineGable: 'SlimlineGable',
//     },
//     ROOF_SHEETING: {
//         SECTION_NAME: 'ROOF_SHEETING',
//         ROOFSHEET_TYPE: 'RoofType', 
//         ROOFSHEET_SUBTYPE: 'RoofSubType', 
//         ROOFSHEET_COLOUR: 'RoofColour', 
//         RoofRolling: 'RoofRolling',
//         SkylightRolling: 'SkylightRolling',
//         SheetLaying: 'SheetLaying',
//         SheetLayingOffset: 'SheetLayingOffset',

//     },
//     GUTTERS_AND_DOWNPIPES: {
//         SECTION_NAME: "GUTTERS_AND_DOWNPIPES",
//         GUTTERB: "GutterB",
//         GUTTERF: "GutterF",
//         GUTTERL: "GutterL",
//         GUTTERR: "GutterR",
//         GUTTERTYPE: 'GutterType', 
//         GUTTERCOL: 'GutterCol', 
//         BOXGUTTERWIDTH: 'BoxGutterWidth',
//         DOUBLEBOXGUTTERWIDTH: 'DoubleBoxGutterWidth',
//         BOXGUTTERCOLOUR: 'BoxGutterColour', 
//         BOXGUTTERCOLOURMATCHBEAM: 'BoxGutterColourMatchBeam',
//         DOWNPIPETYPE: 'DownpipeType', 
//         DOWNPIPECOL: 'DownpipeCol', 
//         EasyFixMitres:'EasyFixMitres',
//         HandmadeMitres: 'HandmadeMitres',
//         SlottedGutter: 'SlottedGutter',
//         GutterWithReturn: 'GutterWithReturn',
//         FullLengthGutter: 'FullLengthGutter',
//     },
//     FLASHINGS:  {
//         FLASHINGCOLOUR: 'FlashingColour', 
//         FLASHINGCOLOURMATCHSTRING: 'FlashingColourMatchString', 
//         RIDGECOLOUR: 'RidgeColour', 
//         RIDGECOLOURMATCHSTRING: 'RidgeColourMatchString', 
//     },
//     FOOTINGS: {
//         FOOTINGTYPE: 'FootingType', 
//     },
//     DECK_PARAMETERS: {
//         JoistsOnTop: 'JoistsOnTop',
//         PostOffset: 'PostOffset',
//     },
//     GABLE_PARAMETERS: {
//         InfillEndAlign1: 'InfillEndAlign1',
//         TrussInfill1: 'TrussInfill1',
//         TrussInfillColour1: 'TrussInfillColour1',
//         InfillFlashingColour1: 'InfillFlashingColour1',
//         InfillFlashingColourMatch1: 'InfillFlashingColourMatch1',
//         InfillEndAlign2: 'InfillEndAlign2',
//         TrussInfill2: 'TrussInfill2',
//         TrussInfillColour2: 'TrussInfillColour2',
//         InfillFlashingColour2: 'InfillFlashingColour2',
//         InfillFlashingColourMatch2: 'InfillFlashingColourMatch2',
//         InfillBeamHigh: 'InfillBeamHigh',

//     },
//     ENGINEERING_AND_DESIGN: {
//         RiserBracketSpacing: 'RiserBracketSpacing',
//     }
// }

export const BASIC_SPECS_SECTIONS = {
  SHAPE: "SHAPE",
  BEAMS_AND_POSTS: "BEAMS_AND_POSTS",
  ROOF_SHEETING: "ROOF_SHEETING",
  GUTTERS_AND_DOWNPIPES: "GUTTERS_AND_DOWNPIPES",
  FLASHINGS: "FLASHINGS",
  FOOTINGS: "FOOTINGS",
  DECK_PARAMETERS: "DECK_PARAMETERS",
  GABLE_PARAMETERS: "GABLE_PARAMETERS",
  ENGINEERING_AND_DESIGN: "ENGINEERING_AND_DESIGN",
}

export const basic_specs_initialState = { // matched to VQU
  SHAPE: {
    DesignType: 'Gazebo Roof',
    DesignSubType: 'Type 1,2',
    Length: 6000,
    Depth: 3000,
    Height: 2100,
    RoofPitch: 20,
    SheetDirection: 'Front',
  },
  BEAMS_AND_POSTS: {
    BeamType: '1586510',
    BeamList: 'Gazebo Beam',
    BeamName: 'BeamName',
    BeamCol: 'Classic Cream',
    BeamColMatchBeam: 'Classic Cream',
    BeamType2: '1586510',
    BeamList2: 'Gazebo Beam',
    BeamName2: 'BeamName2',
    BeamCol2: 'Classic Cream',
    BeamCol2MatchBeam: 'Classic Cream',
    BeamType3: '1586510',
    BeamList3: 'Box',
    BeamName3: 'BeamName3',
    BeamCol3: 'Classic Cream',
    BeamCol3MatchBeam: 'Classic Cream',
    BeamType4: '1586510',
    BeamList4: 'Fascia Box',
    BeamName4: 'BeamName4',
    BeamCol4: 'Classic Cream',
    BeamCol4MatchBeam: 'Classic Cream',
    PostType: '1586510',
    PostList: 'Gazebo Post',
    PostCol: 'Classic Cream',
    PostColMatchBeam: 'Classic Cream',
    BeamSpacing: '6000',
    PostSpacing: '6000',
    SpacingsLocked: true,
    MembersLocked: false,
    OmitLeftBeam: 0,
    OmitRightBeam: 0,
    ExternalConnectors: 1,
    PostExternalConnectors: 1,
    PostAlignment: 0,
    AlignPostToBeamsKey: 1,
    MatchSideBeams: 0,
    NumMiddlePosts: 0,
    PurlinSpacing: 600,
    NumExtraPortals: 1,
    NumExtraPurlins: 0,
    HasRidgeBeam: 0,
    SlimlineGable: 0,
  },
  ROOF_SHEETING: {
    SECTION_NAME: "ROOF_SHEETING",
    RoofType: 'Corrugated - C/Bond (Trafficable)',
    RoofSubType: 'Colorbond 0.42 (Trafficable)',
    RoofColour: 'Classic Cream',
    RoofRolling: 'Top Side',
    SkylightType: "Ampelite Solasafe Polycarbonate Corrugated",
    SubSkylightType: "",
    SkylightColour: "Clear AM",
    SkylightRolling: "Under Side",
    SheetLaying: 0,
    SheetLayingOffset: 0,
  },
  GUTTERS_AND_DOWNPIPES: {
    SECTION_NAME: "GUTTERS_AND_DOWNPIPES",
    GutterB: 0,
    GutterF: 1,
    GutterL: 1,
    GutterR: 1,
    GutterType: 'Hi Front Quad Gutter',
    GutterCol: 'Classic Cream',
    BoxGutterWidth: '190',
    DoubleBoxGutterWidth: '250',
    BoxGutterColour: 'Classic Cream',
    BoxGutterColourMatchBeam: 'Classic Cream',
    DownpipeType: '65mm Square Downpipe',
    DownpipeCol: 'Classic Cream',
    EasyFixMitres: 1,
    HandmadeMitres: 0,
    SlottedGutter: 0,
    GutterWithReturn: 0,
    FullLengthGutter: 0,
  },
  FLASHINGS: {
    FlashingColour: 'Classic Cream',
    FlashingColourMatchString: 'Classic Cream',
    RidgeColour: 'Classic Cream/Classic Cream',
    RidgeColourMatchString: 'Classic Cream/Classic Cream',
  },
  FOOTINGS: {
    FootingType: 'FootingType',
  },
  DECK_PARAMETERS: {
    JoistsOnTop: 'JoistsOnTop',
    PostOffset: 'PostOffset',
  },
  GABLE_PARAMETERS: {
    InfillEndAlign1: 'InfillEndAlign1',
    TrussInfill1: 'TrussInfill1',
    TrussInfillColour1: 'TrussInfillColour1',
    InfillFlashingColour1: 'InfillFlashingColour1',
    InfillFlashingColourMatch1: 'InfillFlashingColourMatch1',
    InfillEndAlign2: 'InfillEndAlign2',
    TrussInfill2: 'TrussInfill2',
    TrussInfillColour2: 'TrussInfillColour2',
    InfillFlashingColour2: 'InfillFlashingColour2',
    InfillFlashingColourMatch2: 'InfillFlashingColourMatch2',
    InfillBeamHigh: 'InfillBeamHigh',
    HipAngle1: 60,
    HipAngle2: 45,
  },
  ENGINEERING_AND_DESIGN: {
    RiserBracketSpacing: 'RiserBracketSpacing',
    JoinType: "Offset",
    JoinAngle: "0",
    JoinOffset: "0",
    JoinOffsetP: "(0,0)",
    JoinOffsetGlobal: "0",
    AngleJoinHipCapping: "1",
    StructuralBackChannel: "1",
    OmitJoinPost: "0",
    HipJunctionDir: "0",
    JoinArea: "0",
    JoinGutter: "2",
    JoinBoxGutterWidth: "190",
    MultipleAreaBeamType: "1586510",
    MultipleAreaBeamList: "Box"
  }
}