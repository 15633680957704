import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';

// BabylonJS
import * as BabylonFunctions from './BabylonFunctions';

// Redux Actions
import { connect } from 'react-redux';
import { changePage, toggleDesigner, updateDesigner, toggle3DDesigner, update3DDesigner } from '../redux_store/appState';
import * as APP from '../redux_store/appState';
import DarkSelect from '../components/DarkSelect';
import { MenuItem } from '@material-ui/core';
const importedActions = { changePage, toggleDesigner, toggle3DDesigner, update3DDesigner };
const mapStateToProps = state => ({
  app: state.app,
  job: state.job
});

// BabyloneJS data store
const _scene_data = window.scene_data;

const toolDrawerWidth = 180;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: toolDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  toolDrawerOpen: {
    // backgroundColor: "rgba(242,245,251, 0.5)",
    backgroundColor: "rgba(20,20,20, 0.5)",
    width: toolDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolDrawerClose: {
    backgroundColor: "rgba(20,20,20, 0.5)",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: "48px",
    ...theme.mixins.toolbar,
  },
  toolMenuText: {
    color: "white",
    textAlign: "right",
  },
  toolMenuOn: {
    color: 'orange',
    textShadow: '0px 0px 7px rgba(107,107,107,0.89)',
  },
  toolMenuOff: {
    color: 'white',
    textShadow: '0px 0px 7px rgba(107,107,107,0.89)',
  },
  toolListItem: {
    paddingRight: "0px",
    paddingTop: "5px",
    paddingBottom: "5px",
    minWidth: toolDrawerWidth,
  },
  toolSwitchMenuText: {
    paddingRight: "40px",
  },
  listToggles: {
    marginRight: '-5px',
  }
});

class BabylonDesignerToolDrawerMain extends React.Component {

  toggleGroundPhoto = () => {
    const SHOW_GROUND_PHOTO = this.props.app.designer.designer3D[APP.SHOW_GROUND_PHOTO];
    var visibility = !SHOW_GROUND_PHOTO;
    BabylonFunctions.toggleEaseInOutMeshes(_scene_data.ground, visibility);
    this.props.toggle3DDesigner(APP.SHOW_GROUND_PHOTO);

    if (!visibility) {
      var target_mesh = _scene_data.scene.getMeshByName("RoofSheets");
      if (target_mesh === null) target_mesh = _scene_data.verandah_meshes[0];
      BabylonFunctions.easeCameraToMesh(target_mesh);
    }
  }
  toggleHouseModel = () => {
    const SHOW_HOUSE_MODEL = this.props.app.designer.designer3D[APP.SHOW_HOUSE_MODEL];
    var visibility = !SHOW_HOUSE_MODEL;
    BabylonFunctions.toggleEaseInOutMeshes(_scene_data.house_meshes, visibility);
    this.props.toggle3DDesigner(APP.SHOW_HOUSE_MODEL);
  }

  toggleVerandahModel = () => {
    const SHOW_VERANDAH_MODEL = this.props.app.designer.designer3D[APP.SHOW_VERANDAH_MODEL];
    var visibility = !SHOW_VERANDAH_MODEL;
    BabylonFunctions.toggleEaseInOutMeshes(_scene_data.verandah_meshes, visibility);
    this.props.toggle3DDesigner(APP.SHOW_VERANDAH_MODEL);

    
    if (visibility) {
      for (let mesh of _scene_data.verandah_meshes) {
        if (mesh.id.includes("group_3")) {
          BabylonFunctions.easeCameraToMesh(mesh, true);
        }
      }
    }

    setTimeout(() => {
      const VERANDAH_MESH_MODE = this.props.app.designer.designer3D[APP.VERANDAH_MESH_MODE];
      const VERANDAH_EDGE_LINE_MODE = this.props.app.designer.designer3D[APP.VERANDAH_EDGE_LINE_MODE];
      BabylonFunctions.updateMeshMode(_scene_data.verandah_meshes, VERANDAH_MESH_MODE, VERANDAH_EDGE_LINE_MODE);
    }, 600);
  }

  updateBackgroundMode = () => event => {
    this.props.update3DDesigner(APP.BACKGROUND_MODE, event.target.value);
    const BACKGROUND_SHADOW_MODE = this.props.app.designer.designer3D[APP.BACKGROUND_SHADOW_MODE];
    BabylonFunctions.toggleEnvironment(event.target.value, BACKGROUND_SHADOW_MODE, true);

    if (event.target.value === "Photo") {
      BabylonFunctions.toggleEaseInOutMeshes(_scene_data.ground, false);
      this.props.update3DDesigner(APP.SHOW_GROUND_PHOTO, false);

      BabylonFunctions.toggleEaseInOutMeshes(_scene_data.house_meshes, false);
      this.props.update3DDesigner(APP.SHOW_HOUSE_MODEL, false);
    }
  }

  togglePhotoShadowMode = () => {
    this.props.toggle3DDesigner(APP.BACKGROUND_SHADOW_MODE);
    const BACKGROUND_MODE = this.props.app.designer.designer3D[APP.BACKGROUND_MODE];
    const BACKGROUND_SHADOW_MODE = this.props.app.designer.designer3D[APP.BACKGROUND_SHADOW_MODE];
    BabylonFunctions.toggleEnvironment(BACKGROUND_MODE, !BACKGROUND_SHADOW_MODE);
  }

  toggleVerandahMeshMode = () => {
    const VERANDAH_MESH_MODE = this.props.app.designer.designer3D[APP.VERANDAH_MESH_MODE];
    const VERANDAH_EDGE_LINE_MODE = this.props.app.designer.designer3D[APP.VERANDAH_EDGE_LINE_MODE];
    var mesh_mode = !VERANDAH_MESH_MODE;

    BabylonFunctions.updateMeshMode(_scene_data.verandah_meshes, mesh_mode, VERANDAH_EDGE_LINE_MODE);
    this.props.toggle3DDesigner(APP.VERANDAH_MESH_MODE);
  }

  toggleVerandahEdgeLineMode = () => {
    const VERANDAH_MESH_MODE = this.props.app.designer.designer3D[APP.VERANDAH_MESH_MODE];
    const VERANDAH_EDGE_LINE_MODE = this.props.app.designer.designer3D[APP.VERANDAH_EDGE_LINE_MODE];
    var edge_mode = !VERANDAH_EDGE_LINE_MODE;

    BabylonFunctions.updateMeshMode(_scene_data.verandah_meshes, VERANDAH_MESH_MODE, edge_mode);
    this.props.toggle3DDesigner(APP.VERANDAH_EDGE_LINE_MODE);
  }
  
  toggleVerandahPlanLayout = () => {
    const SHOW_VERANDAH_LAYOUT = this.props.app.designer.designer3D[APP.SHOW_VERANDAH_LAYOUT];
    var visibility = !SHOW_VERANDAH_LAYOUT;
    // BabylonFunctions.toggleEaseInOutMeshes(_scene_data.twod_plan, visibility);
    _scene_data.twod_plan.isVisible = visibility;
    
    // var ease = new window.BABYLON.CubicEase();
    // ease.setEasingMode(window.BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
    // if (!visibility) window.BABYLON.Animation.CreateAndStartAnimation('at5', _scene_data.twod_plan, 'material.alpha', 90, 25, 1.0, 0, 0, ease);
    // else window.BABYLON.Animation.CreateAndStartAnimation('at5', _scene_data.twod_plan, 'material.alpha', 90, 25, 1.0, 0, 0, ease);
    this.props.toggle3DDesigner(APP.SHOW_VERANDAH_LAYOUT);
  }

  

  loadGazebo = () => {
    console.log("gazebo");
    var scene = _scene_data.scene;

    if (_scene_data.meshes.gazebo) {

      let visibility = !_scene_data.meshes.gazebo[0].isVisible;

      BabylonFunctions.toggleEaseInOutMeshes(_scene_data.meshes.gazebo, visibility);

      if (visibility) {
        for (let mesh of _scene_data.meshes.gazebo) {
          if (mesh.id.includes("group_3")) {
            BabylonFunctions.easeCameraToMesh(mesh, true);
          }
        }
      }
      return;
    }
    // const SHOW_VERANDAH_MODEL = this.props.app.designer.designer3D[APP.SHOW_VERANDAH_MODEL];
    // const VERANDAH_MESH_MODE = this.props.app.designer.designer3D[APP.VERANDAH_MESH_MODE];
    // const VERANDAH_EDGE_LINE_MODE = this.props.app.designer.designer3D[APP.VERANDAH_EDGE_LINE_MODE];

    // window.BABYLON.SceneLoader.ImportMesh(null, "http://localhost:62027/api/OBJ/", "gazebo6.obj", scene, function (meshes) {
    window.BABYLON.SceneLoader.ImportMesh(null, "models/", "gazebo6.obj", scene, function (meshes) {

      try {

        // for (let mesh of meshes) mesh.isVisible = false;

        let floor = meshes[0];
        let floor_width = floor.getBoundingInfo().boundingBox.extendSize.x * 0.1;
        let floor_depth = floor.getBoundingInfo().boundingBox.extendSize.y * 0.1;

        // floor.receiveShadows = true;
        // floor.isPickable = false;

        for (let mesh of meshes) {
          if (mesh.id.includes("_mm1")
            || mesh.id.includes("_mm2")
            || mesh.id.includes("_mm3")
            || mesh.id.includes("group_1")
            || mesh.id.includes("group_6")) mesh.dispose();
        };
        meshes = meshes.filter(item => (!item.id.includes("_mm1")
          && !item.id.includes("_mm2")
          && !item.id.includes("_mm3")
          && !item.id.includes("group_1")
          && !item.id.includes("group_6")));

        _scene_data.meshes.gazebo = meshes;

        for (let mesh of meshes) {

          // if (mesh.id.includes("_mm1")) {mesh.dispose()};
          // if (mesh.id.includes("_mm3")) mesh.isVisible = false;
          // if (mesh.id.includes("_mm2")) mesh.isVisible = false;
          // if (mesh.id.includes("group_1")) mesh.isVisible = false;

          mesh.rotate(window.BABYLON.Axis.X, -Math.PI / 2, window.BABYLON.Space.WORLD)
            .scaling = new window.BABYLON.Vector3(-0.1, 0.1, 0.1);
          mesh.position = new window.BABYLON.Vector3(floor_width, 0, floor_depth);
          mesh.backFaceCulling = false;
          mesh.receiveShadows = true;
          mesh.material = mesh.material.clone(mesh.id);
          mesh.material.backFaceCulling = false;


          if (mesh.id.includes("group_4")) {
            mesh.material.reflectionTexture = new window.BABYLON.CubeTexture("textures/skybox", scene);
            mesh.material.reflectionTexture.coordinatesMode = window.BABYLON.Texture.CUBIC_MODE;
            mesh.material.reflectionTexture.level = 0.06;
            mesh.material.bumpTexture = new window.BABYLON.Texture("models/ZMM_cdeck_normal.png", scene);
            mesh.material.useParallax = true;
            mesh.material.useParallaxOcclusion = true;
            mesh.material.parallaxScaleBias = 1;
            mesh.name = "RoofSheets";
          }


          if (!mesh.material.metadata) mesh.material.metadata = { last_alpha: mesh.material.alpha };

          // mesh.enableEdgesRendering(0.999);
          // mesh.edgesColor = new window.BABYLON.Color4(0.2, 0.2, 0.2, 0.4);
          // mesh.edgesWidth = 100;
          mesh.actionManager = new window.BABYLON.ActionManager(scene);
          _scene_data.makeOverOut(mesh);

          _scene_data.shadowGenerator.addShadowCaster(mesh);
          // shadowGenerator_torch.addShadowCaster(mesh);
        }



        // makeOverOut(floor);
        // floor.material.backFaceCulling = true;
        // floor.material.alpha = 0.9;
        // floor.disableEdgesRendering();
        // floor.isVisible = false;
        // floor.id = "Floor";
        for (let mesh of _scene_data.meshes.gazebo) {
          mesh.isVisible = true;
          if (mesh.id.includes("group_3")) {
            setTimeout(() => {
              BabylonFunctions.easeCameraToMesh(mesh, true);
            }, 100);
          }
        }
        // do something with the meshes and skeletons
        // particleSystems are always null for glTF assets

        // BabylonFunctions.updateMeshMode(_scene_data.gazebo_meshes, VERANDAH_MESH_MODE, VERANDAH_EDGE_LINE_MODE);




      }
      catch (err) {
        console.log(err.message);
        console.log(err);
      };


    });
  }

  render() {
    const { classes } = this.props;
    const SHOW_HOUSE_MODEL = this.props.app.designer.designer3D[APP.SHOW_HOUSE_MODEL];
    const SHOW_VERANDAH_MODEL = this.props.app.designer.designer3D[APP.SHOW_VERANDAH_MODEL];
    const VERANDAH_MESH_MODE = this.props.app.designer.designer3D[APP.VERANDAH_MESH_MODE];
    const VERANDAH_EDGE_LINE_MODE = this.props.app.designer.designer3D[APP.VERANDAH_EDGE_LINE_MODE];
    const SHOW_VERANDAH_LAYOUT = this.props.app.designer.designer3D[APP.SHOW_VERANDAH_LAYOUT];
    const BACKGROUND_MODE = this.props.app.designer.designer3D[APP.BACKGROUND_MODE];
    const BACKGROUND_SHADOW_MODE = this.props.app.designer.designer3D[APP.BACKGROUND_SHADOW_MODE];
    const SHOW_GROUND_PHOTO = this.props.app.designer.designer3D[APP.SHOW_GROUND_PHOTO];

    let background_options = ["Plain", "Sunny Background", "Cloudy Background", "Photo"].map((option, i) => {
      return (<MenuItem
        key={option}
        value={option}>
        &nbsp;{option}
      </MenuItem>)
    });


    return (
      <>
        <List style={{ paddingTop: "0px", marginLeft: "-160px" }}>
          <ListItem button className={classes.toolListItem} key={"Ground Layer"} onClick={() => { this.toggleGroundPhoto() }}>
            <ListItemText primary={"Ground Layer"} className={classes.toolMenuText} primaryTypographyProps={{ className: (SHOW_GROUND_PHOTO ? classes.toolMenuOn : classes.toolMenuOff) }} />
            <ListItemIcon><HomeIcon className={(SHOW_GROUND_PHOTO ? classes.toolMenuOn : classes.toolMenuOff)} /></ListItemIcon>
          </ListItem>
          <ListItem button className={classes.toolListItem} key={"House Model"} onClick={() => { this.toggleHouseModel() }}>
            <ListItemText primary={"House Model"} className={classes.toolMenuText} primaryTypographyProps={{ className: (SHOW_HOUSE_MODEL ? classes.toolMenuOn : classes.toolMenuOff) }} />
            <ListItemIcon><HomeIcon className={(SHOW_HOUSE_MODEL ? classes.toolMenuOn : classes.toolMenuOff)} /></ListItemIcon>
          </ListItem>
          <ListItem button className={classes.toolListItem} key={"Verandah Model"} onClick={() => { this.toggleVerandahModel() }}>
            <ListItemText primary={"Verandah Model"} className={classes.toolMenuText} primaryTypographyProps={{ className: (SHOW_VERANDAH_MODEL ? classes.toolMenuOn : classes.toolMenuOff) }} />
            <ListItemIcon><HomeIcon className={(SHOW_VERANDAH_MODEL ? classes.toolMenuOn : classes.toolMenuOff)} /></ListItemIcon>
          </ListItem>
          <ListItem button className={classes.toolListItem} key={"Load Gazebo"} onClick={() => { this.loadGazebo() }}>
            <ListItemText primary={"Load Gazebo"} className={classes.toolMenuText} primaryTypographyProps={{ className: classes.toolMenuOff }} />
            <ListItemIcon><HomeIcon className={classes.toolMenuOff} /></ListItemIcon>
          </ListItem>



          <ListItem button className={classes.toolListItem} key={"Mesh Mode"} onClick={() => this.toggleVerandahMeshMode()}>
            {/* <ListItemIcon><HomeIcon className={(VERANDAH_MESH_MODE ? classes.toolMenuOn : classes.toolMenuOff)} /></ListItemIcon> */}
            <ListItemText primary={"...Mesh Mode"} className={classes.toolMenuText + " " + classes.toolSwitchMenuText} primaryTypographyProps={{ className: (VERANDAH_MESH_MODE ? classes.toolMenuOn : classes.toolMenuOff) }} />
            <ListItemIcon><HomeIcon style={{ width: "0px" }} /></ListItemIcon>
            <ListItemSecondaryAction className={classes.listToggles}>
              <Switch
                checked={VERANDAH_MESH_MODE}
                onChange={() => this.toggleVerandahMeshMode()}
                // value="potato"
                color="secondary"
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button className={classes.toolListItem} key={"Edge Lines"} onClick={() => this.toggleVerandahEdgeLineMode()}>
            {/* <ListItemIcon><HomeIcon className={(VERANDAH_MESH_MODE ? classes.toolMenuOn : classes.toolMenuOff)} /></ListItemIcon> */}
            <ListItemText primary={"...Edge Lines"} className={classes.toolMenuText + " " + classes.toolSwitchMenuText} primaryTypographyProps={{ className: (VERANDAH_EDGE_LINE_MODE ? classes.toolMenuOn : classes.toolMenuOff) }} />
            <ListItemIcon><HomeIcon style={{ width: "0px" }} /></ListItemIcon>
            <ListItemSecondaryAction className={classes.listToggles}>
              <Switch
                checked={VERANDAH_EDGE_LINE_MODE}
                onChange={() => this.toggleVerandahEdgeLineMode()}
                // value="potato"
                color="secondary"
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button className={classes.toolListItem} key={"Plan Layout"} onClick={() => this.toggleVerandahPlanLayout()}>
            {/* <ListItemIcon><HomeIcon className={(VERANDAH_MESH_MODE ? classes.toolMenuOn : classes.toolMenuOff)} /></ListItemIcon> */}
            <ListItemText primary={"...Plan Layout"} className={classes.toolMenuText + " " + classes.toolSwitchMenuText} primaryTypographyProps={{ className: (VERANDAH_EDGE_LINE_MODE ? classes.toolMenuOn : classes.toolMenuOff) }} />
            <ListItemIcon><HomeIcon style={{ width: "0px" }} /></ListItemIcon>
            <ListItemSecondaryAction className={classes.listToggles}>
              <Switch
                checked={SHOW_VERANDAH_LAYOUT}
                onChange={() => this.toggleVerandahPlanLayout()}
                // value="potato"
                color="secondary"
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <DarkSelect
          labelText="Background"
          options={background_options}
          value={BACKGROUND_MODE}
          onChange={this.updateBackgroundMode}
        />
        <List style={{ paddingTop: "0px", marginLeft: "-160px" }}>
          <ListItem button className={classes.toolListItem} key={"Shadow Mode"} onClick={() => this.togglePhotoShadowMode()}>
            <ListItemText primary={"...Photo Shadow"} className={classes.toolMenuText + " " + classes.toolSwitchMenuText} primaryTypographyProps={{ className: (BACKGROUND_SHADOW_MODE ? classes.toolMenuOn : classes.toolMenuOff) }} />
            <ListItemIcon><HomeIcon style={{ width: "0px" }} /></ListItemIcon>
            <ListItemSecondaryAction className={classes.listToggles}>
              <Switch
                checked={BACKGROUND_SHADOW_MODE}
                onChange={() => this.togglePhotoShadowMode()}
                // value="potato"
                color="secondary"
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </>
    );
  }
}

export default connect(mapStateToProps, importedActions)(withStyles(styles)(BabylonDesignerToolDrawerMain));