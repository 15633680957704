import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import deepPurple from '@material-ui/core/colors/deepPurple';

// Components
import LayoutDesigner from './LayoutDesigner';
import CustomerJobPanel from './CustomerJobPanel';
import BabylonDesigner from './BabylonDesigner';

// Redux Actions
import { connect } from 'react-redux';
import { changePage, toggleDesigner, updateDesigner } from '../redux_store/appState';
import * as APP from '../redux_store/appState';
import DesignerMainDrawer from './Designer_MainDrawer';
import LayoutDesignerToolDrawer from './LayoutDesigner_ToolDrawer';
import BabylonDesignerToolDrawer from './BabylonDesignerToolDrawer';
import NotesPanel from './NotesPanel';
const importedActions = { changePage, toggleDesigner };
const mapStateToProps = state => ({
  app: state.app,
  job: state.job
});

const drawerWidth = 240;
const toolDrawerWidth = 160;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7 + 1,
    },
  },
  toolDrawerOpen: {
    backgroundColor: "rgba(242,245,251, 0.5)",
    width: toolDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolDrawerClose: {
    backgroundColor: "rgba(242,245,251, 0.5)",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: "48px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100%",
  },
  purpleAvatar: {
    margin: 5,
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
  grow: {
    flexGrow: 1,
    font: "22pt 'Futura', sans-serif",
    textAlign: "left",
  },
  drawerPaper: {
    backgroundColor: "rgba(242,245,251, 0.6)",
    width: 160,
  },
  toolMenuText: {
    textAlign: "right",
  },
  toolListItem: {
    paddingRight: "0px",
    minWidth: toolDrawerWidth,
  }
});

class Designer extends React.Component {
  // state = {
  //   open: false,
  // };

  // handleDrawerOpen = () => {
  //   this.setState({ open: true });
  // };

  // handleDrawerClose = () => {
  //   this.setState({ open: false });
  // };


  render() {
    const { classes, changePage, toggleDesigner } = this.props;
    const MAIN_DRAWER_OPEN = this.props.app.designer[APP.MAIN_DRAWER_OPEN];
    const SHOW_CUSTOMER_JOB_PANEL = this.props.app.designer[APP.SHOW_CUSTOMER_JOB_PANEL];
    const SHOW_NOTES_PANEL = this.props.app.designer[APP.SHOW_NOTES_PANEL];
    const THREED_MODE = this.props.app.designer[APP.THREED_MODE];
    
  console.log(this.props.app);

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: MAIN_DRAWER_OPEN,
          })}
        >
          <Toolbar disableGutters={!MAIN_DRAWER_OPEN} style={{ minHeight: "48px" }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={() => toggleDesigner(APP.MAIN_DRAWER_OPEN)}
              className={classNames(classes.menuButton, {
                [classes.hide]: MAIN_DRAWER_OPEN,
              })}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h6" color="inherit" className={classes.grow}>
              CENTENARY DESIGN CLOUD
                            </Typography>
            <Avatar className={classes.purpleAvatar}>FC</Avatar>
          </Toolbar>
        </AppBar>

        <DesignerMainDrawer />
        {/* {THREED_MODE ? <BabylonDesignerToolDrawer /> : <LayoutDesignerToolDrawer />} */}
        <BabylonDesignerToolDrawer/>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <BabylonDesigner />
          <LayoutDesigner visible={!THREED_MODE} />
      {/* <Grid container spacing={24}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}><LayoutDesigner visible={true}/></Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}><LayoutDesigner visible={!THREED_MODE} /></Paper>
          <Paper className={classes.paper}><BabylonDesigner /></Paper>
        </Grid>
      </Grid> */}
        </main>

        <Drawer anchor="left" open={SHOW_CUSTOMER_JOB_PANEL} onClose={() => toggleDesigner(APP.SHOW_CUSTOMER_JOB_PANEL)}>
          <CustomerJobPanel key={this.props.job} />
        </Drawer>
        <Drawer anchor="left" open={SHOW_NOTES_PANEL} onClose={() => toggleDesigner(APP.SHOW_NOTES_PANEL)}>
          <NotesPanel key={this.props.job} />
        </Drawer>
      </div>
    );
  }
}

export default connect(mapStateToProps, importedActions)(withStyles(styles)(Designer));