import React from 'react';
import ReactDOM from 'react-dom'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>+</div>;


const searchOptions = {
  componentRestrictions: {country: "au"},
  types: ['address']
}

const styles = {
  autoComplete: {
    border: "1px",
  },
  formControlFull: {
      paddingRight: "5px",
      width: "100%",
  },
  formControl: {
      paddingRight: "5px",
  },
  mapCross: {
    position: 'absolute',
    zIndex: '100',
    color: 'white',
    marginLeft: '42%',
    marginTop: '80px',
    fontSize: 'xx-large',
    userSelect: 'none',
    pointerEvents: 'none',
  }
};

class AddressAutoComplete extends React.Component {
  constructor(props) {
    super(props);

    var in_showMap = props.showMap?props.showMap:true;
    this.state = {
    // latLng: null,
    showMap: in_showMap,
    hasFocus: false,
   };
    this.map = null;
    this.addressInputRef = React.createRef();
  }

  
  toggleMap = () => {
    this.setState({ showMap: !this.state.showMap });
  };

  
  setBlur = () => {
    let _this = this;
    setTimeout(function(){_this.setState({hasFocus : false})}, 250);
  };

  handleApiLoaded = (map, maps) => {
    // use map and maps objects
    map.setMapTypeId('hybrid');
    map.rotateControl = false;
    map.setTilt(0);
    
    let onChangelatLng = this.props.onChangelatLng;
    this.map = map;

    map.addListener('center_changed', function() {
      onChangelatLng({lat:map.getCenter().lat(), lng:map.getCenter().lng()});
    });
  };
  

  // handleChange = address => {
  //   this.setState({ address });
  // };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        // this.setState({ latLng: latLng });
        // console.log('Success', latLng);
        this.props.onChangelatLng(latLng);
        if (this.map) {
          this.map.setCenter(new window.google.maps.LatLng(latLng.lat, latLng.lng));
        }
      })
      .catch(error => console.error('Error', error));
      this.props.onChange(address);
  };



  render() {
    const { latLng, address, classes, label_text, default_address, key } = this.props;
    const { showMap, hasFocus } = this.state;
    let search_term = address;

    let seed = Math.floor(Math.random() * 20);
    let factor = 1 + (0.002*seed);

    // let windspeed_data_mock = "Region: A1, vr: " + Math.round(39*factor) + ", vsitβ_max: " + (30.45*factor).toFixed(2) + ", , qsitβ_max: " + (0.5563*factor).toFixed(4);
    // search_term = latLng.lat + "," + latLng.lng;

    let showMapButton = this.props.enableMap?(
      <InputAdornment position="end">
        <IconButton onClick={this.toggleMap}>
          {showMap ? <LocationOnIcon /> : <LocationOffIcon />}
        </IconButton>
      </InputAdornment>
    ):"";


    return (
      <PlacesAutocomplete
        key={key}
        value={address}
        onChange={this.props.onChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
        debounce={250}
        shouldFetchSuggestions={address.length > 4}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            {/* <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
            /> */}
            <FormControl className={classes.formControlFull}>
              <InputLabel htmlFor={"AddressSearch"+key}>{label_text?label_text:"Address"}</InputLabel>
              <Input {...getInputProps({
                id: "AddressSearch"+key,
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
              ref={this.addressInputRef}
              onFocus={() => this.setState({hasFocus : true})}
              onBlur={() => this.setBlur()}
              endAdornment={showMapButton}
              />
            </FormControl>

{latLng && showMap && this.props.enableMap &&
  <div style={{ height: '200px', width: '100%' }}>
  <div className={classes.mapCross}>+</div>
  <GoogleMapReact
    bootstrapURLKeys={{ key: "AIzaSyDusbQSuHd8yi5wBk8x4skpqjH4ttqBc2w" }}
    defaultCenter={latLng}
    defaultZoom={20}
    mapTypeId={'satellite'}
    yesIWantToUseGoogleMapApiInternals
    onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
  >
    {/* <AnyReactComponent
      lat={latLng.lat}
      lng={latLng.lng}
      text="My Marker"
    /> */}
  </GoogleMapReact>
  
  <span style={{fontSize:"10px"}}>LAT: {typeof latLng.lat === "function"?latLng.lat().toFixed(5):latLng.lat.toFixed(5)}
  &nbsp;LNG: {typeof latLng.lng === "function"?latLng.lng().toFixed(5):latLng.lng.toFixed(5)}
  {/* <br /> */}
  {/* WINDSPEED DATA: {windspeed_data_mock} */}
  </span>
</div>
            // <iframe
            // title="map"
            //   width="100%"
            //   height="200px"
            //   frameborder="0"
            //   scrolling="no"
            //   marginheight="0"
            //   marginwidth="0"
            //   src={"https://maps.google.com/maps?key=AIzaSyDusbQSuHd8yi5wBk8x4skpqjH4ttqBc2w&q=" + search_term + "&maptype=satellite&z=18&output=embed"}
            // >
            // </iframe>
}

{/* <Popper open={loading || suggestions.length > 0} anchorEl={document.getElementById("AddressSearch"+key)} style={{zIndex:2000}}> */}
            <Popper open={(loading || suggestions.length > 0 || (default_address && address !== default_address)) && hasFocus} anchorEl={ReactDOM.findDOMNode(this.addressInputRef.current)} style={{zIndex:2000}}>
            <div className={classes.autoComplete + " autocomplete-dropdown-container"}>
            {default_address && address !== default_address && <div className='suggestion-item' onClick={(event) => this.handleSelect(default_address)} style={{color: "red", fontSize: "0.75rem", cursor: 'pointer', background:'white'}}>{default_address} (Customer Address)</div>}
            {/* {default_address && <div className='suggestion-item' onClick={(event) => alert(default_address)} style={{color: "red", fontSize: "0.75rem", cursor: 'pointer'}}>{default_address} (Customer Address)</div>} */}
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#f5f5f5', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
            </Popper>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}


export default withStyles(styles)(AddressAutoComplete);