import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import JobCard from './JobCard';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';


const styles = {
    card: {
        maxWidth: 345,
    },
    media: {
        // ⚠️ object-fit is not supported by IE 11.
        objectFit: 'cover',
    },
    searchbox: {
        backgroundColor: "rgba(255, 255, 255, 0.5)" ,
    },
    iconButton: {
        padding: "2px",

    },
    searchtext: {
        backgroundColor: "rgba(255, 255, 255, 0.5)" ,
    },
};

function RecallQuotesLayout(props) {
    const { classes } = props;

    let grid_card_scale = 3;
    if (document.body.clientWidth > 1400) grid_card_scale = 2;
    return (
        <div>
            <Grid container spacing={24} direction="row">
                <Grid item xs={9}>
                    <h2 style={{marginTop: "0px", marginBottom: "0px"}}>RECENT QUOTES</h2>
                </Grid>
                <Grid item xs={3}>
                    <Paper className={classes.searchbox} elevation={1}>
                        <InputBase className={classes.searchtext} placeholder="Search Quotes" />
                        <IconButton className={classes.iconButton} aria-label="Search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={24} direction="row"
                justify="top"
                alignItems="flex">
                <Grid item xs={grid_card_scale}>
                    <JobCard image="images/job1.jpg"
                    first_button_text="OPEN"
                    second_button_text="Archive"
                    third_button_text="Details"
                        title="Rowan DONOVAN"
                        detail_text="Flat Gable Combination"
                        sub_detail_text="WALKERVILLE<br />last edited: 12 hours ago" />
                </Grid>
                <Grid item xs={grid_card_scale}>
                    <JobCard image="images/AKBIGC0541_3D.jpg"
                    first_button_text="OPEN"
                    second_button_text="Archive"
                    third_button_text="Details"
                        title="Jane & James SMITH"
                        detail_text="Freestanding BBQ Shelter"
                        sub_detail_text="MILE END<br />last edited: 1 day ago" />
                </Grid>
                <Grid item xs={grid_card_scale}>
                    <JobCard image="images/BBQDSC0419_3D.jpg"
                    first_button_text="OPEN"
                    second_button_text="Archive"
                    third_button_text="Details"
                        title="JOSH MARTIN 3343"
                        detail_text="Flat Gable Combination"
                        sub_detail_text="MODBURY<br />last edited: 2 days ago" />
                </Grid>
                <Grid item xs={grid_card_scale}>
                    <JobCard image="images/BBQDSC0449_3D.jpg"
                    first_button_text="OPEN"
                    second_button_text="Archive"
                    third_button_text="Details"
                        title="JANINE REQUEL"
                        detail_text="Flat Combination"
                        sub_detail_text="PORT LINCOLN<br />last edited: 3 days ago" />
                </Grid>
                <Grid item xs={grid_card_scale}>
                    <JobCard image="images/K5FTLC0087_3D.jpg"
                    first_button_text="OPEN"
                    second_button_text="Archive"
                    third_button_text="Details"
                    title="BUSINESS PTY LTD"
                    detail_text="Flat Gable Combination"
                    sub_detail_text="ADELAIDE<br />last edited: 5 days ago" />
                </Grid>
                <Grid item xs={grid_card_scale}>
                    <JobCard image="images/BRSAQC0036_3D.jpg"
                    first_button_text="OPEN"
                    second_button_text="Archive"
                    third_button_text="Details"
                    title="Barbara Buntz"
                    detail_text="Flat Verandah"
                    sub_detail_text="PORT ADELAIDE<br />last edited: 6 days ago" />
                </Grid>
                <Grid item xs={grid_card_scale}>
                    <JobCard image="images/K5FTLC0347_3D.jpg"
                    first_button_text="OPEN"
                    second_button_text="Archive"
                    third_button_text="Details"
                    title="PORT LINCOLN AREA PRIMARY SCHOOL"
                    detail_text="Flat Combination"
                    sub_detail_text="PORT LINCOLN<br />last edited: 6 days ago" />
                </Grid>
                <Grid item xs={grid_card_scale}>
                    <JobCard disabled={true}/>
                </Grid>
            </Grid>
        </div>
    );
}
const mapStateToProps = state => ({
    // posts: state.posts
});

export default connect(mapStateToProps, null)(withStyles(styles)(RecallQuotesLayout));