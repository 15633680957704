import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ReplyIcon from '@material-ui/icons/Reply';
import deepPurple from '@material-ui/core/colors/deepPurple';

// Redux Actions
import { connect } from 'react-redux';
import { changePage, toggleDesigner, updateDesigner } from '../redux_store/appState';
import * as APP from '../redux_store/appState';
const importedActions = { changePage, toggleDesigner };
const mapStateToProps = state => ({
  app: state.app,
  job: state.job
});

const drawerWidth = 240;
const toolDrawerWidth = 160;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7 + 1,
    },
  },
  toolDrawerOpen: {
    backgroundColor: "rgba(242,245,251, 0.5)",
    width: toolDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolDrawerClose: {
    backgroundColor: "rgba(242,245,251, 0.5)",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: "48px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  purpleAvatar: {
    margin: 5,
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
  grow: {
    flexGrow: 1,
    font: "22pt 'Futura', sans-serif",
    textAlign: "left",
  },
  drawerPaper: {
    backgroundColor: "rgba(242,245,251, 0.6)",
    width: 160,
  },
  toolMenuText : {
    textAlign: "right",
  },
  toolListItem : {
    paddingRight: "0px",
    minWidth: toolDrawerWidth,
  }
});

class DesignerMainDrawer extends React.Component {

  render() {
    const { classes, changePage, toggleDesigner } = this.props;
    const MAIN_DRAWER_OPEN = this.props.app.designer[APP.MAIN_DRAWER_OPEN];
    const SHOW_CUSTOMER_JOB_PANEL = this.props.app.designer[APP.SHOW_CUSTOMER_JOB_PANEL];
    const SHOW_NOTES_PANEL = this.props.app.designer[APP.SHOW_NOTES_PANEL];
    
    const THREED_MODE = this.props.app.designer[APP.THREED_MODE];

    return (
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: MAIN_DRAWER_OPEN,
            [classes.drawerClose]: !MAIN_DRAWER_OPEN,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: MAIN_DRAWER_OPEN,
              [classes.drawerClose]: !MAIN_DRAWER_OPEN,
            }),
          }}
          open={MAIN_DRAWER_OPEN}
        >
          <div className={classes.toolbar} style={{ minHeight: "48px" }}>
            <IconButton onClick={() => toggleDesigner(APP.MAIN_DRAWER_OPEN)}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem button key={"Back"} onClick={() => changePage(APP.MAIN_PAGE)}>
              <ListItemIcon><ReplyIcon /></ListItemIcon>
              <ListItemText primary={"Back To Main Page"} />
            </ListItem>
            <Divider />
            <ListItem button key={"Customer & Job"} onClick={() => toggleDesigner(APP.SHOW_CUSTOMER_JOB_PANEL)}>
              <ListItemIcon><AssignmentIndIcon  style={SHOW_CUSTOMER_JOB_PANEL?{color: "orange"}:{}}/></ListItemIcon>
              <ListItemText primary={"Customer & Job"} />
            </ListItem>
            <ListItem button key={"Notes"} onClick={() => toggleDesigner(APP.SHOW_NOTES_PANEL)}>
              <ListItemIcon><AssignmentIcon  style={SHOW_NOTES_PANEL?{color: "orange"}:{}}/></ListItemIcon>
              <ListItemText primary={"Notes"} />
            </ListItem>
            <Divider />
            <ListItem button key={"3D"} onClick={() => toggleDesigner(APP.THREED_MODE)}>
              <ListItemIcon><ThreeDRotationIcon style={THREED_MODE?{color: "orange"}:{}}/></ListItemIcon>
              <ListItemText primary={"3D Model"} />
            </ListItem>
          </List>
          {/* <Divider /> */}
        </Drawer>
    );
  }
}

export default connect(mapStateToProps, importedActions)(withStyles(styles)(DesignerMainDrawer));