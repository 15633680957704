import * as APP from '../redux_store/appState';
const _scene_data = window.scene_data;
const BABYLON = window.BABYLON;

export function toggleEaseInOutMeshes(meshes, visibility) {
    // animate
    setTimeout(() => {
        var mesh;
        if (!Array.isArray(meshes)) {
            meshes = [meshes];
        }
        if (meshes) {
            for (mesh of meshes) {

                if (_scene_data.mesh_picked === mesh) {
                    _scene_data.mesh_picked = null;
                    _scene_data.join_line.linkWithMesh(null);
                    _scene_data.join_line.isVisible = false;
                }

                if (visibility === true) {
                    if (!mesh.material) mesh.material = new BABYLON.StandardMaterial("myMaterial", _scene_data.scene);
                    mesh.material.alpha = 0.0;
                    mesh.isVisible = true;
                }

                mesh.isPickable = visibility;

                var ease = new BABYLON.CubicEase();
                ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
                if (!visibility) {
                    BABYLON.Animation.CreateAndStartAnimation('at5', mesh, 'material.alpha', 90, 25, 1.0, 0, 0, ease);
                    // BABYLON.Animation.CreateAndStartAnimation('at5', mesh, 'position.y', 90, 25, mesh.position.y-100, mesh.position.y, 0, ease);
                }
                else {
                    let target_alpha = 1.0;
                    if (mesh.material && mesh.material.metadata && mesh.material.metadata.last_alpha) target_alpha = mesh.material.metadata.last_alpha;
                    BABYLON.Animation.CreateAndStartAnimation('at5', mesh, 'material.alpha', 90, 25, 0, target_alpha, 0, ease);
                    BABYLON.Animation.CreateAndStartAnimation('at5', mesh, 'position.y', 90, 25, mesh.position.y + 200, mesh.position.y, 0, ease);
                }
            }
        }
    }, 0);
    setTimeout(() => {
        var mesh;
        if (meshes) {
            for (mesh of meshes) {
                if (mesh.material.metadata && mesh.material.metadata.last_alpha) {
                    mesh.material.alpha = mesh.material.metadata.last_alpha;
                }
                mesh.isVisible = visibility;
            }
        }
    }, 500);
};


export function changeMeshGroupColour(meshes, mesh_group, hex_colour) {
    if (meshes === null) meshes = _scene_data.scene.meshes;
    for (var mesh of _scene_data.scene.meshes) {
        if (mesh.id.includes(mesh_group)) {
            mesh.material.ambientColor = window.BABYLON.Color3.FromHexString(hex_colour);
            mesh.material.diffuseColor = window.BABYLON.Color3.FromHexString(hex_colour);
        };
    };
};

export function changeMeshGroupTexture(meshes, mesh_group, texture, bump_texture = null, colour = null) {
    if (meshes === null) meshes = _scene_data.scene.meshes;
    for (var mesh of meshes) {
        console.log(mesh.id);
        if (mesh.id.includes(mesh_group)) {
            console.log(mesh.material);
            mesh.material.ambientTexture = new BABYLON.Texture(texture, _scene_data.scene);
            if (colour) mesh.material.ambientColor = colour;
            mesh.material.diffuseTexture = new BABYLON.Texture(texture, _scene_data.scene);
            if (colour) mesh.material.diffuseColor = colour;
            if (bump_texture === null) {
                mesh.material.bumpTexture = null;
            }
            else {
                mesh.material.bumpTexture = new BABYLON.Texture(bump_texture, _scene_data.scene);
                mesh.material.useParallax = true;
                mesh.material.useParallaxOcclusion = true;
                mesh.material.parallaxScaleBias = 0.1;
                mesh.material.specularPower = 1000.0;
                mesh.material.specularColor = new BABYLON.Color3(0.5, 0.5, 0.5);
            }
        };
    };
};

export function updateMeshMode(meshes, mesh_mode, edge_mode) {
    setTimeout(() => {
        var mesh;
        if (meshes) {
            for (mesh of meshes) {
                // mesh.isVisible = this.state.display_3D_plan;

                if (!mesh.material.metadata) mesh.material.metadata = { last_alpha: mesh.material.alpha };

                if (mesh_mode && (mesh.id !== "skyBox" && mesh.id !== "ground")) {
                    mesh.enableEdgesRendering(0.999);

                    let ease = new BABYLON.CubicEase();
                    ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
                    BABYLON.Animation.CreateAndStartAnimation('at5', mesh, 'material.alpha', 90, 25, mesh.material.alpha, 0.2, 0, ease);

                    // mesh.material.alpha = 0.2;
                    mesh.edgesColor = new window.BABYLON.Color4(0.0, 0.0, 0.0, 1.0);
                    mesh.edgesWidth = 100;
                }
                else if (edge_mode) {
                    mesh.enableEdgesRendering(0.999);
                    mesh.edgesColor = new window.BABYLON.Color4(0.2, 0.2, 0.2, 0.5);
                    mesh.edgesWidth = 100;
                    if (mesh.material.metadata && mesh.material.metadata.last_alpha) {
                        let ease = new BABYLON.CubicEase();
                        ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
                        BABYLON.Animation.CreateAndStartAnimation('at5', mesh, 'material.alpha', 90, 25, mesh.material.alpha, mesh.material.metadata.last_alpha, 0, ease);
                        // mesh.material.alpha = mesh.material.metadata.last_alpha;
                    }
                }
                else {
                    mesh.disableEdgesRendering();
                    mesh.edgesColor = new window.BABYLON.Color4(0.2, 0.2, 0.2, 0.5);
                    mesh.edgesWidth = 100;
                    if (mesh.material.metadata && mesh.material.metadata.last_alpha) {
                        let ease = new BABYLON.CubicEase();
                        ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
                        BABYLON.Animation.CreateAndStartAnimation('at5', mesh, 'material.alpha', 90, 25, mesh.material.alpha, mesh.material.metadata.last_alpha, 0, ease);
                        // mesh.material.alpha = mesh.material.metadata.last_alpha;
                    }
                }
            }
        }
    }, 0);
}

export function easeCameraToMesh (target_mesh, zoom_in = false) {
    var ease = new BABYLON.CubicEase();
    ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
    var activCam = _scene_data.scene.activeCamera;
    BABYLON.Animation.CreateAndStartAnimation('at5', activCam, 'target', 90, 75, activCam.target, target_mesh.getBoundingInfo().boundingSphere.centerWorld, 0, ease);
    if (zoom_in) {
        // BABYLON.Animation.CreateAndStartAnimation('at6', activCam, 'radius', 90, 75, activCam.radius, target_mesh.getBoundingInfo().boundingSphere.radius, 0, ease);
        BABYLON.Animation.CreateAndStartAnimation('at6', activCam, 'radius', 90, 75, activCam.radius, target_mesh.getBoundingInfo().boundingSphere.radiusWorld*3, 0, ease);
    }
    else {
        BABYLON.Animation.CreateAndStartAnimation('at6', activCam, 'radius', 90, 75, activCam.radius, activCam.radius * (activCam.radius < 1500 ? 0.99 : 0.97), 0, ease);
    };
}

export function toggleEnvironment(mode, shadow_mode = false, adjust_position = false) {
    _scene_data.skybox.isVisible = false;
    _scene_data.skybox2.isVisible = false;
    _scene_data.background.isVisible = false;
    _scene_data.display_mode = mode;

    if (mode === "Plain" || mode === "Sunny Background" || mode === "Cloudy Background") {
        _scene_data.skybox.isVisible = true;
        _scene_data.skybox2.isVisible = true;
        if (mode === "Sunny Background" && _scene_data.skybox) {
            _scene_data.skybox.material.reflectionTexture = new BABYLON.CubeTexture("textures/tropical_sunny_day/TropicalSunnyDay", _scene_data.scene);
            _scene_data.skybox.material.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
            _scene_data.skybox2.material.reflectionTexture = new BABYLON.CubeTexture("textures/tropical_sunny_day/TropicalSunnyDay", _scene_data.scene);
            _scene_data.skybox2.material.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
        }
        else if (mode === "Cloudy Background") {
            _scene_data.skybox.material.reflectionTexture = new BABYLON.CubeTexture("textures/skybox", _scene_data.scene);
            _scene_data.skybox.material.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
            _scene_data.skybox2.material.reflectionTexture = new BABYLON.CubeTexture("textures/skybox", _scene_data.scene);
            _scene_data.skybox2.material.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
        }
        else if (mode === "Plain") {
            _scene_data.skybox.material.reflectionTexture = new BABYLON.CubeTexture("textures/plain/TropicalSunnyDay", _scene_data.scene);
            _scene_data.skybox.material.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
            _scene_data.skybox2.isVisible = false;
            _scene_data.skybox2.material.reflectionTexture = new BABYLON.CubeTexture("textures/plain/TropicalSunnyDay", _scene_data.scene);
            _scene_data.skybox2.material.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
        }
    }
    else if (mode === "Photo") {

        if (!_scene_data.photo_background) {
            var photo_background = new BABYLON.Layer('photo', 'https://sekisuihouse.imgix.net/getmedia/17e117c1-4e00-40e7-970f-0fef1b5d2c53/caladenia-1228-vivid-hero-1000x557.jpg.aspx?auto=format', _scene_data.scene, true);
            _scene_data.photo_background = photo_background;

            
            var photo_background = new BABYLON.Layer('photo', 'https://sekisuihouse.imgix.net/getmedia/17e117c1-4e00-40e7-970f-0fef1b5d2c53/caladenia-1228-vivid-hero-1000x557.jpg.aspx?auto=format', _scene_data.scene, true);
            _scene_data.photo_background = photo_background;
        }

        console.log(_scene_data);
        if (shadow_mode && _scene_data.canvas && !_scene_data.photo_background_shadow) {
            var svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="" viewbox="0 0 ' + _scene_data.canvas.width + ' ' + _scene_data.canvas.height + '" width="1710" height="966">\
        \
      <defs>\
      <filter id="f1" x="0" y="0">\
        <feGaussianBlur in="SourceGraphic" stdDeviation="15" />\
      </filter>\
    </defs>\
    <polygon points="970,550 1520,550 1520,800 1800,900 1800,1200 1350,1200 970,900" fill="#000" opacity="0.6" filter="url(#f1)"/></svg>';
            var url = URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }));
            var photo_background_shadow = new BABYLON.Layer('photo_svg', url, _scene_data.scene, true);
            photo_background_shadow.isVisible = true;
            _scene_data.photo_background_shadow = photo_background_shadow;
        }
        else if (_scene_data.photo_background_shadow && !shadow_mode) {
            _scene_data.photo_background_shadow.dispose();
            _scene_data.photo_background_shadow = null;
        }
        _scene_data.join_line.isVisible = false;
        if (_scene_data.highlighter && _scene_data.mesh_picked) _scene_data.highlighter.removeMesh(_scene_data.mesh_picked);

        if (adjust_position) {
            if (_scene_data.verandah_meshes) {
                var ease = new BABYLON.CubicEase();
                ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
                var activCam = _scene_data.scene.activeCamera;
                BABYLON.Animation.CreateAndStartAnimation('at5', activCam, 'target', 90, 25, activCam.target, _scene_data.verandah_meshes[0].getBoundingInfo().boundingSphere.centerWorld, 0, ease);
                BABYLON.Animation.CreateAndStartAnimation('at6', activCam, 'beta', 90, 25, activCam.beta, Math.PI * 0.53, 0, ease);
            }
        }
    }
    else {
        _scene_data.background.isVisible = true;
        if (_scene_data.photo_background_shadow) {
            _scene_data.photo_background_shadow.dispose();
            _scene_data.photo_background_shadow = null;
        }
        if (_scene_data.photo_background) {
            _scene_data.photo_background.dispose();
            _scene_data.photo_background = null;
        }
    }
}


export function updateVerandahModel() {
    var scene = _scene_data.scene;
    const SHOW_VERANDAH_MODEL = this.props.app.designer.designer3D[APP.SHOW_VERANDAH_MODEL];
    const VERANDAH_MESH_MODE = this.props.app.designer.designer3D[APP.VERANDAH_MESH_MODE];
    const VERANDAH_EDGE_LINE_MODE = this.props.app.designer.designer3D[APP.VERANDAH_EDGE_LINE_MODE];

    window.BABYLON.SceneLoader.ImportMesh(null, "http://localhost:62027/api/OBJ", "gazebo6.obj", scene, function (meshes) {

        try {

            let floor = meshes[0];
            let floor_width = floor.getBoundingInfo().boundingBox.extendSize.x * 0.1;
            let floor_depth = floor.getBoundingInfo().boundingBox.extendSize.y * 0.1;

            // floor.receiveShadows = true;
            // floor.isPickable = false;

            for (var mesh of meshes) {
                if (mesh.id.includes("_mm1")
                    || mesh.id.includes("_mm2")
                    || mesh.id.includes("_mm3")
                    || mesh.id.includes("group_1")
                    || mesh.id.includes("group_6")) mesh.dispose();
            };
            meshes = meshes.filter(item => (!item.id.includes("_mm1")
                && !item.id.includes("_mm2")
                && !item.id.includes("_mm3")
                && !item.id.includes("group_1")
                && !item.id.includes("group_6")));

            _scene_data.gazebo_meshes = meshes;

            for (var mesh of meshes) {

                // if (mesh.id.includes("_mm1")) {mesh.dispose()};
                // if (mesh.id.includes("_mm3")) mesh.isVisible = false;
                // if (mesh.id.includes("_mm2")) mesh.isVisible = false;
                // if (mesh.id.includes("group_1")) mesh.isVisible = false;

                mesh.rotate(window.BABYLON.Axis.X, -Math.PI / 2, window.BABYLON.Space.WORLD)
                    .scaling = new window.BABYLON.Vector3(-0.1, 0.1, 0.1);
                mesh.position = new window.BABYLON.Vector3(floor_width, 0, floor_depth);
                mesh.backFaceCulling = false;
                mesh.receiveShadows = true;
                mesh.material = mesh.material.clone(mesh.id);
                mesh.material.backFaceCulling = false;


                if (mesh.id.includes("group_4")) {
                    mesh.material.reflectionTexture = new window.BABYLON.CubeTexture("textures/skybox", scene);
                    mesh.material.reflectionTexture.coordinatesMode = window.BABYLON.Texture.CUBIC_MODE;
                    mesh.material.reflectionTexture.level = 0.06;
                    mesh.material.bumpTexture = new window.BABYLON.Texture("models/ZMM_cdeck_normal.png", scene);
                    mesh.material.useParallax = true;
                    mesh.material.useParallaxOcclusion = true;
                    mesh.material.parallaxScaleBias = 1;
                    mesh.name = "RoofSheets";
                }

                if (!mesh.material.metadata) mesh.material.metadata = { last_alpha: mesh.material.alpha };

                // mesh.enableEdgesRendering(0.999);
                // mesh.edgesColor = new window.BABYLON.Color4(0.2, 0.2, 0.2, 0.4);
                // mesh.edgesWidth = 100;
                mesh.actionManager = new window.BABYLON.ActionManager(scene);
                _scene_data.makeOverOut(mesh);

                _scene_data.shadowGenerator.addShadowCaster(mesh);
                // shadowGenerator_torch.addShadowCaster(mesh);
            }

            // makeOverOut(floor);
            // floor.material.backFaceCulling = true;
            // floor.material.alpha = 0.9;
            // floor.disableEdgesRendering();
            // floor.isVisible = false;
            // floor.id = "Floor";
            for (mesh of _scene_data.gazebo_meshes) mesh.isVisible = SHOW_VERANDAH_MODEL;
            // do something with the meshes and skeletons
            // particleSystems are always null for glTF assets
            
            updateMeshMode(_scene_data.gazebo_meshes, VERANDAH_MESH_MODE, VERANDAH_EDGE_LINE_MODE);

    
        }
        catch (err) {
            console.log(err.message);
            console.log(err);
        };


    });
};

function createTreeQQ() {
    var scene = _scene_data.scene;
    ///TREES
    //leaf material
    var green = new window.BABYLON.StandardMaterial("green", scene);
    green.diffuseColor = new window.BABYLON.Color3(0.5, 0.7, 0.3);

    //trunk and branch material
    var bark = new window.BABYLON.StandardMaterial("bark", scene);
    // bark.emissiveTexture = new window.BABYLON.Texture("https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Bark_texture_wood.jpg/800px-Bark_texture_wood.jpg", scene);
    bark.ambientTexture = new window.BABYLON.Texture("textures/treebark.png", scene);
    // bark.diffuseTexture = new window.BABYLON.Texture("https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Bark_texture_wood.jpg/800px-Bark_texture_wood.jpg", scene);
    bark.ambientTexture.uScale = 5.0;//Repeat 5 times on the Vertical Axes
    bark.ambientTexture.vScale = 3.0;//Repeat 5 times on the Horizontal Axes	
    bark.specularColor = new window.BABYLON.Color3(0, 0, 0);

    //Tree parameters			
    var trunk_height = 20;
    var trunk_taper = 0.6;
    var trunk_slices = 5;
    var boughs = 2; // 1 or 2
    var forks = 4;
    var fork_angle = Math.PI / 4;
    var fork_ratio = 2 / (1 + Math.sqrt(5)); //PHI the golden ratio
    var branch_angle = Math.PI / 3;
    var bow_freq = 2;
    var bow_height = 3.5;
    var branches = 10;
    var leaves_on_branch = 5;
    var leaf_wh_ratio = 0.5;

    return window.createTree(trunk_height, trunk_taper, trunk_slices, bark, boughs, forks, fork_angle, fork_ratio, branches, branch_angle, bow_freq, bow_height, leaves_on_branch, leaf_wh_ratio, green, scene);
}

export function addObject(object_name, id) {

    var scene = _scene_data.scene;
    var added_objects = _scene_data.added_objects;
    if (object_name === "Lounge") {
        window.BABYLON.SceneLoader.ImportMesh(null, "models/low_poly_couch/", "scene.gltf", scene, function (meshes) {

            var lounge = new window.BABYLON.Mesh(id, scene);

            for (var mesh of meshes) {
                // new_object_mesh.receiveShadows = true;
                mesh.parent = lounge;
                // new_object_mesh.id = id;
            };
            lounge.scaling = new window.BABYLON.Vector3(40, 40, 40);
            lounge.position = new window.BABYLON.Vector3(20, 0, 640);
            lounge.rotate(window.BABYLON.Axis.Z, -(Math.PI / 2), window.BABYLON.Space.WORLD);
            lounge.rotate(window.BABYLON.Axis.Y, -(Math.PI / 2), window.BABYLON.Space.WORLD);
            // shadowGenerator.addShadowCaster(bbq);
            var pointerDragBehavior = new window.BABYLON.PointerDragBehavior({ dragPlaneNormal: new window.BABYLON.Vector3(0, 1, 0) });
            lounge.addBehavior(pointerDragBehavior);
            added_objects.push(lounge);
        });
    }
    else if (object_name === "Webber") {
        window.BABYLON.SceneLoader.ImportMesh(null, "models/", "webber.obj", scene, function (meshes) {

            var bbq = new window.BABYLON.Mesh(id, scene);

            for (var mesh of meshes) {
                // new_object_mesh.receiveShadows = true;
                if (mesh.id === "black_gloss_paint" || mesh.id === "black_matte_plastic") {
                    var gloss_black = new window.BABYLON.StandardMaterial("myMaterial", scene);
                    gloss_black.ambientColor = window.BABYLON.Color3.Black;
                    mesh.material = gloss_black;
                }
                mesh.parent = bbq;
                // new_object_mesh.id = id;
            };
            bbq.scaling = new window.BABYLON.Vector3(3, 3, 3);
            bbq.position = new window.BABYLON.Vector3(20, 0, 640);
            toggleEaseInOutMeshes(bbq, true);
            _scene_data.shadowGenerator.addShadowCaster(bbq);
            var pointerDragBehavior = new window.BABYLON.PointerDragBehavior({ dragPlaneNormal: new window.BABYLON.Vector3(0, 1, 0) });
            bbq.addBehavior(pointerDragBehavior);
            added_objects.push(bbq);
        });
    }
    else if (object_name === "Tree") {
        let new_object_mesh = createTreeQQ();
        toggleEaseInOutMeshes(new_object_mesh, true);
        new_object_mesh.scaling = new window.BABYLON.Vector3(10, 10, 10);
        new_object_mesh.position = new window.BABYLON.Vector3(20, 0, 640);
        new_object_mesh.receiveShadows = true;
        _scene_data.shadowGenerator.addShadowCaster(new_object_mesh);
        var pointerDragBehavior = new window.BABYLON.PointerDragBehavior({ dragPlaneNormal: new window.BABYLON.Vector3(0, 1, 0) });
        new_object_mesh.addBehavior(pointerDragBehavior);
        new_object_mesh.id = id;
        added_objects.push(new_object_mesh);
    }
    else if (object_name === "Hills Hoist") {
        window.BABYLON.SceneLoader.ImportMesh(null, "models/", "Hills Hoist Clothes Line1.obj", scene, function (meshes) {
            let plane_mesh = meshes.find(x => x.id.includes("Plane"));
            if (plane_mesh) {
                meshes = meshes.filter(item => item !== plane_mesh);
                plane_mesh.dispose();
            }
            // var hills_hoist = new window.BABYLON.Mesh(id,scene);
            // for (mesh of meshes) {
            //   mesh.parent = hills_hoist;
            //   mesh.receiveShadows = true;
            // };
            let hills_hoist = window.BABYLON.Mesh.MergeMeshes(meshes);


            hills_hoist.scaling = new window.BABYLON.Vector3(25, 25, 25);
            hills_hoist.position = new window.BABYLON.Vector3(20, 0, 640);
            toggleEaseInOutMeshes(hills_hoist, true);
            hills_hoist.receiveShadows = true;
            _scene_data.shadowGenerator.addShadowCaster(hills_hoist);
            var pointerDragBehavior = new window.BABYLON.PointerDragBehavior({ dragPlaneNormal: new window.BABYLON.Vector3(0, 1, 0) });
            hills_hoist.addBehavior(pointerDragBehavior);
            hills_hoist.id = id;
            added_objects.push(hills_hoist);
        });
    };
};