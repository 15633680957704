import React from 'react';
import ReactDOM from "react-dom";

import * as PaperJS from './LayoutDesigner_PaperFunctions';
import _debounce from 'lodash/debounce';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MapIcon from '@material-ui/icons/Map';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Slider from '@material-ui/lab/Slider';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import GoogleMapReact from 'google-map-react';


import { convertJobStateToIni } from '../components/Common';

// Redux Actions
import { connect } from 'react-redux';
import { changePage, toggleDesigner, updateDesigner, update2DSVGs } from '../redux_store/appState';
import { BASIC_SPECS_SECTIONS } from '../redux_store/jobState_Schemas';
import { updateAreaSpecification } from '../redux_store/jobState';
import { updateCustomer } from '../redux_store/jobState';
// import { CUSTOMER } from '../redux_store/jobState_Schemas';
import * as APP from '../redux_store/appState';
// import * as JOB from '../redux_store/jobState';
const importedActions = { changePage, toggleDesigner, updateCustomer, update2DSVGs, updateAreaSpecification };
const mapStateToProps = state => ({
  app: state.app,
  job: state.job
});

const toolDrawerWidth = 180;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: toolDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  toolDrawerOpen: {
    backgroundColor: "rgba(242,245,251, 0.5)",
    width: toolDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolDrawerClose: {
    backgroundColor: "rgba(242,245,251, 0.5)",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: "48px",
    ...theme.mixins.toolbar,
  },
  toolMenuText: {
    textAlign: "right",
  },
  toolListItem: {
    paddingRight: "0px",
    minWidth: toolDrawerWidth,
  },
  mapCross: {
    position: 'absolute',
    zIndex: '100', 
    color: 'white',
    marginLeft: '38%',
    marginTop: '70px',
    fontSize: 'xx-large',
    userSelect: 'none',
    pointerEvents: 'none',
  }
});

class LayoutDesignerToolDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: null,
    };
    this.importGoogleMap = React.createRef();
  }

  loadGoogleMap = () => {
    const LATLNG = this.props.job.site_parameters["LatLng"];
    const ADDRESS = this.props.job.site_parameters["Address"];
    let zoom = this.map.getZoom() + 1;
    PaperJS.loadGoogleMap(LATLNG ? (typeof LATLNG.lat === "function" ? LATLNG.lat() + "," + LATLNG.lng() : LATLNG.lat + "," + LATLNG.lng) : ADDRESS, zoom);
    this.setState({ showPopover: null });
  }



  updateJOBSVG = () => {
    fetch('/models/MCQDSC0573.vqu')
      .then((r) => r.text())
      .then(text => {
        this.props.update2DSVGs(text);
      })
  }

  updateLengthAndSVG = (new_length = null) => {
    console.log("updating svgs");
    if (new_length) {
      console.log("new length: " + new_length);
      let rounded_val = Math.round(new_length / 50) * 50;
      // this.props.updateAreaSpecification(0, BASIC_SPECS_SECTIONS.SHAPE, "Length", rounded_val);
      // this.props.dispatch(updateAreaSpecification(0, BASIC_SPECS_SECTIONS.SHAPE, "Length", rounded_val)).then(()=>{
      this.props.updateAreaSpecification(0, BASIC_SPECS_SECTIONS.SHAPE, "Length", rounded_val).then(()=>{
        console.log('updated');
        // console.log(this.props.job);
        this.props.update2DSVGs(convertJobStateToIni(this.props.job));
     });
    }
    else {
      this.props.update2DSVGs(convertJobStateToIni(this.props.job));
    }
  }
  
  updateSVG = _debounce(this.updateSVG_debounced, 500, { 'maxWait': 2000 });
  updateSVG_debounced() {
    // console.log("updating svgs");
    this.props.update2DSVGs(convertJobStateToIni(this.props.job));
  }

  handleApiLoaded = (map, maps) => {
    // use map and maps objects
    map.setMapTypeId('hybrid');
    map.rotateControl = false;
    map.setTilt(0);


    this.map = map;
    let updateCustomer = this.props.updateCustomer;

    map.addListener('center_changed', function () {
      console.log(map.getCenter());
      updateCustomer("LatLng", { lat: map.getCenter().lat(), lng: map.getCenter().lng() });
    });
  };

  importGoogleMapClick = () => {
    this.setState({ showPopover: ReactDOM.findDOMNode(this.importGoogleMap.current) });
  }

  render() {
    const { classes, toggleDesigner } = this.props;
    const { customer, site_parameters } = this.props.job;
    const latLng = this.props.job.customer["LatLng"];
    const { showPopover } = this.state;
    const TOOL_DRAWER_OPEN = this.props.app.designer[APP.TOOL_DRAWER_OPEN];

    console.log(showPopover);

    return (
      <Drawer
        variant="permanent"
        anchor="right"
        className={classNames(classes.drawer, {
          [classes.toolDrawerOpen]: TOOL_DRAWER_OPEN,
          [classes.toolDrawerClose]: !TOOL_DRAWER_OPEN,
        })}
        classes={{
          paper: classNames({
            [classes.toolDrawerOpen]: TOOL_DRAWER_OPEN,
            [classes.toolDrawerClose]: !TOOL_DRAWER_OPEN,
          }),
        }}
        open={TOOL_DRAWER_OPEN}
      >
        <div className={classes.toolbar} style={{ minHeight: "48px" }} />
        <span style={{ width: "100%", textAlign: "right" }}><IconButton style={{ width: "48px", marginRight: "5px" }} onClick={() => toggleDesigner(APP.TOOL_DRAWER_OPEN)}>{TOOL_DRAWER_OPEN ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton></span>
        <List style={{ paddingTop: "0px", marginLeft: "-160px" }}>
          <ListItem button className={classes.toolListItem} key={"Import Map"} onClick={() => PaperJS.loadMap()}>
            <ListItemText primary={"Import Map"} className={classes.toolMenuText} />
            <ListItemIcon><MapIcon /></ListItemIcon>
          </ListItem>
          <ListItem ref={this.importGoogleMap} button className={classes.toolListItem} key={"Google Map"} onClick={() => this.importGoogleMapClick()}>
            <ListItemText primary={"Google Map"} className={classes.toolMenuText} />
            <ListItemIcon><MapIcon /></ListItemIcon>
          </ListItem>

          {/* <ListItem button className={classes.toolListItem} key={"Draw Eave Line"}>
              <ListItemText primary={"Draw Eave Line"} className={classes.toolMenuText} />
              <ListItemIcon><BorderColorIcon /></ListItemIcon>
            </ListItem> */}
          <ListItem button className={classes.toolListItem} key={"Generate Roof"} onClick={() => PaperJS.generateRoof()}>
            <ListItemText primary={"Generate Roof"} className={classes.toolMenuText} />
            <ListItemIcon><HomeIcon /></ListItemIcon>
          </ListItem>
        </List>
        <Divider />
        <List style={{ paddingTop: "0px", marginLeft: "-160px" }}>
          <ListItem button className={classes.toolListItem} key={"Roof Test"} onClick={() => PaperJS.loadToggleSVG("roof_load_test")}>
            <ListItemText primary={"Draw Attachment"} className={classes.toolMenuText} />
            <ListItemIcon><HomeIcon /></ListItemIcon>
          </ListItem>
          <ListItem button className={classes.toolListItem} key={"Roof Load Result"} onClick={() => PaperJS.loadToggleSVG("roof_load_test_results")}>
            <ListItemText primary={"Add Verandah Area"} className={classes.toolMenuText} />
            <ListItemIcon><HomeIcon /></ListItemIcon>
          </ListItem>
        </List>
        <Divider />
        <List style={{ paddingTop: "0px", marginLeft: "-160px" }}>
          <ListItem button className={classes.toolListItem} key={"Roof Test"} onClick={() => PaperJS.loadToggleSVG("roof_load_test")}>
            <ListItemText primary={"Roof Load Test"} className={classes.toolMenuText} />
            <ListItemIcon><HomeIcon /></ListItemIcon>
          </ListItem>
          <ListItem button className={classes.toolListItem} key={"Roof Load Result"} onClick={() => PaperJS.loadToggleSVG("roof_load_test_results")}>
            <ListItemText primary={"...Roof Test"} className={classes.toolMenuText} />
            <ListItemIcon><HomeIcon /></ListItemIcon>
          </ListItem>
        </List>
        <Divider />
        <List style={{ paddingTop: "0px", marginLeft: "-160px" }}>
          <ListItem button className={classes.toolListItem} key={"Check INI Data"} onClick={() => convertJobStateToIni(this.props.job)}>
            <ListItemText primary={"Get INI Data..."} className={classes.toolMenuText} />
            <ListItemIcon><HomeIcon /></ListItemIcon>
          </ListItem>
          <ListItem button className={classes.toolListItem} key={"Update SVG"} onClick={() => this.updateJOBSVG()}>
            <ListItemText primary={"Update SVG..."} className={classes.toolMenuText} />
            <ListItemIcon><HomeIcon /></ListItemIcon>
          </ListItem>
        </List>
        <input type="Text"
          value={this.props.job.area_specifications[0].SHAPE.Length}
          onKeyPress={(e, value) => (e.which === 13) ? this.updateLengthAndSVG(value) : null}
          onBlur={(e, value) => this.updateLengthAndSVG(value)}
          onChange={(e) => this.props.updateAreaSpecification(0, BASIC_SPECS_SECTIONS.SHAPE, "Length", e.target.value)}
        >
        </input>

        <Slider
          classes={{ container: classes.slider }}
          value={this.props.job.area_specifications[0].SHAPE.Length}
          min={3000}
          max={6000}
          step={1}
          // onDragEnd={(e, value) => {console.log(e.currentTarget); this.updateLengthAndSVG(value)}}
          // onDragEnd={() => this.updateSVG()}
          onChange={(e, value) => { let rounded_val = Math.round(value / 50) * 50; this.props.updateAreaSpecification(0, BASIC_SPECS_SECTIONS.SHAPE, "Length", rounded_val);this.updateSVG();}}
          // onChange={(e, value) => this.updateLengthAndSVG(value)}
        />


        <Popover
          open={Boolean(showPopover)}
          anchorEl={showPopover}
          onClose={() => this.setState({ showPopover: null })}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          {/* <Paper className={classes.paper}> */}
          <DialogTitle>{"Import Google Map"}</DialogTitle>
          <DialogContent>
            <DialogContentText>Drag image to change map location</DialogContentText>
            <div style={{ height: '200px', width: '100%', marginTop: "10px" }}>
              <div className={classes.mapCross}>+</div>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyDusbQSuHd8yi5wBk8x4skpqjH4ttqBc2w" }}
                defaultCenter={site_parameters["LatLng"]}
                defaultZoom={20}
                mapTypeId={'satellite'}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
              >
                {/* <AnyReactComponent
      lat={latLng.lat}
      lng={latLng.lng}
      text="My Marker"
    /> */}
              </GoogleMapReact>

              <span style={{ fontSize: "10px" }}>LAT: {latLng ? (typeof latLng.lat === "function" ? latLng.lat().toFixed(5) : latLng.lat) : "undefined"}
                &nbsp;LNG: {latLng ? (typeof latLng.lng === "function" ? latLng.lng().toFixed(5) : latLng.lng) : "undefined"}</span>
            </div>
            {/* </DialogContentText> */}
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={this.handleClickButton} color="primary">
                  Disagree
                </Button> */}
            <Button onClick={() => this.loadGoogleMap()} color="primary" >
              Import
                </Button>
          </DialogActions>
          {/* </Paper> */}
        </Popover>
      </Drawer>
    );
  }
}

export default connect(mapStateToProps, importedActions)(withStyles(styles)(LayoutDesignerToolDrawer));