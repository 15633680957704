import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { GetHTMLColour, Colours, insertArrayItem, addArrayItem, updateObjectInArray } from '../components/Common';

// BabylonJS
import * as BabylonFunctions from './BabylonFunctions';

// Redux Actions
import { connect } from 'react-redux';
import { changePage, toggleDesigner, updateDesigner, toggle3DDesigner, update3DDesigner } from '../redux_store/appState';
import * as APP from '../redux_store/appState';
import BabylonDesignerToolDrawerMain from './BabylonDesignerToolDrawerMain';
const importedActions = { changePage, toggleDesigner, update3DDesigner, toggle3DDesigner };
const mapStateToProps = state => ({
    app: state.app,
    job: state.job
});

// import RoofsheetSideBar from './RoofsheetSideBar';
// import ShapeDesignerBar from './ShapeDesignerBar';
// import GutterAndDownpipeSideBar from './GutterAndDownpipeSideBar';
// import PostsSideBar from './PostsSideBar';
// import BeamSideBar from './BeamSideBar';

const _ = window;
const _scene_data = window.scene_data;
const BABYLON = window.BABYLON;


const styles = {

    qq3DCanvasContainer: {
        zIndex: 0,
        display: "block",
        position: "absolute",
        top: "0px",
        right: "0px",
        backgroundColor: "rgba(255, 255, 255, 255)",
        width: "100%",
        height: "100%",
        color: "#000",
        transition: "visibility 0.3s, opacity 0.3s linear",
    },

    //     qq2dCanvas: {
    //         width: "100vw",
    //         height: "100vh",
    //         backgroundSize: "100px 100px, 100px 100px, 20px 20px, 20px 20px",
    //         backgroundPosition: "-2px -2px, -2px -2px, -1px -1px, -1px -1px",
    //         backgroundImage: "-webkit-linear-gradient(white 2px, transparent 2px),\
    // -webkit-linear-gradient(0, white 2px, transparent 2px),\
    // -webkit-linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),\
    // -webkit-linear-gradient(0, rgba(255,255,255,.3) 1px, transparent 1px),\
    // -moz-linear-gradient(white 2px, transparent 2px),\
    // -moz-linear-gradient(0, white 2px, transparent 2px),\
    // -moz-linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),\
    // -moz-linear-gradient(0, rgba(255,255,255,.3) 1px, transparent 1px),\
    // linear-gradient(white 2px, transparent 2px),\
    // linear-gradient(90deg, white 2px, transparent 2px),\
    // linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),\
    // linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px)",
    //     }
};

class QQ3DWebGL extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_item: "",
            canvas_objects: [
            ],
            display_2D_plan: false,
            display_3D_plan: false,
            display_house: true,
            display_edge_rendering: false,
            display_edge_rendering_only: false,
        };

        // this.toggleOpacity = this.toggleOpacity.bind(this);

    }

    componentDidMount() {
        this.setFunctionsToGlobal();
        this.loadBabylon();
        this.load_Plan();
        this.load_House();
        this.load_2DLayout();
        this.load_3DPlan();
        // this.load_Gazebo();

        const BACKGROUND_MODE = this.props.app.designer.designer3D[APP.BACKGROUND_MODE];
        const BACKGROUND_SHADOW_MODE = this.props.app.designer.designer3D[APP.BACKGROUND_SHADOW_MODE];
        BabylonFunctions.toggleEnvironment(BACKGROUND_MODE, BACKGROUND_SHADOW_MODE);

        // this.load_drawing();
    }

    handleStateChange = (new_state) => {
        console.log(new_state);
        if (new_state && typeof new_state === "object") this.setState(new_state);
    };

    render() {
        const { classes, toggleDesigner, update3DDesigner, toggle3DDesigner } = this.props;
        _scene_data.update3DDesigner = update3DDesigner;

        // window.dispatchEvent(new Event('resize'));

        // var mesh;
        // if (_scene_data.twod_plan) _scene_data.twod_plan.isVisible = this.state.display_2D_plan;
        // if (_scene_data.house_meshes) for (mesh of _scene_data.house_meshes) mesh.isVisible = this.state.display_house;
        // if (_scene_data.verandah_meshes) {
        //     for (mesh of _scene_data.verandah_meshes) {
        //         mesh.isVisible = this.state.display_3D_plan;

        //         if (!mesh.material.metadata) mesh.material.metadata = { last_alpha: mesh.material.alpha };

        //         if (this.state.display_edge_rendering_only && (mesh.id !== "skyBox" && mesh.id !== "ground")) {
        //             mesh.enableEdgesRendering(0.999);
        //             mesh.material.alpha = 0.2;
        //             mesh.edgesColor = new window.BABYLON.Color4(0.0, 0.0, 0.0, 1.0);
        //             mesh.edgesWidth = 100;
        //         }
        //         else if (this.state.display_edge_rendering) {
        //             mesh.enableEdgesRendering(0.999);
        //             mesh.edgesColor = new window.BABYLON.Color4(0.2, 0.2, 0.2, 0.5);
        //             mesh.edgesWidth = 100;
        //             if (mesh.material.metadata && mesh.material.metadata.last_alpha) {
        //                 mesh.material.alpha = mesh.material.metadata.last_alpha;
        //             }
        //         }
        //         else {
        //             mesh.disableEdgesRendering();
        //             mesh.edgesColor = new window.BABYLON.Color4(0.2, 0.2, 0.2, 0.5);
        //             mesh.edgesWidth = 100;
        //             if (mesh.material.metadata && mesh.material.metadata.last_alpha) {
        //                 mesh.material.alpha = mesh.material.metadata.last_alpha;
        //             }
        //         }
        //     };
        // };

        return (
            <div className={classes.qq3DCanvasContainer}>
                <canvas id="qqWebGL" className={"canvas"}></canvas>
            </div>
        );
    };

    setFunctionsToGlobal() {
        _scene_data.makeOverOut = this.makeOverOut;
        _scene_data.toggleOpacity = this.toggleOpacity;
        _scene_data.changeMeshGroupColour = this.changeMeshGroupColour;
        _scene_data.handleStateChange = this.handleStateChange;
    }


    makeOverOut(mesh) {
        mesh.actionManager.registerAction(new BABYLON.SetValueAction(BABYLON.ActionManager.OnPointerOutTrigger, mesh.material, "emissiveColor", mesh.material.emissiveColor));
        // mesh.actionManager.registerAction(new BABYLON.SetValueAction(BABYLON.ActionManager.OnPointerOverTrigger, mesh.material, "emissiveColor", new BABYLON.Color3(mesh.material.ambientColor.r * 1.0, mesh.material.ambientColor.g * 1.0, mesh.material.ambientColor.b * 1.0)));
        mesh.actionManager.registerAction(new BABYLON.SetValueAction(BABYLON.ActionManager.OnPointerOverTrigger, mesh.material, "emissiveColor", new BABYLON.Color3(0.25, 0.25, 0.35)));
        mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
            function () {
                // console.log(_scene_data.display_mode);
                if (_scene_data && _scene_data.display_mode && _scene_data.display_mode === "Photo") {
                    return;
                } 
                console.log(mesh);
                //   SetVal("selected_item", mesh.id);
                // _scene_data.handleStateChange({ selected_item: mesh.id });
                _scene_data.update3DDesigner(APP.BABYLON_SELECTED_ITEM, mesh.id);

                //update tools in react
                if (mesh.id === "group_4 group_all") _scene_data.update3DDesigner(APP.DRAWER_TOOLSET_EXPANDED, "roofsheet_tool");
                if (mesh.id === "House Roof") _scene_data.update3DDesigner(APP.DRAWER_TOOLSET_EXPANDED, "attachment_tool");
                if (mesh.id === "group_5 group_all") _scene_data.update3DDesigner(APP.DRAWER_TOOLSET_EXPANDED, "gutters_downpipes_tool");
                if (mesh.id.includes("beam")) _scene_data.update3DDesigner(APP.DRAWER_TOOLSET_EXPANDED, "beams_and_posts_tool");
                if (mesh.id === "group_3 group_all") _scene_data.update3DDesigner(APP.DRAWER_TOOLSET_EXPANDED, "beams_and_posts_tool");

                if (_scene_data.mesh_picked) {
                    if (_scene_data.highlighter) _scene_data.highlighter.removeMesh(_scene_data.mesh_picked);
                    if (_scene_data.mesh_picked.material.alpha < 0.5) {
                        if (_scene_data.mesh_picked.metadata) {
                            if (_scene_data.mesh_picked.metadata.last_edgeRendererEnabled) _scene_data.mesh_picked.enableEdgesRendering(0.999);
                            else _scene_data.mesh_picked.disableEdgesRendering();
                            if (_scene_data.mesh_picked.metadata.last_edgesColor) _scene_data.mesh_picked.edgesColor = _scene_data.mesh_picked.metadata.last_edgesColor;
                            if (_scene_data.mesh_picked.metadata.last_edgesWidth) _scene_data.mesh_picked.edgesWidth = _scene_data.mesh_picked.metadata.last_edgesWidth;
                        }
                        else {
                            _scene_data.mesh_picked.disableEdgesRendering();
                        }

                    }
                    // if (_scene_data.mesh_picked.isVisible) _scene_data.mesh_picked.isPickable = true;
                    // else  _scene_data.mesh_picked.isPickable = false;

                    _scene_data.mesh_picked.isPickable = true;
                }

                if (mesh.material.alpha < 0.5) {
                    if (!mesh.metadata) mesh.metadata = {};
                    mesh.metadata.last_edgeRendererEnabled = mesh.edgesRenderer ? true : false;
                    mesh.metadata.last_edgesColor = mesh.edgesColor;
                    mesh.metadata.last_edgesWidth = mesh.edgesWidth;
                    mesh.enableEdgesRendering(0.999);
                    mesh.edgesColor = new BABYLON.Color4(0, 1, 0, 1);
                    mesh.edgesWidth = 400;
                }
                // console.log("highlighter");
                // console.log(_scene_data.highlighter);
                if (_scene_data.highlighter) _scene_data.highlighter.addMesh(mesh, BABYLON.Color3.Green());
                mesh.isPickable = false;
                _scene_data.join_line.linkWithMesh(mesh);
                _scene_data.join_line.isVisible = true;
                // console.log(_scene_data.scene);
                // console.log(_scene_data.scene.activeCamera);
                // console.log(_scene_data.scene.activeCameras);

                var ease = new BABYLON.CubicEase();
                ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
                var activCam = _scene_data.scene.activeCamera;
                BABYLON.Animation.CreateAndStartAnimation('at5', activCam, 'target', 90, 75, activCam.target, mesh.getBoundingInfo().boundingSphere.centerWorld, 0, ease);
                BABYLON.Animation.CreateAndStartAnimation('at6', activCam, 'radius', 90, 75, activCam.radius, activCam.radius * (activCam.radius < 1500 ? 0.99 : 0.97), 0, ease);

                // this.flyToPosition(_scene_data.scene.activeCamera, _scene_data.scene.activeCamera.position, mesh.pivotPoint);
                // _scene_data.scene.activeCamera.setTarget(mesh);
                // label.isVisible = false;
                // text1.text = mesh.id;
                // if (mesh.id.includes("group_4")) text1.text = "Roof Sheet selected\nDouble Sided CDeck 310 - Classic Cream";
                // if (mesh.id.includes("_mm1")) text1.text = "Footings selected";
                // if (mesh.id.includes("group_5")) text1.text = "Gutters and Downpipes selected";
                // if (mesh.id.includes("group_3")) text1.text = "Posts selected";
                // if (mesh.id.includes("group_2")) text1.text = "Beams selected";
                // if (mesh.id.includes("group_1")) text1.text = "Attachment selected";


                if (mesh.id.includes("Fascia3Example")) {
                    _scene_data.gizmo_projection.attachedMesh = mesh;
                    _scene_data.gizmo_length.attachedMesh = mesh;
                    _scene_data.gizmo_shift_left.attachedMesh = mesh;
                    _scene_data.gizmo_pitch.attachedMesh = mesh;
                }
                else {
                    _scene_data.gizmo_projection.attachedMesh = null;
                    _scene_data.gizmo_length.attachedMesh = null;
                    _scene_data.gizmo_shift_left.attachedMesh = null;
                    _scene_data.gizmo_pitch.attachedMesh = null;
                }
                _scene_data.mesh_picked = mesh;

            })
        );
    };


    changeMeshGroupColour(mesh_group, hex_colour) {
        for (var mesh of _scene_data.scene.meshes) {
            if (mesh.id.includes(mesh_group)) {
                mesh.material.ambientColor = window.BABYLON.Color3.FromHexString(hex_colour);
                mesh.material.diffuseColor = window.BABYLON.Color3.FromHexString(hex_colour);
            };
        };
    };
    // ALL THE 3D STUFF

    loadBabylon() {
        var canvas = document.getElementById("qqWebGL"); // Get the canvas element 
        var engine = new BABYLON.Engine(canvas, true, { stencil: true }); // Generate the BABYLON 3D engine
        var scene = null;
        var camera = null;

        //create ref via global variable
        _scene_data.canvas = canvas;
        _scene_data.scene = null;
        _scene_data.added_objects = [];

        var loadQQWebGL = () => {

            /******* Add the create scene function ******/
            var createScene = function () {

                // Create the scene space
                var scene = new BABYLON.Scene(engine);

                var skybox = BABYLON.MeshBuilder.CreateBox("skyBox", { size: 20000.0 }, scene);
                var skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
                skyboxMaterial.backFaceCulling = false;
                skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("textures/plain/TropicalSunnyDay", scene);
                skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
                skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
                skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
                skybox.material = skyboxMaterial;



                var skybox2 = BABYLON.MeshBuilder.CreateBox("skyBox2", { size: 12000.0 }, scene);
                var skyboxMaterial2 = new BABYLON.StandardMaterial("skyBox2", scene);
                skyboxMaterial2.backFaceCulling = false;
                // skyboxMaterial2.reflectionTexture = new BABYLON.CubeTexture("textures/skybox", scene);
                skyboxMaterial2.reflectionTexture = new BABYLON.CubeTexture("textures/plain/TropicalSunnyDay", scene);
                skyboxMaterial2.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
                skyboxMaterial2.diffuseColor = new BABYLON.Color3(0, 0, 0);
                skyboxMaterial2.specularColor = new BABYLON.Color3(0, 0, 0);
                skyboxMaterial2.alpha = 0.5;
                skybox2.rotation.y += Math.PI;
                skybox2.material = skyboxMaterial2;

                var run_sky_backwards = false;
                scene.registerBeforeRender(function () {
                    if (run_sky_backwards) skybox2.rotation.y -= 0.0003;
                    else skybox2.rotation.y += 0.0003;

                    // if (skybox2.rotation.y > 3.5) run_sky_backwards = true;
                    // if (skybox2.rotation.y < 2.7) run_sky_backwards = false;
                    // console.log(skybox2.rotation.y);
                });

                skybox.isVisible = true;
                _scene_data.skybox = skybox;
                skybox2.isVisible = true;
                _scene_data.skybox2 = skybox2;

                var background = new BABYLON.Layer('background', 'https://i.imgur.com/HaZZSFn.png', scene, true);
                background.isBackground = true;
                background.isVisible = true;
                _scene_data.background = background;

                // Add a camera to the scene and attach it to the canvas
                // var camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2.5, 1500, new BABYLON.Vector3(0, 0, 5), scene);
                camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2.05, 0, 4000, new BABYLON.Vector3(0, 150, -1500), scene);
                camera.attachControl(canvas, true);
                camera.minZ = 1;
                camera.maxZ = 25000;
                camera.upperRadiusLimit = 6000;
                camera.lowerRadiusLimit = 10;
                camera.wheelPrecision = 0.75;
                camera.panningSensibility = 9;
                // camera.useFramingBehavior = true;

                scene.activeCamera = camera;
                let camera_is_under = false;
                camera.onViewMatrixChangedObservable.add(function () {
                    // console.log(camera.position.y);
                    var ground = scene.getMeshByName("ground");
                    if (ground) {
                        if (camera_is_under && camera.position.y > 0) {
                            camera_is_under = !camera_is_under;
                            ground.isPickable = true;
                        }
                        else if (!camera_is_under && camera.position.y < 0) {
                            camera_is_under = !camera_is_under;
                            ground.isPickable = false;
                        }
                    }
                });

                // Add lights to the scene
                var light1 = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(1, 1, 0), scene);
                // var light2 = new BABYLON.PointLight("light2", new BABYLON.Vector3(150, 1000, 150), scene);
                light1.intensity = 0.6;


                var direction_light = new BABYLON.DirectionalLight("dirLight", new BABYLON.Vector3(1, -1, -0.5), scene);
                direction_light.intensity = 0.8;
                direction_light.position = new BABYLON.Vector3(-1000, 1000, 1000);


                //Light direction is directly down from a position one unit up, slow decay
                var torch = new BABYLON.SpotLight("spotLight", new BABYLON.Vector3(-1, 1, -1), new BABYLON.Vector3(0, -1, 0), Math.PI / 2, 30, scene);
                torch.diffuse = new BABYLON.Color3(0.5, 0.5, 0.5);
                torch.position = camera.position;
                torch.specular = new BABYLON.Color3(0.5, 0.5, 0.5);
                torch.intensity = 0.8;

                // Shadows
                var shadowGenerator = new BABYLON.ShadowGenerator(2048, direction_light);
                shadowGenerator.bias = 0.002;
                shadowGenerator.normalBias = 0.05;
                direction_light.shadowMaxZ = 3000;
                direction_light.shadowMinZ = 10;
                shadowGenerator.useBlurExponentialShadowMap = true;
                shadowGenerator.useKernelBlur = true;
                shadowGenerator.blurKernel = 128;
                shadowGenerator.useContactHardeningShadow = true;
                shadowGenerator.contactHardeningLightSizeUVRatio = 0.02;
                shadowGenerator.setDarkness(0.0);
                _scene_data.shadowGenerator = shadowGenerator;


                // return scene;
                var highlighter = new window.BABYLON.HighlightLayer("hightlighter2", scene);
                highlighter.innerGlow = true;

                var alpha = 0;
                scene.registerBeforeRender(() => {
                    alpha += 0.09;

                    highlighter.blurHorizontalSize = 0.3 + Math.cos(alpha) * 0.3 + 0.6;
                    highlighter.blurVerticalSize = 0.3 + Math.sin(alpha / 3) * 0.3 + 0.6;
                });
                _scene_data.highlighter = highlighter;

                // GIZMO Manager
                // Initialize GizmoManager
                _scene_data.gizmoManager = new window.BABYLON.GizmoManager(scene);
                _scene_data.gizmoManager.boundingBoxGizmoEnabled = true;
                _scene_data.gizmoManager.boundingBoxDragBehavior = null;

                _scene_data.gizmoManager.positionGizmoEnabled = false;
                _scene_data.gizmoManager.gizmos.boundingBoxGizmo.setEnabledRotationAxis('y');
                _scene_data.gizmoManager.gizmos.boundingBoxGizmo.rotationSphereSize = 20.0;
                _scene_data.gizmoManager.gizmos.boundingBoxGizmo.scaleBoxSize = 20.0;
                _scene_data.gizmoManager.gizmos.boundingBoxGizmo.onScaleBoxDragEndObservable.add((event) => {
                    _scene_data.gizmoManager.gizmos.boundingBoxGizmo.attachedMesh.position.y = 0;
                });

                _scene_data.gizmoManager.gizmos.updateGizmoPositionToMatchAttachedMesh = true;
                _scene_data.gizmoManager.attachableMeshes = _scene_data.added_objects;

                document.onkeydown = (e) => {
                    if (e.keyCode === 8 || e.keyCode === 46) {
                        console.log('DELETE was pressed');
                        console.log(_scene_data.gizmoManager.gizmos.boundingBoxGizmo.attachedMesh);

                        if (_scene_data.gizmoManager.gizmos.boundingBoxGizmo && _scene_data.gizmoManager.gizmos.boundingBoxGizmo.attachedMesh) {
                            _scene_data.gizmoManager.gizmos.boundingBoxGizmo.attachedMesh.dispose();
                            _scene_data.gizmoManager.gizmos.boundingBoxGizmo.attachedMesh = null;
                        };
                    }
                }

                //When click event is raised
                canvas.addEventListener("click", function () {
                    // We try to pick an object
                    // var pickResult = scene.pick(scene.pointerX, scene.pointerY);
                    // if (pickResult && pickResult.hit) console.log(pickResult);
                    // if (pickResult && pickResult.hit) console.log(pickResult.pickedMesh.id);
                    // if (pickResult && pickResult.hit) console.log(pickResult.pickedPoint);
                });

                scene.registerBeforeRender(function () {
                    torch.position = camera.position;
                    torch.setDirectionToTarget(new window.BABYLON.Vector3(0, 0, 0));
                });


                scene.onPointerObservable.add((pointerInfo) => {
                    if (pointerInfo.type === window.BABYLON.PointerEventTypes.POINTERPICK) {
                        if (!pointerInfo.pickInfo.hit || pointerInfo.pickInfo.pickedMesh.id === "skyBox" || pointerInfo.pickInfo.pickedMesh.id === "skyBox2" || pointerInfo.pickInfo.pickedMesh.id === "ground") {
                            var mesh_picked = _scene_data.mesh_picked;
                            if (mesh_picked) {
                                if (_scene_data.highlighter) _scene_data.highlighter.removeMesh(mesh_picked);
                                if (mesh_picked.material.alpha < 0.5) {
                                    if (mesh_picked.metadata) {
                                        if (mesh_picked.metadata.last_edgeRendererEnabled) mesh_picked.enableEdgesRendering(0.999);
                                        else mesh_picked.disableEdgesRendering();
                                        if (mesh_picked.metadata.last_edgesColor) mesh_picked.edgesColor = mesh_picked.metadata.last_edgesColor;
                                        if (mesh_picked.metadata.last_edgesWidth) mesh_picked.edgesWidth = mesh_picked.metadata.last_edgesWidth;
                                    }
                                    else {
                                        mesh_picked.disableEdgesRendering();
                                    }
                                }
                                mesh_picked.isPickable = true;
                            }
                            mesh_picked = null;
                            join_line.isVisible = false;
                            label.isVisible = true;
                            // _scene_data.handleStateChange({ selected_item: null });

                            _scene_data.update3DDesigner(APP.BABYLON_SELECTED_ITEM, null);
                            _scene_data.gizmoManager.attachToMesh(null);
                            _scene_data.mesh_picked = mesh_picked;
                        }
                    };
                });

                return scene;
            };
            /******* End of the create scene function ******/

            scene = createScene(); //Call the createScene function
            


            var advancedTexture = BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI("ui1");
            var label = new BABYLON.GUI.Rectangle("label for Ball");
            label.background = "black"
            label.height = "1px";
            label.alpha = 0.000;
            label.width = "400px";
            label.cornerRadius = 20;
            label.thickness = 1;
            label.linkOffsetY = 30;
            label.left = "10";
            label.top = "100";
            label.right = "160";
            label.zIndex = 5;
            label.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
            label.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_TOP;
            label.isVisible = true;
            var text1 = new BABYLON.GUI.TextBlock();
            text1.text = "";
            text1.color = "white";
            label.addControl(text1);
            advancedTexture.addControl(label);

            var join_line = new BABYLON.GUI.Line();
            join_line.alpha = 0.5;
            join_line.lineWidth = 3;
            join_line.dash = [3, 6];
            advancedTexture.addControl(join_line);
            join_line.connectedControl = label;
            join_line.isVisible = false;

            _scene_data.join_line = join_line;


            // Create utility layer the gizmo will be rendered on
            var utilLayer = new BABYLON.UtilityLayerRenderer(scene);

            // Create the gizmo and attach to the sphere
            _scene_data.gizmo_projection = new BABYLON.AxisScaleGizmo(new BABYLON.Vector3(0.1, 0, 0), BABYLON.Color3.FromHexString("#00b894"), utilLayer);
            _scene_data.gizmo_projection.snapDistance = 1;

            // Create the gizmo and attach to the sphere
            _scene_data.gizmo_length = new BABYLON.AxisScaleGizmo(new BABYLON.Vector3(0, 0.06, 0), BABYLON.Color3.FromHexString("#0094b8"), utilLayer);
            // gizmo_length.attachedMesh = plane;

            // Create the gizmo and attach to the sphere
            _scene_data.gizmo_shift_left = new BABYLON.AxisDragGizmo(new BABYLON.Vector3(0, -1, 0), BABYLON.Color3.FromHexString("#0094b8"), utilLayer);
            _scene_data.gizmo_shift_left.snapDistance = 10;
            // Create the gizmo and attach to the box
            _scene_data.gizmo_pitch = new BABYLON.PlaneRotationGizmo(new BABYLON.Vector3(0, 1, 0), BABYLON.Color3.FromHexString("#00b894"), utilLayer);
            _scene_data.gizmo_pitch.snapDistance = 0.005;

            var last_abs_rotation = 0;
            var max_pitch_rad = (Math.PI / 2) - 0.174533;

            _scene_data.gizmo_pitch.onSnapObservable.add((event, more, more2) => {
                console.log("snap pitch");
                console.log(event);
                let euler_angles = _scene_data.gizmo_pitch.attachedMesh.rotationQuaternion.toEulerAngles();
                console.log(euler_angles.x + "vs" + max_pitch_rad);
                let abs_rotation = Math.abs(euler_angles.x);
                if (abs_rotation < max_pitch_rad) {
                    // gizmo_pitch.attachedMesh.rotationQuaternion.x = pitch>0?max_pitch_rad:-max_pitch_rad;
                    euler_angles.x = max_pitch_rad;
                    _scene_data.gizmo_pitch.dragBehavior.releaseDrag();
                    _scene_data.gizmo_pitch.attachedMesh.rotationQuaternion = BABYLON.Quaternion.FromEulerAngles(euler_angles.x, euler_angles.y, euler_angles.z);
                }
                last_abs_rotation = abs_rotation;
            });

            _scene_data.scene = scene;
            _scene_data.canvas = canvas;
            // Register a render loop to repeatedly render the scene
            engine.runRenderLoop(function () {
                scene.render();
            });

            // Watch for browser/canvas resize events
            _.addEventListener("resize", function () {
                engine.resize();
            });
        };

        loadQQWebGL();

    }

    load_Plan() {
        // Add and manipulate meshes in the scene
        var scene = _scene_data.scene;
        var ground = BABYLON.MeshBuilder.CreateGround("ground", { height: 1022 * 4.7, width: 470 * 5.4 * 3.8808510638297872340425531914894 }, scene);

        ground.rotate(BABYLON.Axis.Y, -(Math.PI * 0.983), BABYLON.Space.WORLD);
        ground.position = new BABYLON.Vector3(0, 0, -1280);
        ground.receiveShadows = true;
        // ground.isPickable = false;

        var mat = new BABYLON.StandardMaterial("ground", scene);
        // mat.ambientTexture = new BABYLON.Texture("images/draw house outline.png", scene);
        mat.ambientTexture = new BABYLON.Texture("images/20190302_WGS84_S_Mercator_138_57260_-34_93000_138_57356_-34_92956_21.jpg", scene);
        
        // mat.alpha = 0.1;
        // mat.backFaceCulling = false;
        ground.material = mat;
        _scene_data.ground = ground;
        
        const SHOW_GROUND_PHOTO = this.props.app.designer.designer3D[APP.SHOW_GROUND_PHOTO];
        ground.isVisible = SHOW_GROUND_PHOTO;
    }

    load_2DLayout() {
        var scene = _scene_data.scene;

        // Add and manipulate meshes in the scene
        var twod_plan = BABYLON.MeshBuilder.CreateGround("2d_layout", { height: 2480 / 2.52, width: 3508 / 2.52 }, scene);

        twod_plan.rotate(BABYLON.Axis.Y, -(Math.PI), BABYLON.Space.WORLD);
        twod_plan.position = new BABYLON.Vector3(-8, 245, 47);
        twod_plan.receiveShadows = false;
        twod_plan.isPickable = false;

        var mat2 = new BABYLON.StandardMaterial("2d_layout_material", scene);
        // mat2.ambientColor = BABYLON.Color3.Purple();
        mat2.diffuseTexture = new BABYLON.Texture("images/test_opal.svg", scene);
        mat2.diffuseTexture.hasAlpha = true;
        // mat.alpha = 0.8;
        mat2.backFaceCulling = false;
        twod_plan.material = mat2;
        twod_plan.isVisible = this.state.display_2D_plan;
        _scene_data.twod_plan = twod_plan;
    }

    load_House() {
        const SHOW_HOUSE_MODEL = this.props.app.designer.designer3D[APP.SHOW_HOUSE_MODEL];
        var scene = _scene_data.scene;
        BABYLON.SceneLoader.ImportMesh(null, "models/", "house_withroof2.obj", scene, function (meshes_house) {
            try {
                _scene_data.house_meshes = meshes_house;
                for (var mesh of meshes_house) {

                    mesh.rotate(BABYLON.Axis.X, -Math.PI / 2, BABYLON.Space.WORLD)
                        .scaling = new BABYLON.Vector3(-0.1, 0.1, 0.1);
                    mesh.position = new BABYLON.Vector3(470, 0, -60);
                    mesh.backFaceCulling = false;
                    mesh.receiveShadows = true;
                    mesh.material = mesh.material.clone(mesh.id);
                    mesh.material.backFaceCulling = false;
                    mesh.actionManager = new BABYLON.ActionManager(scene);
                    if (mesh.id === "_mm1") {
                        mesh.material.diffuseColor = mesh.material.ambientColor;
                        mesh.material.specularColor = new BABYLON.Color3(0.2, 0.2, 0.2);
                        // mesh.material.specularPower = 0;
                        // mesh.material.reflectionTexture = new BABYLON.CubeTexture("textures/skybox", scene);
                        // mesh.material.reflectionTexture.coordinatesMode = BABYLON.Texture.CUBIC_MODE;
                        // mesh.material.reflectionTexture.level = 0.1;
                        mesh.id = "House Roof";
                    };

                    mesh.isVisible = SHOW_HOUSE_MODEL;

                    _scene_data.shadowGenerator.addShadowCaster(mesh);
                    _scene_data.makeOverOut(mesh);
                    // window.scene_data.makeOverOut(mesh);
                    // mesh.material.diffuseTexture.alpha = 0.9;
                    // shadowGenerator.addShadowCaster(mesh);
                    
                    if (!mesh.material.metadata) mesh.material.metadata = { last_alpha: mesh.material.alpha };
                };
                _scene_data.house_model_on = true;

                

        var plane = BABYLON.MeshBuilder.CreatePlane("Fascia3Example", { height: 700, width: 100, sideOrientation: BABYLON.Mesh.DOUBLESIDE }, scene);

        var vertexBuffer = plane.getVertexBuffer(BABYLON.VertexBuffer.PositionKind).getData();
        var myMaterial = new BABYLON.StandardMaterial("myMaterial", scene);
        myMaterial.diffuseColor = new BABYLON.Color3(1, 0, 1);
        myMaterial.specularColor = new BABYLON.Color3(0.5, 0.6, 0.87);
        myMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
        myMaterial.ambientColor = new BABYLON.Color3(0.23, 0.98, 0.53);
        myMaterial.alpha = 0.5;
        plane.material = myMaterial;
        // plane.material.diffuseColor = BABYLON.Color3.Purple;
        plane.actionManager = new BABYLON.ActionManager(scene);
        _scene_data.makeOverOut(plane);
        plane.setPivotMatrix(BABYLON.Matrix.Translation(50, 350, 0), false);
        plane.rotate(BABYLON.Axis.Z, Math.PI + 0.03, BABYLON.Space.LOCAL);
        plane.rotate(BABYLON.Axis.X, -Math.PI / 2, BABYLON.Space.LOCAL);
        // plane.rotate(BABYLON.Axis.Z, Math.PI/2, BABYLON.Space.LOCAL);
        plane.position = new BABYLON.Vector3(-305.0079853397958, 250.0, -1130.531284391886);
        // plane.position.z += -350;
        // plane.position.x += -5;
        plane.enableEdgesRendering();
        plane.edgesWidth = 300.0;
        plane.edgesColor = new BABYLON.Color4(0.3, 0.4, 1, 1);
        plane.isVisible = SHOW_HOUSE_MODEL;
        
        if (!plane.material.metadata) plane.material.metadata = { last_alpha: plane.material.alpha };
        _scene_data.house_meshes.push(plane);

            } catch (err) {
                console.log(err);
            }
        });

    }

    load_3DPlan() {
        var scene = _scene_data.scene;
        const SHOW_VERANDAH_MODEL = this.props.app.designer.designer3D[APP.SHOW_VERANDAH_MODEL];
        const VERANDAH_MESH_MODE = this.props.app.designer.designer3D[APP.VERANDAH_MESH_MODE];
        const VERANDAH_EDGE_LINE_MODE = this.props.app.designer.designer3D[APP.VERANDAH_EDGE_LINE_MODE];

        window.BABYLON.SceneLoader.ImportMesh(null, "models/", "flatgable.obj", scene, function (meshes) {

            try {

                let floor = meshes[0];
                let floor_width = floor.getBoundingInfo().boundingBox.extendSize.x * 0.1;
                let floor_depth = floor.getBoundingInfo().boundingBox.extendSize.y * 0.1;

                // floor.receiveShadows = true;
                // floor.isPickable = false;
                    

                // for (var mesh of meshes) {
                //     if (mesh.id.includes("_mm1")
                //         || mesh.id.includes("_mm2")
                //         || mesh.id.includes("_mm3")
                //         || mesh.id.includes("group_1")
                //         || mesh.id.includes("group_6")) mesh.dispose();
                // };
                // meshes = meshes.filter(item => (!item.id.includes("_mm1")
                //     && !item.id.includes("_mm2")
                //     && !item.id.includes("_mm3")
                //     && !item.id.includes("group_1")
                //     && !item.id.includes("group_6")));
                for (var mesh of meshes) {
                    if (mesh.id.includes("_mmxx1")) mesh.dispose();
                };
                meshes = meshes.filter(item => (!item.id.includes("_mmxx1")));

                _scene_data.verandah_meshes = meshes;

                for (mesh of meshes) {

                    // if (mesh.id.includes("_mm1")) {mesh.dispose()};
                    // if (mesh.id.includes("_mm3")) mesh.isVisible = false;
                    // if (mesh.id.includes("_mm2")) mesh.isVisible = false;
                    // if (mesh.id.includes("group_1")) mesh.isVisible = false;

                    mesh.rotate(window.BABYLON.Axis.X, -Math.PI / 2, window.BABYLON.Space.WORLD)
                        .scaling = new window.BABYLON.Vector3(-0.1, 0.1, 0.1);
                    mesh.position = new window.BABYLON.Vector3(floor_width, 0, floor_depth);
                    mesh.backFaceCulling = false;
                    mesh.receiveShadows = true;
                    mesh.material = mesh.material.clone(mesh.id);
                    mesh.material.backFaceCulling = false;


                    if (mesh.id.includes("group_4")) {
                        mesh.material.reflectionTexture = new window.BABYLON.CubeTexture("textures/skybox", scene);
                        mesh.material.reflectionTexture.coordinatesMode = window.BABYLON.Texture.CUBIC_MODE;
                        mesh.material.reflectionTexture.level = 0.06;
                        mesh.material.bumpTexture = new window.BABYLON.Texture("models/ZMM_cdeck_normal.png", scene);
                        mesh.material.useParallax = true;
                        mesh.material.useParallaxOcclusion = true;
                        mesh.material.parallaxScaleBias = 1;
                        mesh.name = "RoofSheets";
                    }

                    if (!mesh.material.metadata) mesh.material.metadata = { last_alpha: mesh.material.alpha };

                    // mesh.enableEdgesRendering(0.999);
                    // mesh.edgesColor = new window.BABYLON.Color4(0.2, 0.2, 0.2, 0.4);
                    // mesh.edgesWidth = 100;
                    mesh.actionManager = new window.BABYLON.ActionManager(scene);
                    _scene_data.makeOverOut(mesh);

                    _scene_data.shadowGenerator.addShadowCaster(mesh);
                    // shadowGenerator_torch.addShadowCaster(mesh);
                }

                // makeOverOut(floor);
                // floor.material.backFaceCulling = true;
                // floor.material.alpha = 0.9;
                // floor.disableEdgesRendering();
                // floor.isVisible = false;
                // floor.id = "Floor";
                for (mesh of _scene_data.verandah_meshes) mesh.isVisible = SHOW_VERANDAH_MODEL;
                // do something with the meshes and skeletons
                // particleSystems are always null for glTF assets
                
                BabylonFunctions.updateMeshMode(_scene_data.verandah_meshes, VERANDAH_MESH_MODE, VERANDAH_EDGE_LINE_MODE);

            
                if (SHOW_VERANDAH_MODEL) {
                    // focus onto model
                    var target_mesh = scene.getMeshByName("RoofSheets");
                    if (target_mesh === null) target_mesh = _scene_data.verandah_meshes[0];
                    BabylonFunctions.easeCameraToMesh(target_mesh);
                }


            }
            catch (err) {
                console.log(err.message);
                console.log(err);
            };


        });
    }

    load_Gazebo() {
        var scene = _scene_data.scene;
        const SHOW_VERANDAH_MODEL = this.props.app.designer.designer3D[APP.SHOW_VERANDAH_MODEL];
        const VERANDAH_MESH_MODE = this.props.app.designer.designer3D[APP.VERANDAH_MESH_MODE];
        const VERANDAH_EDGE_LINE_MODE = this.props.app.designer.designer3D[APP.VERANDAH_EDGE_LINE_MODE];

        window.BABYLON.SceneLoader.ImportMesh(null, "models/", "gazebo6.obj", scene, function (meshes) {

            try {

                let floor = meshes[0];
                let floor_width = floor.getBoundingInfo().boundingBox.extendSize.x * 0.1;
                let floor_depth = floor.getBoundingInfo().boundingBox.extendSize.y * 0.1;

                // floor.receiveShadows = true;
                // floor.isPickable = false;

                for (var mesh of meshes) {
                    if (mesh.id.includes("_mm1")
                        || mesh.id.includes("_mm2")
                        || mesh.id.includes("_mm3")
                        || mesh.id.includes("group_1")
                        || mesh.id.includes("group_6")) mesh.dispose();
                };
                meshes = meshes.filter(item => (!item.id.includes("_mm1")
                    && !item.id.includes("_mm2")
                    && !item.id.includes("_mm3")
                    && !item.id.includes("group_1")
                    && !item.id.includes("group_6")));

                _scene_data.gazebo_meshes = meshes;

                for (var mesh of meshes) {

                    // if (mesh.id.includes("_mm1")) {mesh.dispose()};
                    // if (mesh.id.includes("_mm3")) mesh.isVisible = false;
                    // if (mesh.id.includes("_mm2")) mesh.isVisible = false;
                    // if (mesh.id.includes("group_1")) mesh.isVisible = false;

                    mesh.rotate(window.BABYLON.Axis.X, -Math.PI / 2, window.BABYLON.Space.WORLD)
                        .scaling = new window.BABYLON.Vector3(-0.1, 0.1, 0.1);
                    mesh.position = new window.BABYLON.Vector3(floor_width, 0, floor_depth);
                    mesh.backFaceCulling = false;
                    mesh.receiveShadows = true;
                    mesh.material = mesh.material.clone(mesh.id);
                    mesh.material.backFaceCulling = false;


                    if (mesh.id.includes("group_4")) {
                        mesh.material.reflectionTexture = new window.BABYLON.CubeTexture("textures/skybox", scene);
                        mesh.material.reflectionTexture.coordinatesMode = window.BABYLON.Texture.CUBIC_MODE;
                        mesh.material.reflectionTexture.level = 0.06;
                        mesh.material.bumpTexture = new window.BABYLON.Texture("models/ZMM_cdeck_normal.png", scene);
                        mesh.material.useParallax = true;
                        mesh.material.useParallaxOcclusion = true;
                        mesh.material.parallaxScaleBias = 1;
                        mesh.name = "RoofSheets";
                    }

                    if (!mesh.material.metadata) mesh.material.metadata = { last_alpha: mesh.material.alpha };

                    // mesh.enableEdgesRendering(0.999);
                    // mesh.edgesColor = new window.BABYLON.Color4(0.2, 0.2, 0.2, 0.4);
                    // mesh.edgesWidth = 100;
                    mesh.actionManager = new window.BABYLON.ActionManager(scene);
                    _scene_data.makeOverOut(mesh);

                    _scene_data.shadowGenerator.addShadowCaster(mesh);
                    // shadowGenerator_torch.addShadowCaster(mesh);
                }

                // makeOverOut(floor);
                // floor.material.backFaceCulling = true;
                // floor.material.alpha = 0.9;
                // floor.disableEdgesRendering();
                // floor.isVisible = false;
                // floor.id = "Floor";
                for (mesh of _scene_data.gazebo_meshes) mesh.isVisible = SHOW_VERANDAH_MODEL;
                // do something with the meshes and skeletons
                // particleSystems are always null for glTF assets
                
                BabylonFunctions.updateMeshMode(_scene_data.gazebo_meshes, VERANDAH_MESH_MODE, VERANDAH_EDGE_LINE_MODE);

            


            }
            catch (err) {
                console.log(err.message);
                console.log(err);
            };


        });
    }


    createTreeQQ() {
        var scene = _scene_data.scene;
        ///TREES
        //leaf material
        var green = new window.BABYLON.StandardMaterial("green", scene);
        green.diffuseColor = new window.BABYLON.Color3(0.5, 0.7, 0.3);

        //trunk and branch material
        var bark = new window.BABYLON.StandardMaterial("bark", scene);
        // bark.emissiveTexture = new window.BABYLON.Texture("https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Bark_texture_wood.jpg/800px-Bark_texture_wood.jpg", scene);
        bark.ambientTexture = new window.BABYLON.Texture("textures/treebark.png", scene);
        // bark.diffuseTexture = new window.BABYLON.Texture("https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Bark_texture_wood.jpg/800px-Bark_texture_wood.jpg", scene);
        bark.ambientTexture.uScale = 5.0;//Repeat 5 times on the Vertical Axes
        bark.ambientTexture.vScale = 3.0;//Repeat 5 times on the Horizontal Axes	
        bark.specularColor = new window.BABYLON.Color3(0, 0, 0);

        //Tree parameters			
        var trunk_height = 20;
        var trunk_taper = 0.6;
        var trunk_slices = 5;
        var boughs = 2; // 1 or 2
        var forks = 4;
        var fork_angle = Math.PI / 4;
        var fork_ratio = 2 / (1 + Math.sqrt(5)); //PHI the golden ratio
        var branch_angle = Math.PI / 3;
        var bow_freq = 2;
        var bow_height = 3.5;
        var branches = 10;
        var leaves_on_branch = 5;
        var leaf_wh_ratio = 0.5;

        return _.createTree(trunk_height, trunk_taper, trunk_slices, bark, boughs, forks, fork_angle, fork_ratio, branches, branch_angle, bow_freq, bow_height, leaves_on_branch, leaf_wh_ratio, green, scene);
    }

    toggleOpacity(id) {
        for (var mesh of _scene_data.verandah_meshes) {
            if (mesh.id === id) {
                if (mesh.material.alpha < 0.8) {
                    mesh.material.alpha = 1.0;
                    mesh.isPickable = true;
                }
                else {
                    mesh.isPickable = false;
                    mesh.material.alpha = 0.2;
                }
            };
        };
    };
};


QQ3DWebGL.propTypes = {
    classes: PropTypes.object.isRequired,
};


// export default withStyles(styles)(QQ3DWebGL);


export default connect(mapStateToProps, importedActions)(withStyles(styles)(QQ3DWebGL));





// export default QQ2DCanvas;