// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_content_delivery_bucket": "design-cloud-20190320194024-hostingbucket-test",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "http://design-cloud-20190320194024-hostingbucket-test.s3-website-ap-southeast-2.amazonaws.com",
    "aws_cognito_identity_pool_id": "ap-southeast-2:032d1d7f-f87b-438c-af2e-eda113c00d80",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_QCFIkMiYA",
    "aws_user_pools_web_client_id": "1t0e8s357d3m5bsuomt95kgsla",
    "aws_mobile_analytics_app_id": "8229cde9e0694d5397fb9d142a8eb5da",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_user_files_s3_bucket": "designcloudtest-test",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "test_roofsheets-test",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://5wkoe3dxs5dlbpdms25byhwcs4.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "api",
            "endpoint": "https://dkgndcm6d1.execute-api.ap-southeast-2.amazonaws.com/test",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
