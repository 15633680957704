import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import ColourChip from './ColourChip';

// QQ3DWebGL.propTypes = {
//     classes: PropTypes.object.isRequired,
// };


const styles = {
    cssLabel: {
        focused: {
            color: "orange"
        },
        cssFocused: {
          color:'orange',
        },
    },
    focused: {},
    select: {
        color: "white",
        focused: {
            color: "orange"
        },
    },
    underline: {
        borderBottomColor: 'orange',
        '&:after': {
          // The MUI source seems to use this but it doesn't work
          borderBottomColor: 'orange',
        },
        '&:before': {
          // The MUI source seems to use this but it doesn't work
          borderBottom: 'none',
        },
    },
    orangeText : {
        color: "orange",
        focused: {
            color: "orange"
        },
        cssFocused: {
          color:'orange',
        },
    },
    whiteText : {
        color: "white",
        '&:focus':{
          fontSize: '10px',
          color: '#c1c1c2',
        }
    },
};

function ColourSelector(props) {
    const { classes } = props;

    // let colourlist = "Colorbond";
    // if (props.selectedItem && props.selectedItem.GroupingName === "Beams") colourlist = "CentenaryBeam";
    // let double_sided = true;
    // if (props.selectedItem) double_sided = props.selectedItem.ProductCode.includes("**H**") || props.selectedItem.ProductCode.includes("****")
    // let desc = "";
    // if (props.selectedItem) desc = props.selectedItem.ProductDescription
    // let colour_options = setup_editor_colour_selector(props.colourList, props.doubleSided, desc);

    let colour_options = [];
    colour_options.push(<MenuItem key={"TBA"} value="TBA">UNDECIDED</MenuItem>);

    for (var key in props.colourList) {
        if (props.colourList.hasOwnProperty(key)) {
            // console.log(key + " -> " + p[key]);
            if (!props.allowDoubleSided && key.includes("/")) { }
            else colour_options.push(<MenuItem key={key} value={key}><ColourChip colour={key} width="2rem"/>&nbsp;{key}</MenuItem>);
        }
    }


    return (
        <FormControl style={{ width: "100%", textAlign: "left" }}>
            <InputLabel htmlFor={props.id} shrink={true}
                className={classes.whiteText}
                // FormLabelClasses={classes}
                FormLabelClasses={{
                    root: classes.cssLabel,
                    focused: classes.focused,
                  }}
            >{props.text}</InputLabel>
            <Select
                value={props.value}
                className={classes.select}
                onChange={props.onChange}
                name="Colour"
                
                input={<Input
                classes={{
                    focused: classes.orangeText,
                    underline: classes.underline,
                    }}
                    name={props.id}
                    id={props.id} />
                }
                // classes={{label: classes.whiteText, labelFocussed: classes.whiteText}}
            >
                {colour_options}
            </Select>
            {/* <FormHelperText>Some important helper text</FormHelperText> */}
        </FormControl>
    );
}


// function setup_editor_colour_selector(list_name = "Colorbond", double_sided_allowed = true, desc = null) {

//     let options = [];
//     if (desc) {
//         let colour = $.get_colour_from_desc(desc, null);
//         if (colour) return [<MenuItem value={colour}><BOMListItemColourChip colour={key} width="2rem" /> {colour}</MenuItem>]
//     }

//     var list = $.Colorbond_Colours;
//     if (list_name === "CentenaryBeam") list = $.Centenary_Beam_Colours;

//     options.push(<MenuItem value=""></MenuItem>);

//     for (var key in list) {
//         if (list.hasOwnProperty(key)) {
//             // console.log(key + " -> " + p[key]);
//             if (!double_sided_allowed && key.includes("/")) { }
//             else options.push(<MenuItem value={key}><BOMListItemColourChip colour={key} width="2rem" /> {key}</MenuItem>);
//         }
//     }
//     return options;
// }

export default (withStyles(styles)(ColourSelector));