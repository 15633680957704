import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// REDUX
import { Provider } from 'react-redux';
import store from './redux_store';


class ReduxWrapper extends Component {
    render() {
      return (
        <Provider store={store}>
          <App />
        </Provider>
      );
    }
}

ReactDOM.render(<ReduxWrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
