const paper = window.paper;
var _this = {};


export function zoomAll() {
    console.log(paper.project.activeLayer.children.length);
    if (paper.project.activeLayer.children.length === 0) return;
    var screen_size = window.paper.view.viewSize;
    var screen_ratio = screen_size.width / screen_size.height;

    var objects_size = window.paper.project.activeLayer.bounds.size;
    var objects_ratio = objects_size.width / objects_size.height;

    if (isNaN(objects_ratio)) return;
    var zoom = 1;
    console.log("screen_view_ratio: " + screen_ratio + " " + window.paper.view.viewSize.width + " " + window.paper.view.viewSize.height);
    console.log("screen_ratio: " + screen_ratio + " " + screen_size.width + " " + screen_size.height);
    console.log("objects_ratio: " + objects_ratio + " " + objects_size.width + " " + objects_size.height);
    if (objects_ratio > screen_ratio) {
        //scale by width
        zoom = screen_size.width / objects_size.width;
    }
    else {
        //scale by height
        zoom = screen_size.height / objects_size.height;
    }
    console.log(zoom);
    window.paper.view.zoom = zoom * 0.90;
    window.paper.view.center = window.paper.project.activeLayer.bounds.center;
    console.log(window.paper.view.position);
    console.log(window.paper.project.activeLayer.bounds.position);
}

export function loadMap() {

    var map_url = "images/20190302_WGS84_S_Mercator_138_57260_-34_93000_138_57356_-34_92956_21.jpg";
    var map_geodata_url = map_url.replace(".jpg", ".jgw");

    fetch(map_geodata_url)
    .then(res => {console.log(res); return res.text()}) // Gets the response and returns it as a blob
    .then(text => {
            // By lines
        var lines = text.split('\n');
        var x_scale = 1;
        var y_scale = 1;
        for (var line = 0; line < 4; line++) {
            // console.log(lines[line]);
            if (line === 0) x_scale = Number(lines[line]);
            if (line === 3) y_scale = Number(lines[line]);
        }
        var raster = new window.paper.Raster(map_url);
        raster.onLoad = function () {
            raster.name = "imported_map";
            raster.sendToBack();
            raster.width = 87730/window.paper.view.scale;
            raster.height = 48880/window.paper.view.scale;
            raster.opacity = 0.5;
            zoomAll();
        };
  });
    window.paper.view.draw();
}

export function loadGoogleMap(address, zoom = 21) {
console.log(zoom);
    let scale = 2;
    // let zoom = 21;
    let zoom_scale_base_20 = 1.128497220/0.94;
    let zoom_scale = zoom_scale_base_20/2;
    if (zoom === 20) zoom_scale = zoom_scale_base_20;
    if (zoom === 19) zoom_scale = zoom_scale_base_20*2;
    if (zoom <= 18) {
        zoom = 18;
        zoom_scale = zoom_scale_base_20*4;
    }

    // let metersPerPx = 156543.03392 * Math.cos(138.476657 * Math.PI / 180) / Math.pow(2, zoom);
    // console.log("metersPerPx:" + metersPerPx);
    // console.log("zoom_scale:" + zoom_scale);
    // zoom_scale = -metersPerPx*10;

    var map_url = "https://maps.googleapis.com/maps/api/staticmap?center=" + address + "&zoom=" + zoom + "&scale=" + scale + "&size=1800x1800&maptype=hybrid&key=AIzaSyDusbQSuHd8yi5wBk8x4skpqjH4ttqBc2w";


    // var map_geodata_url = map_url.replace(".jpg", ".jgw");
    
    var item = window.paper.project.getItem({
        name: "imported_map_google"
    });

    console.log(item);
    if (item) item.remove();

    var raster = new window.paper.Raster(map_url);
    raster.onLoad = function () {
        raster.name = "imported_map_google";
        raster.sendToBack();
        raster.width = raster.width * scale * zoom_scale;
        raster.height = raster.height * scale * zoom_scale;
        // raster.width = 1128.497220/window.paper.view.scale;
        // raster.height = 1128.497220/window.paper.view.scale;
        raster.opacity = 0.5;
        zoomAll();
    };

    // fetch(map_geodata_url)
    // .then(res => {console.log(res); return res.text()}) // Gets the response and returns it as a blob
    // .then(text => {
    //         // By lines
    //     var lines = text.split('\n');
    //     var x_scale = 1;
    //     var y_scale = 1;
    //     for (var line = 0; line < 4; line++) {
    //         // console.log(lines[line]);
    //         if (line === 0) x_scale = Number(lines[line]);
    //         if (line === 3) y_scale = Number(lines[line]);
    //     }
//   });
    window.paper.view.draw();
}

export function UpdateSVGsIfRequired(svgs) {
    for (var key in svgs) {
        if (window.paper.project !== null && svgs.hasOwnProperty(key) && svgs[key] !== null && svgs[key] !== "") {
            // console.log(svg);
            // var svg_url = URL.createObjectURL(svg);
            // console.log(key); // key (ex. sandwich)
            // console.log(lunch[key]); // value (ex. turkey)
            

            let svg_url = svgs[key];
            
            var item = window.paper.project.getItem({
                name: key
            });

            if (item /* exists_in_paper*/) {
                // check if already added, and update existing
                // item.visible = false;
                
                paper.project.importSVG(svg_url, {
                    onLoad: (new_item, svg_raw) => {
                        new_item.name = key;
                        new_item.scale(1);
                        item.remove();
                        item.name = null;
                        this.zoomAll();
                    }
                });
            }
            else {
                // console.log(svg);
                // console.log(svg_url);

                paper.project.importSVG(svg_url, {
                    onLoad: (new_item, svg_raw) => {
                        new_item.name = key;
                        new_item.scale(1);
                        this.zoomAll();
                    }
                });

            }
        }
    }
}



export function generateRoof() {
    var house_plan = window.paper.project.importSVG("images/roof_native.svg", {
        onLoad: (item, svg_raw) => {
            console.log(item);
            // item.scale(0.41, 0.41);
            item.scale(1/window.paper.view.scale);
            item.rotate(-3.555);
            item.position = new window.paper.Point(-10, -150);
            item.bringToFront();
            item.on('mousedrag', function (e) {
                item.position = item.position.add(e.delta);
            });
        }
    });
    // house_plan.rotate(45);
}

export function ClearAll() {

    paper.project.activeLayer.clear();
    var from = new window.paper.Point(0, 0);
    var middle = new window.paper.Point(0, 0);
    var to = new window.paper.Point(0, 0);
    paper.project.activeLayer.rubberband = new window.paper.Path(from, middle, to);
    var rubberband = paper.project.activeLayer.rubberband;
    rubberband.name = "rubberband";
    rubberband.strokeColor = 'black';
    rubberband.dashArray = [3, 3];
    rubberband.visible = false;
    paper.project.activeLayer.rubberband_label = new window.paper.PointText(new window.paper.Point(200, 50));
    var rubberband_label = paper.project.activeLayer.rubberband_label;
    rubberband_label.name = "rubberband label";
    rubberband_label.justification = 'center';
    rubberband_label.justification = 'center';
    rubberband_label.fillColor = 'black';
    rubberband_label.content = '2000mm';
    rubberband.visible = false;
    paper.view.draw();
}


export function Load_FlatDesign() {
    paper.project.importSVG("images/flat.svg", {
        onLoad: (item, svg_raw) => {
            item.scale(1);
            this.zoomAll();
        }
    });
}

export function loadToggleSVG(file_name) {

    var item = window.paper.project.getItem({
        name: file_name
    });

    if (item !== null) {
        item.visible = !item.visible;
    }
    else {
        paper.project.importSVG("images/" + file_name + ".svg", {
            onLoad: (item, svg_raw) => {
                item.name = file_name;
                item.scale(1);
                this.zoomAll();
            }
        });
    }
}


export function ForceUpdate() {
    function ExecuteUpdate() {
        if (paper.project) {
            // paper.project._needsUpdate = true;
            // paper.project.view.update();
            // paper.view.fire('resize', paper.view);
            window.dispatchEvent(new Event('resize'));
        }
    }

    if (paper.project) {
        ExecuteUpdate();
        setTimeout(function(){
            ExecuteUpdate();
            setTimeout(function(){
                ExecuteUpdate();
            }, 1000);
        }, 100);
    }
    else {
        setTimeout(function(){
            ExecuteUpdate();
            setTimeout(function(){
                ExecuteUpdate();
            }, 2000);
        }, 100);
    }
}

export function InitialisePaper(id) {
    // Get a reference to the canvas object
    var canvas = document.getElementById(id);
    // Create an empty project and a view for the canvas:
    paper.setup(canvas);
    paper.project.options.handleSize = 12;
    paper.settings.hitTolerance = 6;
    paper.view.zoom = 1;
    paper.view.scale = 25;
    paper.view.scale = 25;
    paper.view.viewSize = new window.paper.Size(1920, 1200);
    

    var segment_to_drag = false;
    var segment_to_drag_was_dragged = false;

    paper.view.onMouseUp = e => {
        console.log(e);
        // this.wasDragged = false;
        alert(e);

    };
    paper.view.onMouseDown = e => {
        // this.wasDragged = false;
        console.log(e);

        window.dragging = false;
        _this.downPoint = e.point;
        _this.downPointScreen = new window.paper.Point(e.event.x, e.event.y);
        _this.downButton = e.event.button;
        
        var selected_items = paper.project.getItems({ selected: true });
        segment_to_drag = false;
        segment_to_drag_was_dragged = false;
        if (_this.downButton === 0 && selected_items.length > 0) {
            var hitResult = paper.project.activeLayer.hitTest(e.point, { match: (function (o) { return ((o.type === "handle-in" || o.type === "handle-out") && o.item.name !== "rubberband") }), curves: true, handles: true, tolerance: window.paper.settings.hitTolerance / window.paper.view.zoom });
            console.log(hitResult);
            if (hitResult) segment_to_drag = hitResult.segment;
        }

    };

    
    var bg_pos1 = new paper.Point(-50, -50);
    var bg_pos2 = new paper.Point(-50, -50);
    var orig_bg_zoom1 = 100;
    var orig_zoom = 1;
    var last_rounded = 100;


    function offset_grid(offset = new paper.Point(-0, -0)) {
        var change_ratio = paper.view.zoom / orig_zoom;
        
        // var change_ratio = window.paper.view.zoom / orig_zoom;
        var rounded = orig_bg_zoom1 * change_ratio;
        var rounded_sm = rounded/5;
        document.getElementById("qqCanvas").style.backgroundSize = rounded + "px " + rounded + "px, " + rounded + "px " + rounded + "px, " + rounded_sm + "px " + rounded_sm + "px, " + rounded_sm + "px " + rounded_sm + "px";
    
        if (rounded !== last_rounded) {
            offset = offset.add(new window.paper.Point(-(rounded-last_rounded)/2, -(rounded-last_rounded)/2));
        }
        // bg_pos1 = bg_pos1.subtract(new window.paper.Point(-(rounded-last_rounded)/2, -(rounded-last_rounded)/2));
        // // bg_pos1 = bg_pos1.subtract(rounded);
        // bg_pos1 = bg_pos1.subtract(offset);
        // bg_pos2 = bg_pos2.subtract(offset);
        if (offset) {
            offset.x = offset.x * change_ratio;
            offset.y = offset.y * change_ratio;
            bg_pos1 = bg_pos1.subtract(offset);
            bg_pos2 = bg_pos2.subtract(offset);
            document.getElementById("qqCanvas").style.backgroundPosition = bg_pos1.x + "px " + bg_pos1.y + "px, " + bg_pos1.x + "px " + bg_pos1.y + "px, " + bg_pos2.x + "px " + bg_pos2.y + "px, " + bg_pos2.x + "px " + bg_pos2.y + "px";
        }
        last_rounded = rounded;
    };

    
    var qqCanvas = document.getElementById('qqCanvas');
    qqCanvas.addEventListener('wheel', function (e) {
        var delta = e.deltaY;
        var zoom = window.paper.view.zoom * (1 - delta / 5500);
        
        // zoom = zoom * (1 + delta / 5500);
        console.log(zoom + " " + delta);
        if (zoom > 7) zoom = 7;
        if (zoom < 0.05) zoom = 0.05;
        window.paper.view.zoom = zoom;
        
        offset_grid();

        e.preventDefault();
        e.stopPropagation();
    });
    qqCanvas.addEventListener('contextmenu', function (e) {
        e.preventDefault();
    });

    // return;

    
    window.paper.view.onMouseDrag = function (e) {
        //     // draw rubber band
        //     rubberband.visible = true;
        //     rubberband.bringToFront();
        //     rubberband.segments[0].point = this.lastPointAdded;
        //     rubberband.segments[1].point = e.point;
        // }
        
        var distance_dragged = _this.downPoint?_this.downPoint.getDistance(e.point):0;
        console.log(distance_dragged);
        if (_this.downButton === 0 && segment_to_drag && (distance_dragged > 10 || segment_to_drag_was_dragged)) {
            segment_to_drag.point = segment_to_drag.point.add(e.delta);
            segment_to_drag_was_dragged = true;
        }
        else if (_this.downButton === 0 || _this.downButton === 2) {
            _this.dragging = true;
            var offset = _this.downPoint.subtract(e.point);
            window.paper.view.center = window.paper.view.center.add(offset);
            offset_grid(offset);
            
            // document.getElementById("qqCanvas").style.backgroundPosition = "-2px -2px, -2px -2px, -1px -1px, -1px -1px";

            // var bg_pos = document.getElementById("qqCanvas").style.backgroundPosition;
            // if (bg_pos === "") bg_pos = "-2px -2px, -2px -2px, -1px -1px, -1px -1px";
            // var offsets = bg_pos.split(',');
            // var offset_1_X = offsets[0].split(' ')[0].replace("px","").trim();
            // var offset_1_Y = offsets[0].split(' ')[1].replace("px","").trim();
            // var offset_2_X = offsets[2].trim().split(' ')[0].replace("px","").trim();
            // var offset_2_Y = offsets[2].trim().split(' ')[1].replace("px","").trim();
       }
    }

    // The Path.Line constructor takes 2 points, defining the start and end of the line.
    var from = new window.paper.Point(0, 0);
    var middle = new window.paper.Point(0, 0);
    var to = new window.paper.Point(0, 0);
    paper.project.activeLayer.rubberband = new window.paper.Path(from, middle, to);
    var rubberband = paper.project.activeLayer.rubberband;
    rubberband.name = "rubberband";
    rubberband.strokeColor = 'black';
    rubberband.dashArray = [3, 3];
    rubberband.visible = false;
    paper.project.activeLayer.rubberband_label = new window.paper.PointText(new window.paper.Point(200, 50));
    var rubberband_label = paper.project.activeLayer.rubberband_label;
    rubberband_label.name = "rubberband label";
    rubberband_label.justification = 'center';
    rubberband_label.justification = 'center';
    rubberband_label.fillColor = 'black';
    rubberband_label.content = '2000mm';
    rubberband.visible = false;
    var rubberband_to_point;

    var offset;
    var offsetSpeed;
    var offset_run = false;
    var offset_running = false;
    var offset_direction = "top";

    window.paper.view.onMouseMove = function (e) {
        rubberband_label.visible = rubberband.visible;
        if (_this.adding_points_to_this_path && rubberband.visible) {

            console.log(rubberband);
            _this.last_onMouseMove_event_data = e;


            //snap e.point to vector angle
            var from_point = _this.rubberband_from;
            rubberband_to_point = e.point;
            if (from_point) {
               var vector_mouse = rubberband_to_point.subtract(from_point);
               var new_vector = new window.paper.Point(0, 0);
               var vector_last_line = new window.paper.Point(0, 0);

               if (_this.adding_points_to_this_path && _this.adding_points_to_this_path.segments.length >= 2) {
                   console.log(_this.adding_points_to_this_path);
                   let num_segments = _this.adding_points_to_this_path.segments.length;
                   vector_last_line = _this.adding_points_to_this_path.segments[num_segments-1].point.subtract(_this.adding_points_to_this_path.segments[num_segments-2].point);
                    console.log("vector last line " + vector_last_line.angle);
                //    vector_mouse = vector_mouse.subtract(vector_last_line);
               }

               
            //    console.log(vector_mouse.angle);
               new_vector.length = vector_mouse.length;
               var angle_relative_to_last_line = vector_mouse.angle - vector_last_line.angle;
               var mod = Math.abs((angle_relative_to_last_line) % 45);
               console.log(mod);

               var snap_tolerance = 3;
               var snapped = false;
               if (!e.modifiers.control) {
                if ((mod < snap_tolerance && mod > -snap_tolerance) || (mod > 45-snap_tolerance && mod < 45+snap_tolerance)) {
                    var angle = (Math.round(angle_relative_to_last_line / 45)*45) + vector_last_line.angle;
                    console.log("snap " + angle);
                    new_vector.angle = angle;
                    rubberband_to_point = from_point.add(new_vector);
                    snapped = true;
                }
               }

            //    if (vector_mouse.angle > 87.5 && vector_mouse.angle < 92.5) {
            //        //snap to 90
            //        new_vector.angle = 90;
            //        rubberband_to_point = from_point.add(new_vector);
            //    }
            //    if (vector_mouse.angle > 177.5 && vector_mouse.angle < 182.5) {
            //        //snap to 90
            //        new_vector.angle = 180;
            //        rubberband_to_point = from_point.add(new_vector);
            //    }
            //    if (vector_mouse.angle > -177.5 && vector_mouse.angle < -182.5) {
            //        //snap to 90
            //        new_vector.angle = 180;
            //        rubberband_to_point = from_point.add(new_vector);
            //    }
            //    if (vector_mouse.angle > -177.5 && vector_mouse.angle < -182.5) {
            //        //snap to 90
            //        new_vector.angle = 180;
            //        rubberband_to_point = from_point.add(new_vector);
            //    }
            }
            
            new window.paper.Point(0, 0);


            // console.log(e);
            // draw rubber band
            rubberband.bringToFront();
            rubberband.segments[0].point = _this.rubberband_from ? _this.rubberband_from : rubberband_to_point;
            rubberband.segments[1].point = rubberband_to_point;
            rubberband.segments[2].point = _this.rubberband_end ? _this.rubberband_end : rubberband_to_point;
            var vector1 = rubberband_to_point.subtract(rubberband.segments[0].point);
            
            var actual_angle_relative_to_last_line = vector1.angle - vector_last_line.angle;

            console.log(vector1);
                rubberband_label.point = e.point.add(new window.paper.Point(100/window.paper.view.zoom, 10));
                rubberband_label.content = Math.round(vector1.length*window.paper.view.scale) + "mm @" + actual_angle_relative_to_last_line.toFixed(2) + " degs\n" + (snapped?" *press ctrl to unsnap*":"");
                rubberband_label.fontSize = 14 / window.paper.view.zoom;

            var scroll_tolerance = 100;
            offsetSpeed = 2 / window.paper.view.zoom;
            if (e.event.y < scroll_tolerance) {
                offset_direction = "top";
                offset_run = true;
                if (e.event.x < scroll_tolerance*2) {
                    offset_direction = "top-left";
                }
                else if (e.event.x > (window.paper.view.viewSize.width - scroll_tolerance*2)) {
                    offset_direction = "top-right";
                }
                
                if (e.event.y < scroll_tolerance*2/3) offsetSpeed = offsetSpeed*2;
                if (e.event.y < scroll_tolerance/3) offsetSpeed = offsetSpeed*2;
                if (e.event.y < scroll_tolerance/10) offsetSpeed = offsetSpeed*2;
            }
            else if (e.event.y > (window.paper.view.viewSize.height - scroll_tolerance)) {
                offset_direction = "bottom";
                offset_run = true;
                if (e.event.x < scroll_tolerance*2) {
                    offset_direction = "bottom-left";
                }
                else if (e.event.x > (window.paper.view.viewSize.width - scroll_tolerance*2)) {
                    offset_direction = "bottom-right";
                }
                if (e.event.y > (window.paper.view.viewSize.height - scroll_tolerance*2/3)) offsetSpeed = offsetSpeed*2;
                if (e.event.y > (window.paper.view.viewSize.height - scroll_tolerance/3)) offsetSpeed = offsetSpeed*2;
                if (e.event.y > (window.paper.view.viewSize.height - scroll_tolerance/10)) offsetSpeed = offsetSpeed*2;
            }
            else if (e.event.x < scroll_tolerance) {
                offset_direction = "left";
                offset_run = true;
                if (e.event.x < scroll_tolerance*2/3) offsetSpeed = offsetSpeed*2;
                if (e.event.x < scroll_tolerance/3) offsetSpeed = offsetSpeed*2;
                if (e.event.x < scroll_tolerance/10) offsetSpeed = offsetSpeed*2;
            }
            else if (e.event.x > (window.paper.view.viewSize.width - scroll_tolerance)) {
                offset_direction = "right";
                offset_run = true;
                if (e.event.x > (window.paper.view.viewSize.width - scroll_tolerance*2/3)) offsetSpeed = offsetSpeed*2;
                if (e.event.x > (window.paper.view.viewSize.width - scroll_tolerance/3)) offsetSpeed = offsetSpeed*2;
                if (e.event.x > (window.paper.view.viewSize.width - scroll_tolerance/10)) offsetSpeed = offsetSpeed*2;
            }
            else {
                offset_run = false;
            }

            if (offset_run && !offset_running) {
                let offset_up = () => {
                   offset = new window.paper.Point(0, -offsetSpeed);
                   if (offset_direction === "top-left") offset = new window.paper.Point(-offsetSpeed, -offsetSpeed);
                   if (offset_direction === "top-right") offset = new window.paper.Point(offsetSpeed, -offsetSpeed);
                   if (offset_direction === "bottom") offset = new window.paper.Point(0, offsetSpeed);
                   if (offset_direction === "bottom-left") offset = new window.paper.Point(-offsetSpeed, offsetSpeed);
                   if (offset_direction === "bottom-right") offset = new window.paper.Point(offsetSpeed, offsetSpeed);
                   if (offset_direction === "left") offset = new window.paper.Point(-offsetSpeed, 0);
                   if (offset_direction === "right") offset = new window.paper.Point(offsetSpeed, 0);
                   window.paper.view.center = window.paper.view.center.add(offset);

                   // offset rubberband
                   rubberband.segments[1].point = rubberband.segments[1].point.add(offset);
                   offset_grid(offset);
                   window.paper.view.draw();

                   if (offset_run) setTimeout(function(){ offset_up(); }, 20);
                   else offset_running = false;
                }
                offset_running = true;
                offset_up();
            }
            // else if (!offset_run) {

            // }

                
                // }
                // offset = new window.paper.Point(0, 5 / window.paper.view.zoom);
                // window.paper.view.center = window.paper.view.center.add(offset);
                // }
                // else if(e.event.x < 70) {
                //     offset = new window.paper.Point(-5 / window.paper.view.zoom, 0);
                //     window.paper.view.center = window.paper.view.center.add(offset);
                // }
                // else if(e.event.x > (window.paper.view.viewSize.width - 70)) {
                //     offset = new window.paper.Point(5 / window.paper.view.zoom, 0);
                //     window.paper.view.center = window.paper.view.center.add(offset);
                // }
            // }
        }
    }


    window.paper.view.onMouseUp = function (e) {
        // draggin functions

        if (segment_to_drag && segment_to_drag_was_dragged) {
            segment_to_drag = false;
            segment_to_drag_was_dragged = false;
            return;
        }
        segment_to_drag = false;
        
        if (_this.dragging) {
            _this.dragging = false;

            var screen_distance = _this.downPointScreen.getDistance(new window.paper.Point(e.event.x, e.event.y));
            // console.log(screen_distance);

            if (e.event.button === 2 && screen_distance < 20) {
                if (_this.adding_points_to_this_path) {
                    _this.adding_points_to_this_path = null;
                    rubberband.visible = false;
                    rubberband.sendToBack();
                }
                else {
                    window.paper.project.deselectAll();
                }
            }
        }
        else {
            this.dragging = false;
            if (e.event.button === 0) {

                var selected_items = window.paper.project.getItems({ selected: true });

                if (_this.adding_points_to_this_path) {
                    var new_point = new window.paper.Point(e.point.x, e.point.y);
                    if (rubberband_to_point) new_point = rubberband_to_point;
                    _this.adding_points_to_this_path.fullySelected = false;
                    _this.adding_points_to_this_path.selected = true;
                    console.log(_this.adding_points_to_this_path_inserting_after_index + " vs " + _this.adding_points_to_this_path.segments.length);
                    if (_this.adding_points_to_this_path_inserting_after_index !== null && _this.adding_points_to_this_path_inserting_after_index < _this.adding_points_to_this_path.segments.length) {

                        console.log("inserting point");
                        let new_segment = _this.adding_points_to_this_path.insert(_this.adding_points_to_this_path_inserting_after_index + 1, new_point);
                        new_segment.selected = true;
                        _this.adding_points_to_this_path_inserting_after_index++;
                        _this.rubberband_from = new_point;
                    }
                    else {
                        console.log("adding point");

                        //check for closing shape
                        var hitResult = window.paper.project.activeLayer.hitTest(new_point, { match: (function (o) { return o.type !== "pixel" && o.item.name !== "rubberband" }), handles: true, tolerance: window.paper.settings.hitTolerance*2 / window.paper.view.zoom });

                        console.log(hitResult);
                        if (hitResult && hitResult.item === _this.adding_points_to_this_path && hitResult.segment.index === 0) {
                            // close shape
                            _this.adding_points_to_this_path.closed = true;
                            _this.adding_points_to_this_path_inserting_after_index = _this.adding_points_to_this_path.segments.length;
                            _this.rubberband_from = _this.adding_points_to_this_path.lastSegment.point;
                            _this.rubberband_end = _this.adding_points_to_this_path.firstSegment.point;
                            alert("closing shape");
                        }
                        else {

                            let new_segment = _this.adding_points_to_this_path.add(new_point);
                            new_segment.selected = true;
                            _this.rubberband_from = new_point;
                            if (_this.adding_points_to_this_path.closed) {
                                _this.rubberband_end = _this.adding_points_to_this_path.firstSegment.point;
                            }
                            else _this.rubberband_end = new_point;
                        }


                    }
                    _this.adding_points_to_this_path.bringToFront();
                    // console.log(house_attachment);
                }
                else if (!_this.adding_points_to_this_path && selected_items.length > 0) {
                    // check to see if handle clicked
                    // this.handleSelected = null;
                    // Do a hit test on selected paths for handles:
                    var hitResult = window.paper.project.activeLayer.hitTest(e.point, { match: (function (o) { return (o.type === "handle-in" || o.type === "handle-out") }), curves: true, handles: true, tolerance: window.paper.settings.hitTolerance / window.paper.view.zoom });
                    console.log(hitResult);
                    if (hitResult) {
                        console.log(hitResult.type);
                        // if (hitResult.type === 'handle-in') {
                        //     this.handleSelected = hitResult.segment.handleIn;
                        // } else {
                        //     this.handleSelected = hitResult.segment.handleOut;
                        // };
                        // console.log(this.handleSelected);
                        let segment = null;
                        if (hitResult.type === "curve") {
                            segment = hitResult.location._segment1;
                        }
                        else segment = hitResult.segment;

                        hitResult.item.fullySelected = false;
                        hitResult.item.selected = true;
                        segment.selected = true;

                        _this.adding_points_to_this_path = hitResult.item;
                        _this.adding_points_to_this_path_inserting_after_index = segment.index;
                        _this.insert = hitResult.item;
                        _this.rubberband_from = segment.point;
                        if (hitResult.item.segments.length > segment.index + 1) _this.rubberband_end = hitResult.item.segments[segment.index + 1].point;
                        else _this.rubberband_end = null;
                        rubberband.visible = true;
                    }
                    else {
                        window.paper.project.deselectAll();
                    }


                }
                else if (!_this.adding_points_to_this_path) {
                    // console.log(window.paper.project.activeLayer.hitTestAll(e.point));
                    let hits = window.paper.project.activeLayer.hitTestAll(e.point);
                    if (hits.length > 0 && (!hits[0].item.name || !hits[0].item.name.includes("imported_map"))) {
                        if (hits[0].item.fullySelected) return;
                        window.paper.project.deselectAll();
                        hits[0].item.selected = true;
                        // this.lastPointAdded = hits[0].item.segments[hits[0].item.segments.length-1].point;
                        // this.adding_points_to_this_path = hits[0].item;
                    }
                    else {
                        if (window.paper.project.getItems({ selected: true }).length > 0) {
                            window.paper.project.deselectAll();
                        }
                        else {
                            // begin adding points to new path?
                            window.paper.project.deselectAll();
                            var new_path = new window.paper.Path();
                            new_path.strokeColor = 'red';
                            new_path.strokeWidth = 4;
                            new_path.strokeCap = 'round';
                            new_path.strokeJoin = 'round';
                            new_path.selected = true;
                            var point = new window.paper.Point(e.point.x, e.point.y);
                            new_path.add(point);
                            _this.rubberband_from = point;
                            _this.rubberband_end = point;
                            rubberband.visible = true;
                            _this.adding_points_to_this_path = new_path;
                            new_path.on('mousedrag', function (e) {
                                console.log(e);
                                // new_path.position = new_path.position.add(e.delta);
                            });
                            console.log("adding points to");
                            console.log(_this.adding_points_to_this_path);
                        }


                    }
                };
            };

            if (e.event.button === 2) {
                if (_this.adding_points_to_this_path) {
                    _this.adding_points_to_this_path.fullySelected = false;
                    _this.adding_points_to_this_path = null;
                    rubberband.visible = false;
                    _this.lastPointAdded = null;
                }
                else {
                    window.paper.project.deselectAll();
                }
            }
        }




    }

    window.paper.view.onKeyDown = function (e) {
        console.log(e);
        var offset;
        if (e.key === "up") {
            offset = new window.paper.Point(0, -15 / window.paper.view.zoom);
            window.paper.view.center = window.paper.view.center.add(offset);
        }
        if (e.key === "down") {
            offset = new window.paper.Point(0, 15 / window.paper.view.zoom);
            window.paper.view.center = window.paper.view.center.add(offset);
        }
        if (e.key === "left") {
            offset = new window.paper.Point(-15 / window.paper.view.zoom, 0);
            window.paper.view.center = window.paper.view.center.add(offset);
        }
        if (e.key === "right") {
            offset = new window.paper.Point(15 / window.paper.view.zoom, 0);
            window.paper.view.center = window.paper.view.center.add(offset);
        }
        if (e.key === "delete" || e.key === "backspace") {
            var selected_items = window.paper.project.getItems({ selected: true });
            console.log(selected_items);
            if (selected_items.length > 0) {
                for (var path of selected_items) {
                    path.selected = true;
                    path.remove();
                    // this.adding_points_to_this_path = null;
                    // rubberband.visible = false;
                    // this.lastPointAdded = null;
                    // window.paper.project.deselectAll();
                }
            }
        }
        if (e.key === "control" && _this.adding_points_to_this_path && rubberband.visible && _this.last_onMouseMove_event_data) {
            console.log(_this.last_onMouseMove_event_data.modifiers);
            window.paper.view.emit('mousemove', _this.last_onMouseMove_event_data);
        }
    }
    
    window.paper.view.onKeyUp = function (e) {
        if (e.key === "control" && _this.adding_points_to_this_path && rubberband.visible && _this.last_onMouseMove_event_data) {
            console.log(_this.last_onMouseMove_event_data.modifiers);
            setTimeout(window.paper.view.emit('mousemove', _this.last_onMouseMove_event_data), 50);
        }
    }

    var qqCanvas = document.getElementById('qqCanvas');
    qqCanvas.addEventListener('wheel', function (e) {
        var delta = e.deltaY;
        var zoom = window.paper.view.zoom * (1 - delta / 5500);
        
        // zoom = zoom * (1 + delta / 5500);
        console.log(zoom + " " + delta);
        if (zoom > 7) zoom = 7;
        if (zoom < 0.05) zoom = 0.05;
        window.paper.view.zoom = zoom;
        
        offset_grid();

        e.preventDefault();
        e.stopPropagation();
    });
    qqCanvas.addEventListener('contextmenu', function (e) {
        e.preventDefault();
    });
};