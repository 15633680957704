import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Colours } from '../components/Common';
import * as Paper from './LayoutDesigner_PaperFunctions';

// Redux Actions
import { connect } from 'react-redux';
const mapStateToProps = state => ({
  app: state.app
});

const styles = {

    qq2dCanvasContainer: {
        zIndex: 0,
        display: "block",
        position: "absolute",
        top: "0px",
        right: "0px",
        backgroundColor: "rgba(255, 255, 255, 255)",
        width: "100%",
        height: "100%",
        color: "#000",
        transition: "visibility 0.3s, opacity 0.3s linear",
    },

    qq2dCanvas: {
        width: "100vw",
        height: "100vh",
        backgroundSize: "100px 100px, 100px 100px, 20px 20px, 20px 20px",
backgroundPosition: "-2px -2px, -2px -2px, -1px -1px, -1px -1px",
backgroundImage: "-webkit-linear-gradient(white 2px, transparent 2px),\
-webkit-linear-gradient(0, white 2px, transparent 2px),\
-webkit-linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),\
-webkit-linear-gradient(0, rgba(255,255,255,.3) 1px, transparent 1px),\
-moz-linear-gradient(white 2px, transparent 2px),\
-moz-linear-gradient(0, white 2px, transparent 2px),\
-moz-linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),\
-moz-linear-gradient(0, rgba(255,255,255,.3) 1px, transparent 1px),\
linear-gradient(white 2px, transparent 2px),\
linear-gradient(90deg, white 2px, transparent 2px),\
linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),\
linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px)",
    }
};

class QQ2DCanvas extends React.Component {

    componentDidMount() {
        console.log("mounting");
        Paper.InitialisePaper('qqCanvas');
    }

    render() {
        const { classes } = this.props;

        if (this.props.visible) {
            Paper.ForceUpdate();
            Paper.UpdateSVGsIfRequired(this.props.app.designer.designer2D.svgs);
        }
        
        return (
            <div className={classes.qq2dCanvasContainer + (this.props.visible ? "" : " invisible")}>
                <canvas id="qqCanvas" className={classes.qq2dCanvas} style={{width:"100%"}} resize="true"></canvas>
            </div>
        );
    };
    // ALL 2D CANVAS RELATED STUFF
    
};

QQ2DCanvas.propTypes = {
    classes: PropTypes.object.isRequired,
};

// export default withStyles(styles)(QQ2DCanvas);
export default connect(mapStateToProps, null)(withStyles(styles)(QQ2DCanvas));


    // componentWillUpdate(nextProps, nextState) {
    //     // for (var canvas_item of nextState.canvas_objects) {
    //     //     this.canvas.add(canvas_item);
    //     // }
    //     // this.canvas.renderAll();
    // }


    // zoomAll() {
    //     console.log(paper.project.activeLayer.children.length);
    //     if (paper.project.activeLayer.children.length === 0) return;
    //     var screen_size = window.paper.view.viewSize;
    //     var screen_ratio = screen_size.width / screen_size.height;

    //     var objects_size = window.paper.project.activeLayer.bounds.size;
    //     var objects_ratio = objects_size.width / objects_size.height;

    //     if (isNaN(objects_ratio)) return;
    //     var zoom = 1;
    //     console.log("screen_view_ratio: " + screen_ratio + " " + window.paper.view.viewSize.width + " " + window.paper.view.viewSize.height);
    //     console.log("screen_ratio: " + screen_ratio + " " + screen_size.width + " " + screen_size.height);
    //     console.log("objects_ratio: " + objects_ratio + " " + objects_size.width + " " + objects_size.height);
    //     if (objects_ratio > screen_ratio) {
    //         //scale by width
    //         zoom = screen_size.width / objects_size.width;
    //     }
    //     else {
    //         //scale by height
    //         zoom = screen_size.height / objects_size.height;
    //     }
    //     console.log(zoom);
    //     window.paper.view.zoom = zoom * 0.98;
    //     window.paper.view.center = window.paper.project.activeLayer.bounds.center;
    //     console.log(window.paper.view.position);
    //     console.log(window.paper.project.activeLayer.bounds.position);
    // }

    // loadMap() {


    //     var zoomAll = () => this.zoomAll();
    //     // var generateRoof = () => this.generateRoof();
    //     var map_url = "images/20190302_WGS84_S_Mercator_138_57260_-34_93000_138_57356_-34_92956_21.jpg";
    //     var map_geodata_url = map_url.replace(".jpg", ".jgw");

    //     fetch(map_geodata_url)
    //     .then(res => {console.log(res); return res.text()}) // Gets the response and returns it as a blob
    //     .then(text => {
    //         // console.log(text);
    //             // By lines
    //         var lines = text.split('\n');
    //         var x_scale = 1;
    //         var y_scale = 1;
    //         for (var line = 0; line < 4; line++) {
    //             // console.log(lines[line]);
    //             if (line === 0) x_scale = Number(lines[line]);
    //             if (line === 3) y_scale = Number(lines[line]);
    //         }
    //         var raster = new window.paper.Raster(map_url);
    //         raster.onLoad = function () {
    //             raster.name = "imported_map";
    //             // raster.position = window.paper.view.center;
    //             raster.sendToBack();
    //             // console.log(x_scale);
    //             // console.log(-y_scale);
    //             // console.log(raster.width + " " + (raster.width * (x_scale * 1000000)));
    //             raster.width = 87730/window.paper.view.scale;
    //             raster.height = 48880/window.paper.view.scale;
    //             // raster.height = 5974;
    //             // raster.scale = (1.0);
    //             // raster.height = 5974;
    //             raster.opacity = 0.5;
    //             // console.log(raster.height);
    //             // generateRoof();
    //             zoomAll();
    //         };
    //   });
    //     window.paper.view.draw();
        
    // }

    // add_polyline(points, params) {


    //     /// START DRAWING

    //     var line2 = new window.fabric.Polyline(points, params);
    //     line2.set({
    //         scale: 0.01,
    //     });
    //     line2.dirty = true;
    //     var new_objects = insertArrayItem(this.state.canvas_objects, { index: this.state.canvas_objects.length, item: line2 });
    //     this.setState({ canvas_objects: new_objects });
    // }


    // handleStateChange = (new_state) => {
    //     console.log(new_state);
    //     this.setState(new_state);
    // };

    // edit_test() {
    //     var new_array = [...this.state.canvas_objects];
    //     console.log(new_array);
    //     new_array[0].stroke = "purple";
    //     new_array[0].dirty = true;
    //     this.setState({ canvas_objects: new_array });
    // }

    // generateRoof() {
    //     var house_plan = window.paper.project.importSVG("images/roof_native.svg", {
    //         onLoad: (item, svg_raw) => {
    //             console.log(item);
    //             // item.scale(0.41, 0.41);
    //             item.scale(1/window.paper.view.scale);
    //             item.rotate(-3.555);
    //             item.position = new window.paper.Point(-10, -150);
    //             item.bringToFront();
    //             item.on('mousedrag', function (e) {
    //                 item.position = item.position.add(e.delta);
    //             });
    //         }
    //     });
    //     // house_plan.rotate(45);
    // }

    // showSuggest() {
    //     if (this.concept === null) {
    //         var concept = new window.paper.Raster("images/designTypes_FlatGF2.png");
    //         this.concept = concept;
    //         concept.onLoad = function () {
    //             concept.name = "suggestion";
    //             concept.scale(0.65);
    //             concept.rotate(-93.555);
    //             concept.position = new window.paper.Point(1550, 90);
    //             // console.log(raster.height);
    //             // generateRoof();
    //             // zoomAll();
    //             // When the mouse leaves the item, set its fill color to black:
    //             concept.on('mousedrag', function (e) {
    //                 concept.position = concept.position.add(e.delta);
    //             });
    //         };
    //     }
    //     else {
    //         this.concept.visible = !this.concept.visible;
    //     }
    //     // house_plan.rotate(45);
    //     // house_plan.bringToFront();
    // }

    // showDesign() {
    //     var design = window.paper.project.importSVG("images/test_opal.svg", {
    //         onLoad: (item, svg_raw) => {
    //             console.log(item);
    //             // item.scale(0.41, 0.41);
    //             item.scale(1/7);
    //             item.rotate(-3.555);
    //             // item.position = new window.paper.Point(850, 1000);
    //         }
    //     });
    // }


// export default QQ2DCanvas;


                {/* <div style={{ right: "0px", float: "right", display: "block", position: "absolute", textAlign: "right", zIndex: "1"}}>
                    <div onClick={() => this.clearAll()}>CLEAR ALL</div>
                    <div onClick={() => this.zoomAll()}>ZOOM ALL</div>
                    <div onClick={() => this.loadMap()}>LOAD MAP</div>
                    <div onClick={() => this.generateRoof()}>GENERATE ROOF</div>
                    <div onClick={() => this.showSuggest()}>SUGGEST CONCEPT</div>
                    <div onClick={() => this.showDesign()}>DRAW DESIGN</div>
                    <div onClick={() => this.load_FlatDesign()}>DRAW FLAT</div>
                </div> */}