import React from 'react';
import { GetHTMLColour, Colours, Colorbond_Colours } from '../components/Common';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

// BabylonJS
import * as BabylonFunctions from './BabylonFunctions';

// Redux Actions
import { connect } from 'react-redux';

import { changePage, toggleDesigner, updateDesigner, toggle3DDesigner, update3DDesigner } from '../redux_store/appState';
import { updateAreaSpecification } from '../redux_store/jobState';
import * as APP from '../redux_store/appState';
import { BASIC_SPECS_SECTIONS } from '../redux_store/jobState_Schemas';
import * as CONFIG from '../redux_store/configState';
import ColourSelector from '../components/ColourSelector';
const importedActions = { changePage, toggleDesigner, toggle3DDesigner, updateAreaSpecification,  };
const mapStateToProps = state => ({
    app: state.app,
    job: state.job,
    config: {
        RoofSheetTypes : state.config.RoofSheetTypes
    }
});

// BabyloneJS data store
const _scene_data = window.scene_data;
const BABYLON = window.BABYLON;

const areaIndex = 0;

const toolDrawerWidth = 240;
const chip_width = "1rem";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: toolDrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    toolDrawerOpen: {
        // backgroundColor: "rgba(242,245,251, 0.5)",
        backgroundColor: "rgba(20,20,20, 0.5)",
        width: toolDrawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolDrawerClose: {
        backgroundColor: "rgba(20,20,20, 0.5)",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing.unit * 7 + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 7 + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: "48px",
        ...theme.mixins.toolbar,
    },
    toolMenuText: {
        color: "white",
        textAlign: "right",
    },
    toolMenuOn: {
        color: 'orange',
        textShadow: '0px 0px 7px rgba(107,107,107,0.89)',
    },
    toolMenuOff: {
        color: 'white',
        textShadow: '0px 0px 7px rgba(107,107,107,0.89)',
    },
    toolListItem: {
        paddingRight: "0px",
        minWidth: toolDrawerWidth,
    },
    titleText: {
        fontSize: '18px',
        color: '#e0f4ff',
        textTransform: 'uppercase',
        margin: '10px 0px 5px',
        fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontWeight: '200',
        textAlign: 'left',
    },
    whiteText : {
        color: "white",
        '&:focus':{
          fontSize: '10px',
          color: '#c1c1c2',
        }
    },
    orangeText : {
        color: "orange",
        '&:focus':{
          fontSize: '10px',
          color: '#c1c1c2',
        }
    },
    underline: {
        borderBottomColor: 'orange',
        '&:after': {
          // The MUI source seems to use this but it doesn't work
          borderBottomColor: 'orange',
        },
        '&:before': {
          // The MUI source seems to use this but it doesn't work
          borderBottom: 'none',
        },
    },
    cssLabel: {
        focused: {
            color: "orange"
        },
        cssFocused: {
          color:'orange',
        },
    },
    select: {
        color: "white",
        textAlign: "center",
        focused: {
            color: "orange"
        },
    },
    focused: {},
    roofsheet_chip: {
        // display:"inline",
        borderRadius: chip_width,
        border: "1px solid grey",
        paddingLeft:"0.5rem",
        paddingRight:"0.5rem",
        minWidth: chip_width,
        minHeight: chip_width,
        background: "#fff",
    }
});

class BabylonGutterDownpipeTools extends React.Component {

    updateGutterColour = () => event => {
        this.props.updateAreaSpecification(areaIndex, BASIC_SPECS_SECTIONS.GUTTERS_AND_DOWNPIPES, "GutterCol", event.target.value);
        BabylonFunctions.changeMeshGroupColour(null, "group_5", GetHTMLColour(event.target.value)[0]);
    }
    

    render() {
        const { classes, job } = this.props;

        // const GUTTERS_AND_DOWNPIPES = BASIC_SPECS.GUTTERS_AND_DOWNPIPES;
        const area_design_specs = job.area_specifications[areaIndex];
        const gutter_downpipe_data = area_design_specs[BASIC_SPECS_SECTIONS.GUTTERS_AND_DOWNPIPES];
        const gutter_colour = gutter_downpipe_data["GutterCol"];
        
        return (
            <>
                {/* <span className={classes.titleText}>Roof Sheet</span> */}
                {/* <Divider style={{margin: "5px 0px 10px"}}/> */}
                <ColourSelector
                    text="Gutter Colour"
                    colourList={Colorbond_Colours}
                    value={gutter_colour}
                    onChange={this.updateGutterColour()}
                    id="gutter_colour"
                    // classes={{ label: classes.whiteText, labelFocussed: classes.whiteText }}
                />
                <Divider />
            </>
        );
    }
}

export default connect(mapStateToProps, importedActions)(withStyles(styles)(BabylonGutterDownpipeTools));