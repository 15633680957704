import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {combineReducers} from 'redux';
import appState, {initialState as appStateinitialState} from './appState';
import jobState, {initialState as jobStateinitialState, testState as jobStatetestState} from './jobState';
import configState, {initialState as configStateinitialState, testState as configStatetestState} from './configState';
import _throttle from 'lodash/throttle';
import _merge from 'lodash/merge';
import { ConsoleLogger } from '@aws-amplify/core';

// local storage
export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return initialState;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};


const appReducer = combineReducers ({
    app: appState,
    job: jobState,
    config: configState,
});

export const rootReducer = (state, action) => {
    if (action.type === 'RESET_ALL') {
        // console.log("CLLEADSARE");
        state = mergedState({});
        // console.log(appStateinitialState);
        // console.log(JSON.stringify(state.app));
    }
    if (action.type === 'LOAD_TEMPLATE') {
        // console.log("CLLEADSARE");
        state = mergedState(action.value);
        // console.log(appStateinitialState);
        // console.log(JSON.stringify(state.app));
    }
    return appReducer(state, action)
  }

const initialState = {};

const middleware = [thunk];

const persistedState = loadState();
//const persistedState = initialState;

function mergedState(initial_state) {
    
    window.debug = false;
    window.test = false;
    console.log(window.location.href);
    if (window.location.href.includes("localhost")) window.debug = true;
    if (window.location.href.includes("test=true")) window.test = true;

    console.log(jobStatetestState);
    console.log(window.test);


    let merged = initial_state;
    
    if (window.test) {
        merged = {job: jobStatetestState};
        merged = _merge({}, {config: configStatetestState}, merged);
        merged = _merge({}, initial_state, merged);
    }

    merged = _merge({}, {app: appStateinitialState}, merged);
    merged = _merge({}, {job: jobStateinitialState}, merged);
    merged = _merge({}, {config: configStateinitialState}, merged);


    // console.log(window.location.href);
    return merged;
}

const store = createStore(
    rootReducer,
    mergedState(persistedState),
    (
        window.location.href.includes("localhost")
        ? (compose(applyMiddleware(...middleware),window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()))
        : compose(applyMiddleware(...middleware))
    ),
);

// store.subscribe(() => {
//     // saveState(store.getState());
// });

store.subscribe(_throttle(() => {
    saveState(store.getState());
  }, 1000));


export default store;