import React from 'react';
import { GetHTMLColour, Colours, Colorbond_Colours } from '../components/Common';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

// BabylonJS
import * as BabylonFunctions from './BabylonFunctions';

// Redux Actions
import { connect } from 'react-redux';

import { changePage, toggleDesigner, updateDesigner, toggle3DDesigner, update3DDesigner } from '../redux_store/appState';
import { updateAreaSpecification } from '../redux_store/jobState';
import * as APP from '../redux_store/appState';
import { BASIC_SPECS_SECTIONS } from '../redux_store/jobState_Schemas';
import * as CONFIG from '../redux_store/configState';
import ColourSelector from '../components/ColourSelector';
const importedActions = { changePage, toggleDesigner, toggle3DDesigner, updateAreaSpecification,  };
const mapStateToProps = state => ({
    app: state.app,
    job: state.job,
    config: {
        RoofSheetTypes : state.config.RoofSheetTypes
    }
});

// BabyloneJS data store
const _scene_data = window.scene_data;
const BABYLON = window.BABYLON;

const areaIndex = 0;

const toolDrawerWidth = 240;
const chip_width = "1rem";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: toolDrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    toolDrawerOpen: {
        // backgroundColor: "rgba(242,245,251, 0.5)",
        backgroundColor: "rgba(20,20,20, 0.5)",
        width: toolDrawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolDrawerClose: {
        backgroundColor: "rgba(20,20,20, 0.5)",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing.unit * 7 + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 7 + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: "48px",
        ...theme.mixins.toolbar,
    },
    toolMenuText: {
        color: "white",
        textAlign: "right",
    },
    toolMenuOn: {
        color: 'orange',
        textShadow: '0px 0px 7px rgba(107,107,107,0.89)',
    },
    toolMenuOff: {
        color: 'white',
        textShadow: '0px 0px 7px rgba(107,107,107,0.89)',
    },
    toolListItem: {
        paddingRight: "0px",
        minWidth: toolDrawerWidth,
    },
    titleText: {
        fontSize: '18px',
        color: '#e0f4ff',
        textTransform: 'uppercase',
        margin: '10px 0px 5px',
        fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontWeight: '200',
        textAlign: 'left',
    },
    whiteText : {
        color: "white",
        '&:focus':{
          fontSize: '10px',
          color: '#c1c1c2',
        }
    },
    orangeText : {
        color: "orange",
        '&:focus':{
          fontSize: '10px',
          color: '#c1c1c2',
        }
    },
    underline: {
        borderBottomColor: 'orange',
        '&:after': {
          // The MUI source seems to use this but it doesn't work
          borderBottomColor: 'orange',
        },
        '&:before': {
          // The MUI source seems to use this but it doesn't work
          borderBottom: 'none',
        },
    },
    cssLabel: {
        focused: {
            color: "orange"
        },
        cssFocused: {
          color:'orange',
        },
    },
    select: {
        color: "white",
        textAlign: "center",
        focused: {
            color: "orange"
        },
    },
    focused: {},
    roofsheet_chip: {
        // display:"inline",
        borderRadius: chip_width,
        border: "1px solid grey",
        paddingLeft:"0.5rem",
        paddingRight:"0.5rem",
        minWidth: chip_width,
        minHeight: chip_width,
        background: "#fff",
    }
});

class BabylonRoofSheetTools extends React.Component {

    updateRoofSheetColour = () => event => {
        this.props.updateAreaSpecification(areaIndex, BASIC_SPECS_SECTIONS.ROOF_SHEETING, "RoofColour", event.target.value);
        BabylonFunctions.changeMeshGroupColour(null, "group_4", GetHTMLColour(event.target.value)[0]);
    }
    updateRoofSheetType = () => event => {
        this.props.updateAreaSpecification(areaIndex, BASIC_SPECS_SECTIONS.ROOF_SHEETING, "RoofType", event.target.value);
        let sheet_key = event.target.value;

        if (this.props.config.RoofSheetTypes[sheet_key]) {
            let sheet = this.props.config.RoofSheetTypes[sheet_key];
            BabylonFunctions.changeMeshGroupTexture(null, "group_4", "/models/" + sheet[CONFIG.ROOFSHEET_TYPE.TEXTURE_FILENAME], "/models/" + sheet[CONFIG.ROOFSHEET_TYPE.TEXTURE_NORMAL_FILENAME]);
        }
    }
    updateRoofSheetSubType = () => event => {
        this.props.updateAreaSpecification(areaIndex, BASIC_SPECS_SECTIONS.ROOF_SHEETING, "RoofSubType", event.target.value);
    }
    

    render() {
        const { classes, job } = this.props;

        // const ROOF_SHEETING = BASIC_SPECS.ROOF_SHEETING;
        const area_design_specs = job.area_specifications[areaIndex];
        const roof_sheet_data = area_design_specs[BASIC_SPECS_SECTIONS.ROOF_SHEETING];
        const selected_RoofSheet = roof_sheet_data["RoofType"];
        let selected_RoofSubType = roof_sheet_data["RoofSubType"];

        let sheet_menu_items = [];
        for (let key in this.props.config.RoofSheetTypes) {
            var sheet = this.props.config.RoofSheetTypes[key];
            sheet_menu_items.push(
                <MenuItem
                    key={sheet[CONFIG.ROOFSHEET_TYPE.NAME]}
                    value={sheet[CONFIG.ROOFSHEET_TYPE.NAME]}>
                    <div className={classes.roofsheet_chip}>
                        <img alt="sheet thumb" src={sheet[CONFIG.ROOFSHEET_TYPE.THUMBNAIL]} style={{ width: "120px" }} />
                    </div>
                    &nbsp;{sheet[CONFIG.ROOFSHEET_TYPE.NAME]}
                </MenuItem>
            )
        }
        
        let subsheet_menu_items = [];
        // if (this.props.config.RoofSheetTypes[selected_RoofSheet]) {
        //     let subtypes = this.props.config.RoofSheetTypes[selected_RoofSheet][CONFIG.ROOFSHEET_TYPE.SUBTYPES];
        //     // subsheet_menu_items = subtypes.map((sub_type, i) => {
                
        //     for (let key in subtypes) {
        //         // var sub_type = this.props.config.RoofSheetTypes[key];
        //         subsheet_menu_items.push (
        //             <MenuItem
        //                 key={key}
        //                 value={key}>
        //                 &nbsp;{key}
        //             </MenuItem>
        //         )
        //     };

        //     //set default for subtype if it is nt in the list
        //     if (!subtypes[selected_RoofSubType]) {
        //         selected_RoofSubType = this.props.config.RoofSheetTypes[selected_RoofSheet][CONFIG.ROOFSHEET_TYPE.DEFAULT_SUBTYPE];
        //     }
        // };
        
        return (
            <>
                {/* <span className={classes.titleText}>Roof Sheet</span> */}
                {/* <Divider style={{margin: "5px 0px 10px"}}/> */}

                <FormControl style={{ width: "100%", textAlign: "left" }}>
                    <InputLabel htmlFor="roofsheetType" shrink={true}
                        className={classes.whiteText}
                        // FormLabelClasses={classes}
                        FormLabelClasses={{
                            root: classes.cssLabel,
                            focused: classes.focused,
                        }}
                    >Main Sheet Type</InputLabel>
                    <Select
                        value={selected_RoofSheet}
                        className={classes.select}
                        onChange={this.updateRoofSheetType()}
                        name="Colour"

                        input={<Input
                            classes={{
                                focused: classes.orangeText,
                                underline: classes.underline,
                            }}
                            name="roofsheetType"
                            id="roofsheetType" />
                        }
                        // classes={classes}
                    >
                    {sheet_menu_items}
                    </Select>
                </FormControl>
                <FormControl style={{ width: "100%", textAlign: "left" }}>
                    <InputLabel htmlFor="roofsheetSubType" shrink={true}
                        className={classes.whiteText}
                        // FormLabelClasses={classes}
                        FormLabelClasses={{
                            root: classes.cssLabel,
                            focused: classes.focused,
                        }}
                    >Material Type</InputLabel>
                    <Select
                        value={selected_RoofSubType}
                        className={classes.select}
                        onChange={this.updateRoofSheetSubType()}
                        name="Colour"

                        input={<Input
                            classes={{
                                focused: classes.orangeText,
                                underline: classes.underline,
                            }}
                            name="roofsheetSubType"
                            id="roofsheetSubType" />
                        }
                        // classes={classes}
                    >
                        {subsheet_menu_items}
                    </Select>
                </FormControl>
                <ColourSelector
                    text="Sheet Colour"
                    colourList={Colorbond_Colours}
                    value={roof_sheet_data["RoofColour"]}
                    onChange={this.updateRoofSheetColour()}
                    id="roofsheet_colour"
                    // classes={{ label: classes.whiteText, labelFocussed: classes.whiteText }}
                />
                <Divider />
            </>
        );
    }
}

export default connect(mapStateToProps, importedActions)(withStyles(styles)(BabylonRoofSheetTools));